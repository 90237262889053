import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IPayrollLoan } from "../../core/interfaces/payroll-loan.interface";

import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { CustomerService } from "./customer.service";
import { ErrorHandlerService } from "./error-handler.service";

import { PayrollLoanRemoteService } from "../remote/payroll-loan-remote.service";

@Injectable({
  providedIn: "root",
})
export class PayrollLoanService {
  payrollLoans: IPayrollLoan[];
  private payrollLoanSelected: IPayrollLoan;
  private payrollLoanSend: IPayrollLoan;

  constructor(
    private customerService: CustomerService,
    private errorHandlerService: ErrorHandlerService,
    private payrollLoanRemoteService: PayrollLoanRemoteService,
  ) {}

  getCustomerActivePayrollLoans(): Observable<any> {
    return this.payrollLoanRemoteService
      .getCustomerActivePayrollLoans(this.customerService.getCustomer())
      .pipe(
        map((dataPayrollLoan: IPayrollLoan[]) => {
          this.payrollLoans = dataPayrollLoan;
          return dataPayrollLoan;
        }),
        catchError((errorActivePayroll: any) => {
          return this.errorHandlerService.errorUnknow(errorActivePayroll);
        }),
      );
  }

  getPayrollLoanSelected(): IPayrollLoan {
    return this.payrollLoanSelected;
  }

  setPayrollLoanSelected(payrollLoan: IPayrollLoan) {
    this.payrollLoanSelected = payrollLoan;
  }

  getPayrollLoanSend(): IPayrollLoan {
    return this.payrollLoanSend;
  }

  setPayrollLoanSend(payrollLoan: IPayrollLoan) {
    this.payrollLoanSend = payrollLoan;
  }

  setAccountId(accountId: number) {
    this.payrollLoanSend.accountId = accountId;
  }

  confirmRelief(payrollLoan: IPayrollLoan): Observable<any> {
    return this.payrollLoanRemoteService
      .confirmRelief(this.customerService.getCustomer(), payrollLoan)
      .pipe(
        map((dataRelief: any) => {
          if (
            this.errorHandlerService.validateIfError(
              dataRelief,
              TypeMessageErrorEnum.ERROR_206,
            )
          ) {
            throw new Error(TypeMessageErrorEnum.ERROR_206);
          } else {
            return dataRelief;
          }
        }),
        catchError((errorRelief: any) => {
          return errorRelief.message === TypeMessageErrorEnum.ERROR_206
            ? throwError([])
            : this.errorHandlerService.errorUnknow(errorRelief);
        }),
      );
  }

  hasActiveRelief(): boolean {
    return (
      this.payrollLoans.filter((payrollLoan) => !payrollLoan.processedRelief)
        .length > 0
    );
  }
}
