import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { OnlyNumberDirective } from "./directives/only-number.directive";

import { AppInterceptor } from "./interceptors/app-interceptor";

@NgModule({
  declarations: [OnlyNumberDirective],
  imports: [CommonModule],
  exports: [OnlyNumberDirective],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
})
export class AppCoreModule {}
