import { MessageEnum } from "../../core/enums/message.enum";
import * as i0 from "@angular/core";
var MessageDataService = /** @class */ (function () {
    function MessageDataService() {
    }
    MessageDataService.prototype.getMessageData = function () {
        return [
            {
                messageId: MessageEnum.APPROVED_RELIEF_5_DAYS,
                icon: "./assets/images/icon-document.svg",
                title: "Solicitud éxitosa",
                cssClassTitle: "d-flex justify-content-center",
                description: "\n          <p>\n            Recibimos tu solicitud, el beneficio se ver\u00E1 reflejado en tu cuenta en los pr\u00F3ximos 5 d\u00EDas.\n            <br /> <br />\n            Si tienes alguna inquietud comun\u00EDcate al <b>743 46 46</b> en Bogot\u00E1 o al <b>01 8000 184646</b> en el resto del pais.\n          </p>\n        ",
                htmlDescription: true,
            },
            {
                messageId: MessageEnum.APPROVED_RELIEF_WITH_QUOTA,
                icon: "./assets/images/icon-document.svg",
                title: "Solicitud éxitosa",
                cssClassTitle: "d-flex justify-content-center",
                description: "\n          <p>\n            Recibimos tu solicitud, el beneficio se ver\u00E1 reflejado en tu cuenta cuando las cuotas sean recibidas de tu empleador o entidad pagadora.\n            <br /> <br />\n            Si tienes alguna inquietud comun\u00EDcate al <b>743 46 46</b> en Bogot\u00E1 o al <b>01 8000 184646</b> en el resto del pais.\n          </p>\n        ",
                htmlDescription: true,
            },
        ];
    };
    MessageDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageDataService_Factory() { return new MessageDataService(); }, token: MessageDataService, providedIn: "root" });
    return MessageDataService;
}());
export { MessageDataService };
