<div class="row">
  <div class="col-12">
    <div
      class="time-remainnig-bar"
      [ngClass]="[time ? '' : 'time-remainnig-bar-upload']"
    >
      <div #timeRemainnigProgress class="time-remainnig-progress"></div>
    </div>
  </div>
</div>
