<div
  id="main"
  class="d-flex flex-column justify-with-scroll-md align-items-center"
>
  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <div class="pb-4 p-b-sm-6">
    <app-text-title [text]="'Libranzas activas'" [level]="3"> </app-text-title>
  </div>

  <div class="content-cards cards-md">
    <div class="d-flex">
      <ng-container *ngFor="let payrollLoan of payrollLoans; let i = index">
        <app-form-salary-loan
          [number]="i"
          [payrollLoan]="payrollLoan"
          (reliefTypeSelectionEvent)="reliefTypeSelection(payrollLoan)"
        ></app-form-salary-loan>
      </ng-container>
    </div>
  </div>
</div>
