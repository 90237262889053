import { Injectable } from "@angular/core";

import { InitialNavPillsEnum } from "../../core/enums/initial-nav-pills.enum";
import { INavPill } from "../../core/interfaces/nav-pill.interface";
import { InitialNavPillsRemoteService } from "../remote/initial-nav-pills-remote.service";

@Injectable({
  providedIn: "root",
})
export class InitialNavPillsService {
  constructor(
    private initialNavPillsRemoteService: InitialNavPillsRemoteService,
  ) {}

  getInitialNavPills(initialNavPillsEnum: InitialNavPillsEnum): INavPill[] {
    return this.initialNavPillsRemoteService.getInitialNavPillsByEnum(
      initialNavPillsEnum,
    );
  }
}
