/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../elements/button/button.component.ngfactory";
import * as i2 from "../../elements/button/button.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../groups/content-message/content-message.component.ngfactory";
import * as i5 from "../../groups/content-message/content-message.component";
import * as i6 from "@angular/common";
import * as i7 from "./approved-relief-view.component";
import * as i8 from "../../../services/local/message.service";
import * as i9 from "../../../services/local/payroll-loan.service";
import * as i10 from "../../../services/local/redirect.service";
import * as i11 from "@angular/router";
var styles_ApprovedReliefViewComponent = [];
var RenderType_ApprovedReliefViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ApprovedReliefViewComponent, data: {} });
export { RenderType_ApprovedReliefViewComponent as RenderType_ApprovedReliefViewComponent };
function View_ApprovedReliefViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "w-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "p-t-30-px container-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "container-center-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-button", [["id", "app-button_accept-data-apply-other-loan"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.clickApplyOtherLoan() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(4, 49152, null, 0, i2.ButtonComponent, [], { cssClass: [0, "cssClass"], mandatory: [1, "mandatory"], text: [2, "text"], parentForm: [3, "parentForm"] }, { clickEvent: "clickEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "w-100 buttonTextMedium"; var currVal_1 = true; var currVal_2 = "Aplicar beneficio a otra Libranza"; var currVal_3 = _co.formGroup; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ApprovedReliefViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "d-flex flex-column justify-with-scroll-md"], ["id", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "simply-margin-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(6, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 10, "div", [["class", "row d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "col-12 col-sm-7 col-lg-6 content-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-content-message", [], null, null, null, i4.View_ContentMessageComponent_0, i4.RenderType_ContentMessageComponent)), i0.ɵdid(10, 49152, null, 0, i5.ContentMessageComponent, [], { icon: [0, "icon"], title: [1, "title"], cssClassTitle: [2, "cssClassTitle"], description: [3, "description"], htmlDescription: [4, "htmlDescription"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ApprovedReliefViewComponent_1)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "w-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "p-t-30-px container-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "container-center-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-button", [["id", "app-button_accept-data"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.clickExit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(17, 49152, null, 0, i2.ButtonComponent, [], { cssClass: [0, "cssClass"], mandatory: [1, "mandatory"], text: [2, "text"], parentForm: [3, "parentForm"] }, { clickEvent: "clickEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.icon; var currVal_9 = _co.title; var currVal_10 = _co.cssClassTitle; var currVal_11 = _co.description; var currVal_12 = _co.htmlDescription; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.applyOtherLoan(); _ck(_v, 12, 0, currVal_13); var currVal_14 = "w-100 alternativeButton buttonTextMedium"; var currVal_15 = true; var currVal_16 = "Salir"; var currVal_17 = _co.formGroup; _ck(_v, 17, 0, currVal_14, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ApprovedReliefViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-approved-relief-view", [], null, null, null, View_ApprovedReliefViewComponent_0, RenderType_ApprovedReliefViewComponent)), i0.ɵdid(1, 114688, null, 0, i7.ApprovedReliefViewComponent, [i8.MessageService, i9.PayrollLoanService, i10.RedirectService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApprovedReliefViewComponentNgFactory = i0.ɵccf("app-approved-relief-view", i7.ApprovedReliefViewComponent, View_ApprovedReliefViewComponent_Host_0, {}, {}, []);
export { ApprovedReliefViewComponentNgFactory as ApprovedReliefViewComponentNgFactory };
