import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RecaptchaRemoteService = /** @class */ (function () {
    function RecaptchaRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    RecaptchaRemoteService.prototype.validations = function (token) {
        return this.httpClient.post(environment.serverUrlRecaptcha + "recaptcha/validations", {
            response: token,
        });
    };
    RecaptchaRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecaptchaRemoteService_Factory() { return new RecaptchaRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: RecaptchaRemoteService, providedIn: "root" });
    return RecaptchaRemoteService;
}());
export { RecaptchaRemoteService };
