/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text-html.component";
var styles_TextHtmlComponent = [];
var RenderType_TextHtmlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextHtmlComponent, data: {} });
export { RenderType_TextHtmlComponent as RenderType_TextHtmlComponent };
export function View_TextHtmlComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
export function View_TextHtmlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-html", [], null, null, null, View_TextHtmlComponent_0, RenderType_TextHtmlComponent)), i0.ɵdid(1, 49152, null, 0, i1.TextHtmlComponent, [], null, null)], null, null); }
var TextHtmlComponentNgFactory = i0.ɵccf("app-text-html", i1.TextHtmlComponent, View_TextHtmlComponent_Host_0, { text: "text" }, {}, []);
export { TextHtmlComponentNgFactory as TextHtmlComponentNgFactory };
