import { throwError } from "rxjs";
import { ErrorCodeEnum } from "../../core/enums/error-code.enum";
import { ErrorTypeEnum } from "../../core/enums/error-type.enum";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { ErrorHandlerRemoteService } from "../remote/error-handler-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/error-handler-remote.service";
var ErrorHandlerService = /** @class */ (function () {
    function ErrorHandlerService(errorHandlerRemoteService) {
        this.errorHandlerRemoteService = errorHandlerRemoteService;
    }
    ErrorHandlerService.prototype.clearError = function () {
        this.error = undefined;
    };
    ErrorHandlerService.prototype.getError = function () {
        return this.error;
    };
    ErrorHandlerService.prototype.setError = function (errorTypeEnum) {
        this.error = this.errorHandlerRemoteService.searchErrorsData(errorTypeEnum);
    };
    ErrorHandlerService.prototype.getErrorById = function (errorTypeEnum) {
        return this.errorHandlerRemoteService.searchErrorsData(errorTypeEnum);
    };
    ErrorHandlerService.prototype.errorUnknow = function (error) {
        if (error.error) {
            this.validateIfError(error, TypeMessageErrorEnum.ERROR_500);
        }
        return throwError([]);
    };
    ErrorHandlerService.prototype.validateIfError = function (object, typeMessageError) {
        var isError = false;
        if (!object.code) {
            if (TypeMessageErrorEnum.ERROR_500 === typeMessageError) {
                isError = true;
                this.errorOverflow(ErrorCodeEnum.FinancialReliefs999);
            }
        }
        else {
            isError = true;
            this.errorOverflow(object.code);
        }
        return isError;
    };
    ErrorHandlerService.prototype.errorOverflow = function (code) {
        var errorTypeEnum;
        switch (code) {
            case ErrorCodeEnum.FinancialReliefs01:
            case ErrorCodeEnum.FinancialReliefs02:
            case ErrorCodeEnum.FinancialReliefs03:
            case ErrorCodeEnum.FinancialReliefs07:
                errorTypeEnum = ErrorTypeEnum.ERROR_LOGIN;
                break;
            case ErrorCodeEnum.FinancialReliefs04:
                errorTypeEnum = ErrorTypeEnum.ERROR_GENERATE_OTP;
                break;
            case ErrorCodeEnum.FinancialReliefs05:
                errorTypeEnum = ErrorTypeEnum.ERROR_SIM;
                break;
            case ErrorCodeEnum.FinancialReliefs08:
                errorTypeEnum = ErrorTypeEnum.ERROR_COLLECTION_CUSTOMER;
                break;
            case ErrorCodeEnum.FinancialReliefs10:
                errorTypeEnum = ErrorTypeEnum.ERROR_INCOMPLETE_DATA_RELIEF;
                break;
            case ErrorCodeEnum.FinancialReliefs11:
                errorTypeEnum = ErrorTypeEnum.SIMULATION_ERROR;
                break;
            case ErrorCodeEnum.FinancialReliefs12:
            case ErrorCodeEnum.FinancialReliefs14:
                errorTypeEnum = ErrorTypeEnum.ERROR_AGE_OUT_OF_RANGE;
                break;
            default:
                errorTypeEnum = ErrorTypeEnum.TECHNICAL_ERROR;
                break;
        }
        this.setError(errorTypeEnum);
    };
    ErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.ɵɵinject(i1.ErrorHandlerRemoteService)); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
