import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";
import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

export class ReliefConfirmationRequestMapper {
  setReliefConfirmationRequestMapper(
    customer: ICustomer,
    payrollLoan: IPayrollLoan,
  ) {
    // Esto se quitará cuando ajusten los problemas en salary loans
    const advisor: IAdvisor = {
      documentAdvisor: "664295",
      officeCode: "44",
    };

    return {
      documentType: customer.documentType,
      documentNumber: customer.documentNumber,
      obligationId: payrollLoan.obligationId,
      payerName: payrollLoan.payerName,
      feeNumber: payrollLoan.feeNumber,
      feeAmount: payrollLoan.feeAmount,
      benefitType: payrollLoan.benefitType,
      disbursementAmount: payrollLoan.disbursementAmount,
      totalAmount: payrollLoan.totalAmount,
      nominalRate: payrollLoan.nominalRate,
      effectiveRate: payrollLoan.effectiveRate,
      accountId: payrollLoan.accountId,
      authorizationAdditionalFees: payrollLoan.authorizationAdditionalFees,
      authorizationTermsConditions: payrollLoan.authorizationAdditionalFees,
      reliefAdvisorRequest: advisor,
    };
  }
}
