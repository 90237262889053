import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

import { PayrollLoanService } from "../../../services/local/payroll-loan.service";

@Component({
  selector: "app-salary-loan-view",
  templateUrl: "./salary-loan-view.component.html",
})
export class SalaryLoanViewComponent implements OnInit {
  payrollLoans: IPayrollLoan[];
  steps: any;
  title: string;

  constructor(
    private payrollLoanService: PayrollLoanService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.steps = {
      numberSteps: 7,
      stepActive: 3,
    };

    this.title = "Libranzas activas";
    this.getValuesPayrollLoan();
  }

  reliefTypeSelection(payrollLoan: IPayrollLoan) {
    this.payrollLoanService.setPayrollLoanSelected(payrollLoan);
    this.router.navigate(["relieftype"]);
  }

  private getValuesPayrollLoan() {
    this.payrollLoanService.getCustomerActivePayrollLoans().subscribe(
      (dataPayrollLoan: IPayrollLoan[]) =>
        (this.payrollLoans = dataPayrollLoan),
      () => {
        this.router.navigate(["overflowerror"]);
      },
    );
  }
}
