<button
  type="button" 
  (click)="onClick()" 
  [disabled]="(!parentForm.valid && mandatory) || loading"
  [ngClass]="[
    !parentForm.valid && mandatory ? 'buttonDisabled' : '',
    cssClass !== '' ? cssClass : ''
  ]"
>
  <span *ngIf="!loading">
    {{ text }}
  </span>
  <div
    class="div-loading d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <app-loading-three-point [pointWhite]="true"></app-loading-three-point>
  </div>
</button>
