import { ErrorDisplayTypeEnum } from "../../core/enums/error-display-type.enum";
import { ErrorTypeEnum } from "../../core/enums/error-type.enum";
import * as i0 from "@angular/core";
var ErrorHandlerDataService = /** @class */ (function () {
    function ErrorHandlerDataService() {
    }
    ErrorHandlerDataService.prototype.getErrorsData = function () {
        return [
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.ERROR_AGE_OUT_OF_RANGE,
                title: "No podemos atender tu solicitud",
                description: "Lo sentimos, debido a tu edad no puedes continuar con el proceso.",
                captionButtom: "Volver a intentar",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.ERROR_COLLECTION_CUSTOMER,
                title: "No podemos atender tu solicitud por este canal",
                description: "Te recomendamos llamar al 744 48 57, 745 42 32 en Bogot\u00E1 o al 01 8000 516300 en el resto de pa\u00EDs.\n          O escribenos al correo electr\u00F3nico negociaciones_cobranzas@bancopopular.com.co, para continuar con tu solicitud",
                captionButtom: "Entendido",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.ERROR_LOGIN,
                title: "No cuentas con una Libranza apta para beneficios",
                description: "Si quieres conocer más comunícate al 743 46 46 en Bogotá o al 01 8000 184646 en el resto del país",
                captionButtom: "Entendido",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.ERROR_SIM,
                title: "Necesitamos actualizar tus datos",
                description: "Lo sentimos, no pudimos validar tu información, acércate a cualquiera de nuestras oficinas y actualiza tus datos",
                captionButtom: "Entendido",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/darts.svg",
                errorCode: ErrorTypeEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS,
                title: "Has superado el máximo de intentos",
                description: "Puedes volver a intentarlo más tarde. Asegúrate de tener tu celular a la mano y verifica el número que ingresas.",
                captionButtom: "Entendido",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/darts.svg",
                errorCode: ErrorTypeEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_AGAIN,
                title: "No podemos atender tu solicitud por este medio",
                description: "Lo sentimos, no pudimos validar tu información, acércate a cualquiera de nuestras oficinas para validar tus datos.",
                captionButtom: "Salir",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icecream.svg",
                errorCode: ErrorTypeEnum.TECHNICAL_ERROR,
                title: "Algo no salió bien…",
                description: "En este momento tenemos incovenientes técnicos. Pronto estaremos funcionando. Por favor, vuelve en un momento.",
                captionButtom: "Salir",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icecream.svg",
                errorCode: ErrorTypeEnum.ERROR_GENERATE_OTP,
                title: "Algo no salió bien…",
                description: "En este momento tenemos incovenientes técnicos en el proceso de tu validación. Pronto estaremos funcionando. Por favor, vuelve en un momento.",
                captionButtom: "Volver a intentar",
                url: "",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/idea.svg",
                errorCode: ErrorTypeEnum.ERROR_INCORRECT_OTP,
                title: "Revisa el código",
                description: "El código de seguridad que ingresaste es incorrecto, inténtalo de nuevo",
                captionButtom: "Intentar de nuevo",
                url: "",
                errorDisplayType: ErrorDisplayTypeEnum.POPUP,
            },
            {
                icon: "./assets/images/user-error.svg",
                errorCode: ErrorTypeEnum.ERROR_EXPIRE_OTP,
                title: "El código ha expirado",
                description: "No te preocupes, puedes intentarlo con otro",
                captionButtom: "Intentar de nuevo",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.POPUP,
            },
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.ERROR_INCOMPLETE_DATA_RELIEF,
                title: "Necesitamos completar tu información",
                description: "Lo sentimos, la información para tu solicitud no esta completa, acércate a cualquiera de nuestras oficinas y actualiza tus datos",
                captionButtom: "Entendido",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
            {
                icon: "./assets/images/icon-error.svg",
                errorCode: ErrorTypeEnum.SIMULATION_ERROR,
                title: "No podemos atender tu solicitud por este medio",
                description: "Lo sentimos, no pudimos calcular tu beneficio, acércate a cualquiera de nuestras oficinas.",
                captionButtom: "Salir",
                url: "login",
                errorDisplayType: ErrorDisplayTypeEnum.OVERFLOW,
            },
        ];
    };
    ErrorHandlerDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerDataService_Factory() { return new ErrorHandlerDataService(); }, token: ErrorHandlerDataService, providedIn: "root" });
    return ErrorHandlerDataService;
}());
export { ErrorHandlerDataService };
