import { DocumentTypeEnum } from "../../core/enums/document-type.enum";
import { InitialNavPillsEnum } from "../../core/enums/initial-nav-pills.enum";
import * as i0 from "@angular/core";
var InitialNavPillsDataService = /** @class */ (function () {
    function InitialNavPillsDataService() {
    }
    InitialNavPillsDataService.prototype.getInitialNavPills = function () {
        return [
            {
                id: InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
                active: true,
                text: "Cédula de ciudadanía",
                value: DocumentTypeEnum.CC,
            },
            {
                id: InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
                active: false,
                text: "Cédula de extranjería",
                value: DocumentTypeEnum.CE,
            },
        ];
    };
    InitialNavPillsDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InitialNavPillsDataService_Factory() { return new InitialNavPillsDataService(); }, token: InitialNavPillsDataService, providedIn: "root" });
    return InitialNavPillsDataService;
}());
export { InitialNavPillsDataService };
