import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-relief-type",
  templateUrl: "./relief-type.component.html",
})
export class ReliefTypeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      ["onecolumn", { outlets: { "part-center": ["relieftypeview"] } }],
      { skipLocationChange: true },
    );
  }
}
