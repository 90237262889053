import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { AuthenticationRequestMapper } from "./mapper/authentication-request.mapper";

@Injectable({
  providedIn: "root",
})
export class AuthorizationRemoteService {
  constructor(private httpClient: HttpClient) {}

  saveAuthorization(customer: ICustomer) {
    const authenticationRequestMapper = new AuthenticationRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/auth",
      authenticationRequestMapper.setAuthenticationRequestMapper(customer),
    );
  }
}
