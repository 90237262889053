import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-otp-input",
  templateUrl: "./otp-input.component.html",
  styleUrls: ["./otp-input.component.scss"],
})
export class OtpInputComponent implements AfterViewInit {
  @ViewChild("otpInput", { static: false }) otpInput: ElementRef;

  @Input("textFocusBack")
  set setTextFocusBack(textFocusBack: ElementRef) {
    this.textFocusBack = textFocusBack;
  }

  @Input("textFocusNext")
  set setTextFocusNext(textFocusNext: ElementRef) {
    this.textFocusNext = textFocusNext;
  }

  @Input() fieldControl: FormControl;
  @Input() focusInit: boolean;
  @Input() parentForm: FormGroup;

  textFocusBack: ElementRef;
  textFocusNext: ElementRef;

  ngAfterViewInit() {
    if (this.focusInit) {
      this.otpInput.nativeElement.focus();
    }
  }

  keyUp(event: any) {
    if (event.key === "Backspace") {
      if (this.textFocusBack !== undefined) {
        // tslint:disable-next-line:no-string-literal
        this.textFocusBack["otpInput"].nativeElement.focus();
      }
    } else {
      if (this.textFocusNext !== undefined) {
        // tslint:disable-next-line:no-string-literal
        this.textFocusNext["otpInput"].nativeElement.focus();
      }
    }
  }
}
