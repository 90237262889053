import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AccountRequestMapper } from "./mapper/account-request.mapper";
import { SearchCustomerRequestMapper } from "./mapper/search-customerRequest.mapper";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerRemoteService = /** @class */ (function () {
    function CustomerRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    CustomerRemoteService.prototype.loginCustomer = function (customer) {
        var searchCustomerRequestMapper = new SearchCustomerRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/customer", searchCustomerRequestMapper.setSearchCustomerRequestMapper(customer));
    };
    CustomerRemoteService.prototype.getCustomerAccounts = function (customer) {
        var accountRequestMapper = new AccountRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/customer/accounts", accountRequestMapper.setAccountRequestMapper(customer));
    };
    CustomerRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerRemoteService_Factory() { return new CustomerRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomerRemoteService, providedIn: "root" });
    return CustomerRemoteService;
}());
export { CustomerRemoteService };
