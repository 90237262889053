/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/background-black/background-black.component.ngfactory";
import * as i3 from "../../elements/background-black/background-black.component";
import * as i4 from "../lottie-animation/lottie-animation.component.ngfactory";
import * as i5 from "../lottie-animation/lottie-animation.component";
import * as i6 from "./loading.component";
var styles_LoadingComponent = [i0.styles];
var RenderType_LoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
export function View_LoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-background-black", [], null, null, null, i2.View_BackgroundBlackComponent_0, i2.RenderType_BackgroundBlackComponent)), i1.ɵdid(1, 49152, null, 0, i3.BackgroundBlackComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "loading-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "rectangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-lottie-animation", [["class", "lottie"]], null, null, null, i4.View_LottieAnimationComponent_0, i4.RenderType_LottieAnimationComponent)), i1.ɵdid(5, 114688, null, 0, i5.LottieAnimationComponent, [], { path: [0, "path"], renderer: [1, "renderer"], loop: [2, "loop"], width: [3, "width"], height: [4, "height"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Logo Loading"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "./assets/json/Logo_ani_loader.json"; var currVal_1 = "canvas"; var currVal_2 = true; var currVal_3 = "57px"; var currVal_4 = "49px"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = "./assets/images/isologo-wh.png"; _ck(_v, 6, 0, currVal_5); }); }
export function View_LoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i1.ɵdid(1, 49152, null, 0, i6.LoadingComponent, [], null, null)], null, null); }
var LoadingComponentNgFactory = i1.ɵccf("app-loading", i6.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
