import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
var RedirectService = /** @class */ (function () {
    // tslint:disable-next-line: no-empty
    function RedirectService() {
    }
    RedirectService.prototype.redirectToBank = function () {
        window.open(environment.urlBank, "_self");
    };
    RedirectService.prototype.redirectToBankRelief = function () {
        window.open(environment.urlBankRelief, "_self");
    };
    RedirectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectService_Factory() { return new RedirectService(); }, token: RedirectService, providedIn: "root" });
    return RedirectService;
}());
export { RedirectService };
