<app-background-black></app-background-black>
<div class="loading-spinner">
  <div class="rectangle">
    <app-lottie-animation
      class="lottie"
      [width]="'57px'"
      [height]="'49px'"
      [path]="'./assets/json/Logo_ani_loader.json'"
      [renderer]="'canvas'"
      [loop]="true"
    ></app-lottie-animation>
    <img alt="Logo Loading" [src]="'./assets/images/isologo-wh.png'" />
  </div>
</div>
