import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthenticationRequestMapper } from "./mapper/authentication-request.mapper";
import { OtpRequestMapper } from "./mapper/otp-request.mapper";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OtpAuthenticationRemoteService = /** @class */ (function () {
    function OtpAuthenticationRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    OtpAuthenticationRemoteService.prototype.authenticationOtp = function (otpValidate) {
        var otpRequestMapper = new OtpRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/auth/otp", otpRequestMapper.setOtpRequestMapper(otpValidate));
    };
    OtpAuthenticationRemoteService.prototype.generateOtpByCall = function (customer) {
        var authenticationRequestMapper = new AuthenticationRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/auth/otp/call", authenticationRequestMapper.setAuthenticationRequestMapper(customer));
    };
    OtpAuthenticationRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OtpAuthenticationRemoteService_Factory() { return new OtpAuthenticationRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: OtpAuthenticationRemoteService, providedIn: "root" });
    return OtpAuthenticationRemoteService;
}());
export { OtpAuthenticationRemoteService };
