import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthenticationRequestMapper } from "./mapper/authentication-request.mapper";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthorizationRemoteService = /** @class */ (function () {
    function AuthorizationRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    AuthorizationRemoteService.prototype.saveAuthorization = function (customer) {
        var authenticationRequestMapper = new AuthenticationRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/auth", authenticationRequestMapper.setAuthenticationRequestMapper(customer));
    };
    AuthorizationRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationRemoteService_Factory() { return new AuthorizationRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthorizationRemoteService, providedIn: "root" });
    return AuthorizationRemoteService;
}());
export { AuthorizationRemoteService };
