<div class="position-relative w-100" [formGroup]="parentForm">
  <input
    #otpInput
    class="form-input-otp"
    [formControl]="fieldControl"
    maxlength="1"
    placeholder="-"
    type="tel"
    (keyup)="keyUp($event)"
    required
  />
</div>
