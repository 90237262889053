<div
  id="main"
  class="d-flex flex-column justify-with-scroll-lg align-items-center"
>
  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <div class="w-100 text-tittle">
    <app-text-title [text]="title" [level]="3"> </app-text-title>
  </div>

  <div class="w-100 text-tittle">
    <app-text-description [text]="payerNameText" [textBold]="payerName">
    </app-text-description>
  </div>

  <div class="w-100 text-tittle mb-3">
    <app-text-description [text]="quotaAndRateText" [textBold]="quotaAndRate">
    </app-text-description>
  </div>

  <div class="content-cards cards-lg">
    <div class="d-flex flex-row align-items-center">
      <ng-container *ngFor="let reliefType of reliefTypes; let i = index">
        <app-form-relief-type
          [number]="i"
          [reliefType]="reliefType"
          (accountSelectionEvent)="accountSelection($event)"
        ></app-form-relief-type>
      </ng-container>
    </div>
  </div>
</div>
<app-loading [hidden]="hiddenLoading"></app-loading>
