<app-background-black></app-background-black>
<form class="form-popup" [formGroup]="formGroup" novalidate>
  <div class="w-100 h-50-px d-flex justify-content-center">
    <div class="w-90 d-flex align-items-center justify-content-end">
      <app-button-close (click)="closePopup()"></app-button-close>
    </div>
  </div>
  <div class="w-100 h-90-px d-flex justify-content-center">
    <div class="w-85 text-center">
      <app-text-title [level]="3" [text]="title"></app-text-title>
    </div>
  </div>
  <div class="w-100 d-flex align-items-center justify-content-center">
    <div class="w-85">
      <app-form-text-overflow-y
        [cssClass]="'h-260-px'"
        [cssClassDiv]="'h-260-px'"
        [text]="contentText"
      ></app-form-text-overflow-y>
    </div>
  </div>
  <div class="w-100 h-110-px d-flex align-items-center justify-content-center">
    <div class="w-85">
      <app-button
        id="app-button_button-close"
        [cssClass]="'w-100 buttonTextMedium'"
        [mandatory]="true"
        [text]="'Continuar'"
        [parentForm]="formGroup"
        (clickEvent)="closeSavePopup()"
      ></app-button>
    </div>
  </div>
</form>
