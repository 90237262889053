import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { IListSelect } from "../../../core/interfaces/list-select.interface";

@Component({
  selector: "app-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
})
export class FormSelectComponent {
  @Input("listSelect")
  set setListItemSelect(value: IListSelect[]) {
    this.listSelect = value;
    this.listItemSelect = value;
  }

  @Input() fieldControl: FormControl;
  @Input() lengthInput: string;
  @Input() parentForm: FormGroup;
  @Input() valueControl: FormControl;
  @Input() text: string;

  @Output() selectItemEvent = new EventEmitter<boolean>();

  hiddenList = true;
  itemSelect: IListSelect;
  listSelect: IListSelect[];
  listItemSelect: IListSelect[];

  showList() {
    this.hiddenList = !this.hiddenList;
  }

  showListText() {
    this.hiddenList = false;
  }

  selectItem(item: any) {
    this.hiddenList = true;
    this.itemSelect = item;
    this.fieldControl.setValue(item.text);
    this.valueControl.setValue(item);

    this.selectItemEvent.emit();
  }

  changeValue() {
    this.itemSelect = undefined;

    if (this.fieldControl.value !== undefined) {
      this.listItemSelect = this.listSelect.filter(
        (x) =>
          x.text.toLowerCase().indexOf(this.fieldControl.value.toLowerCase()) >
          -1,
      );
    }
  }

  blurSelect() {
    setTimeout(() => {
      this.hiddenList = true;

      if (this.itemSelect === undefined) {
        this.fieldControl.setValue(undefined);
        this.changeValue();
      }
    }, 1000);
  }
}
