import { Injectable } from "@angular/core";

import { InitialNavPillsEnum } from "../../core/enums/initial-nav-pills.enum";
import { INavPill } from "../../core/interfaces/nav-pill.interface";
import { InitialNavPillsDataService } from "../data/initial-nav-pills-data.service";

@Injectable({
  providedIn: "root",
})
export class InitialNavPillsRemoteService {
  constructor(private initialNavPillsDataService: InitialNavPillsDataService) {}

  getInitialNavPillsByEnum(
    initialNavPillsEnum: InitialNavPillsEnum,
  ): INavPill[] {
    return this.initialNavPillsDataService
      .getInitialNavPills()
      .filter(
        (initialNavPill: INavPill) => initialNavPill.id === initialNavPillsEnum,
      );
  }
}
