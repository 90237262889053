import { ElementRef, EventEmitter, Renderer2, } from "@angular/core";
import { timer } from "rxjs";
import { map, take } from "rxjs/operators";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
var ProgressBarComponent = /** @class */ (function () {
    function ProgressBarComponent(renderer) {
        this.renderer = renderer;
        this.infoBarCompleteEvent = new EventEmitter();
    }
    Object.defineProperty(ProgressBarComponent.prototype, "setProgress", {
        set: function (progress) {
            if (progress) {
                this.barProgress(progress.toString());
                if (progress === ApplicationConstantsEnum.NUMBER_MAX_BAR_PROGRESS) {
                    this.infoBarComplete();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "setSeconds", {
        set: function (seconds) {
            if (seconds) {
                this.seconds = seconds;
                this.restartWithSeconds();
            }
        },
        enumerable: true,
        configurable: true
    });
    ProgressBarComponent.prototype.infoBarComplete = function () {
        this.infoBarCompleteEvent.emit();
    };
    ProgressBarComponent.prototype.restartWithSeconds = function () {
        clearInterval(this.intervalBar);
        this.secondsCount = this.seconds;
        this.progreessBarCompleteWithSeconds();
        this.timerComplete();
    };
    ProgressBarComponent.prototype.progreessBarCompleteWithSeconds = function () {
        var _this = this;
        this.count = 0;
        this.intervalBar = setInterval(function () {
            _this.count++;
            if (_this.count <= 100) {
                _this.barProgress(_this.count.toString());
            }
            else {
                clearInterval(_this.intervalBar);
                _this.infoBarComplete();
            }
        }, (this.seconds * 1000) / 100);
    };
    ProgressBarComponent.prototype.timerComplete = function () {
        var _this = this;
        this.countTimer = timer(0, 1000).pipe(take(this.seconds), map(function () { return --_this.secondsCount; }));
    };
    ProgressBarComponent.prototype.barProgress = function (percentajeProgress) {
        this.renderer.setStyle(this.timeRemainnigProgress.nativeElement, "width", percentajeProgress + "%");
    };
    return ProgressBarComponent;
}());
export { ProgressBarComponent };
