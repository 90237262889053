import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  // tslint:disable-next-line: no-empty
  constructor() {}

  redirectToBank() {
    window.open(environment.urlBank, "_self");
  }

  redirectToBankRelief() {
    window.open(environment.urlBankRelief, "_self");
  }
}
