import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent() {
        this.clickEvent = new EventEmitter();
    }
    Object.defineProperty(ButtonComponent.prototype, "setLoading", {
        set: function (loading) {
            this.loading = loading;
        },
        enumerable: true,
        configurable: true
    });
    ButtonComponent.prototype.onClick = function () {
        this.clickEvent.emit(true);
    };
    return ButtonComponent;
}());
export { ButtonComponent };
