import { Router, } from "@angular/router";
import { CustomerService } from "./services/local/customer.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./services/local/customer.service";
var AppAccessGuard = /** @class */ (function () {
    function AppAccessGuard(router, customerService) {
        this.router = router;
        this.customerService = customerService;
    }
    AppAccessGuard.prototype.canActivate = function (next, state) {
        var currentCustomer = this.customerService.getCustomer();
        if (currentCustomer !== undefined) {
            return true;
        }
        this.router.navigate(["login"]);
        return false;
    };
    AppAccessGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppAccessGuard_Factory() { return new AppAccessGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.CustomerService)); }, token: AppAccessGuard, providedIn: "root" });
    return AppAccessGuard;
}());
export { AppAccessGuard };
