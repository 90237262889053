import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-otp-field",
  templateUrl: "./otp-field.component.html",
  styleUrls: ["./otp-field.component.scss"],
})
export class OtpFieldComponent implements AfterViewInit, OnInit {
  @ViewChildren("textOtp") textOtp: QueryList<ElementRef>;

  @Input() numberInputText: number;
  @Input() formGroup: FormGroup;

  arrayText: string[] = [];
  widthPercentajeText: any;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.calculateWidthText();
    this.setText();
    this.initForm();
  }

  ngAfterViewInit() {
    this.setFocusText();
  }

  clearOtpText() {
    Object.keys(this.formGroup.controls).forEach((key: any) => {
      this.formGroup.controls[key].setValue("");
    });

    this.focusFirstText();
  }

  getValueOtp(): string {
    let otpValue: string = "";

    Object.keys(this.formGroup.controls).forEach((key: any) => {
      otpValue += this.formGroup.controls[key].value;
    });

    return otpValue;
  }

  @HostListener("window:resize", ["$event"])
  private calculateWidthText() {
    const row = document.getElementById("row");
    const width = row.offsetWidth / this.numberInputText;
    this.widthPercentajeText = this.domSanitizer.bypassSecurityTrustStyle(
      `calc(${width}px)`,
    );
  }

  private focusFirstText() {
    // tslint:disable-next-line:no-string-literal
    this.textOtp.toArray()[0]["otpInput"].nativeElement.focus();
  }

  private initForm() {
    for (let i = 1; i <= this.numberInputText; i++) {
      this.formGroup.addControl(
        "textOtp" + i,
        new FormControl("", Validators.required),
      );
    }
  }

  private setFocusText() {
    let count: number = 0;
    this.textOtp.toArray().forEach((txt: any) => {
      if (count === 0) {
        txt.textFocusNext = this.textOtp.toArray()[count + 1];
      } else if (count === this.numberInputText - 1) {
        txt.textFocusBack = this.textOtp.toArray()[count - 1];
      } else {
        txt.textFocusBack = this.textOtp.toArray()[count - 1];
        txt.textFocusNext = this.textOtp.toArray()[count + 1];
      }

      count++;
    });

    this.focusFirstText();
  }

  private setText() {
    for (let i = 1; i <= this.numberInputText; i++) {
      this.arrayText.push("textOtp" + i);
    }
  }
}
