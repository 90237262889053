export class SimulationSalaryLoanRequestMapper {
  setSimulationSalaryLoanRequestMapper(simulationRequest: any) {
    return {
      documentType: simulationRequest.documentType,
      documentNumber: simulationRequest.documentNumber,
      obligationId: simulationRequest.obligationId,
      term: simulationRequest.term,
      benefitType: simulationRequest.benefitType,
    };
  }
}
