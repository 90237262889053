import * as tslib_1 from "tslib";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { ErrorHandlerService } from "./error-handler.service";
import { CustomerRemoteService } from "../remote/customer-remote.service";
import { RecaptchaRemoteService } from "../remote/recaptcha-remote.service";
import { CipherService } from "./cipher.service";
import * as i0 from "@angular/core";
import * as i1 from "./cipher.service";
import * as i2 from "../remote/customer-remote.service";
import * as i3 from "./error-handler.service";
import * as i4 from "../remote/recaptcha-remote.service";
import * as i5 from "ng-recaptcha";
var CustomerService = /** @class */ (function () {
    function CustomerService(cipherService, customerRemoteService, errorHandlerService, recaptchaRemoteService, recaptchaV3Service) {
        this.cipherService = cipherService;
        this.customerRemoteService = customerRemoteService;
        this.errorHandlerService = errorHandlerService;
        this.recaptchaRemoteService = recaptchaRemoteService;
        this.recaptchaV3Service = recaptchaV3Service;
    }
    CustomerService.prototype.getCustomer = function () {
        return this.customer;
    };
    CustomerService.prototype.setCustomer = function (customer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.customer = customer;
                        return [4 /*yield*/, this.setDocumentDataEncrypted(customer)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerService.prototype.setDocumentDataEncrypted = function (customer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cipherService
                            .asyncCipherStringRSAOAEP512(JSON.stringify(customer))
                            .then(function (x) {
                            _this.customer.documentDataEncrypted = x;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerService.prototype.loginCustomer = function () {
        var _this = this;
        return this.recaptchaV3Service.execute("onInit").pipe(mergeMap(function (token) {
            return _this.recaptchaRemoteService.validations(token).pipe(mergeMap(function () {
                return _this.validateLoginCustomer();
            }), catchError(function (errorLoginCustomer) {
                return _this.errorHandlerService.errorUnknow(errorLoginCustomer);
            }));
        }), catchError(function (errorLoginCustomer) {
            return _this.errorHandlerService.errorUnknow(errorLoginCustomer);
        }));
    };
    CustomerService.prototype.getCustomerAccounts = function () {
        var _this = this;
        return this.customerRemoteService.getCustomerAccounts(this.customer).pipe(map(function (dataCustomerAccounts) {
            return dataCustomerAccounts;
        }), catchError(function (errorCustomerAccounts) {
            return _this.errorHandlerService.errorUnknow(errorCustomerAccounts);
        }));
    };
    CustomerService.prototype.validateLoginCustomer = function () {
        var _this = this;
        return this.customerRemoteService.loginCustomer(this.getCustomer()).pipe(map(function (dataLoginCustomer) {
            if (_this.errorHandlerService.validateIfError(dataLoginCustomer, TypeMessageErrorEnum.ERROR_206)) {
                throw new Error(TypeMessageErrorEnum.ERROR_206);
            }
            else {
                _this.customer.firstName = dataLoginCustomer.firstName;
                _this.customer.lastName = dataLoginCustomer.lastName;
                return;
            }
        }), catchError(function (errorLoginCustomer) {
            return errorLoginCustomer.message === TypeMessageErrorEnum.ERROR_206
                ? throwError([])
                : _this.errorHandlerService.errorUnknow(errorLoginCustomer);
        }));
    };
    CustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.ɵɵinject(i1.CipherService), i0.ɵɵinject(i2.CustomerRemoteService), i0.ɵɵinject(i3.ErrorHandlerService), i0.ɵɵinject(i4.RecaptchaRemoteService), i0.ɵɵinject(i5.ReCaptchaV3Service)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
