export enum ApplicationConstantsEnum {
  APPLICATION_PDF = "application/pdf",
  CHARACTER_EMPTY = " ",
  CHARACTER_NOT_FOUND = -1,
  FEE = "cuotas",
  FEE_NUMBER = "Número de cuotas",
  FEE_NUMBER_ADDED = "Número de cuotas adicionadas",
  IMAGE_JPEG = "image/jpeg",
  JPG = "jpg",
  JPGE = "jpeg",
  MONTHS = "meses",
  NUMBER_ATTEMPTS = 3,
  NUMBER_ATTEMPTS_LOGIN_CUSTOMER_DESIGN = 1,
  NUMBER_MAX_LENGTH_CODE_RNEC = 12,
  NUMBER_MAX_BAR_PROGRESS = 100,
  OTP_LONG = 8,
  OTP_SHORT = 6,
  PDF = "pdf",
  TERM = "Plazo",
}
