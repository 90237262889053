<div class="content-card pt-3 mb-4 animation-{{ number }}">
  <form class="d-flex flex-column position-relative" novalidate>
    <div class="h-35-px"></div>

    <div class="container-recommended" *ngIf="reliefType.recommended">
      <span class="recommended py-1 px-2">Recomendado</span>
    </div>

    <div class="w100 d-flex justify-content-center">
      <div class="negative-mt-2">
        <img [src]="reliefType.icon" alt="Icon Error" />
      </div>
    </div>

    <div class="w100 mt-3 px-4">
      <div class="text-center">
        <app-text-title [text]="reliefType.title" [level]="5"></app-text-title>
      </div>
    </div>

    <div class="w100 mt-2 px-4">
      <div class="text-center">
        <app-text-description
          [cssClass]="'text-md-center'"
          [text]="reliefType.description"
        >
        </app-text-description>
      </div>
    </div>

    <div class="w100 mt-2" *ngIf="reliefType.inputText">
      <div class="text-center">
        <app-text-description
          [cssClass]="'text-md-center'"
          [text]="reliefType.inputText"
        >
        </app-text-description>
      </div>
    </div>

    <div class="w-100 px-3" *ngIf="listTerm">
      <app-form-select
        [fieldControl]="formGroup.controls['textTerm']"
        [valueControl]="formGroup.controls['valueTerm']"
        [lengthInput]="'25'"
        [listSelect]="listTerm"
        [parentForm]="formGroup"
        [text]="reliefType.labelTerm"
        (selectItemEvent)="setSelectTerm()"
        id="app-form-select_sector-list"
      ></app-form-select>
    </div>

    <div class="w100 mt-4 mb-1" *ngIf="disbursementAmount">
      <div class="text-center">
        <app-text-title [text]="'Valor a desembolsar'" [level]="6">
        </app-text-title>
      </div>
    </div>

    <div class="w100" *ngIf="disbursementAmount">
      <div class="text-center">
        <app-text-description
          [cssClass]="'font-lg-bold'"
          [text]="disbursementAmount"
        >
        </app-text-description>
      </div>
    </div>

    <div class="h-50-px" *ngIf="!disbursementAmount"></div>

    <div class="w-100 px-3 mb-4">
      <app-button
        [cssClass]="'w-100'"
        [loading]="loadingButtonRelief"
        [mandatory]="true"
        [text]="'Seleccionar beneficio'"
        [parentForm]="formGroup"
        (clickEvent)="goToAccountSelection()"
      >
      </app-button>
    </div>
  </form>
</div>
