export const environment = {
  production: false,

  serverUrlReliefs:
    "https://44yv6znw2c.execute-api.us-east-2.amazonaws.com/dev/",
  serverUrlRecaptcha:
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/",
  serverUrlSalaryLoans:
    "https://hh1xrjw9ej.execute-api.us-east-2.amazonaws.com/salaryloans/",
  urlBank: "https://www.bancopopular.com.co/",
  urlBankRelief:
    "https://www.bancopopular.com.co/wps/portal/bancopopular/inicio/formulario-alivios-financieros",

  codeGoogleAnalytics: "uXrIEOicg5U2IGSEdYfYpA&gtm_preview=env-7",
  codeUAGoogleAnalytics: "UA-120789499-1",
  rsaOaep512PublicKey:
    // tslint:disable-next-line:max-line-length
    "zCi8mi3DgzVwwbVEhb3oTvWr73RG3CKfkVirxjpA5DLjBRslXICgBtVlMW3zkLqcz75f_cz0B9GqL2y6weRtGkPyUTaOuea1ZkeuGmAXDfXEdYpmRFxQ6D5B-49S8x4iUe-Jd4nL4qWuQGtWXNPKSu8_mOPKj64Nw5eA5IKLOdPZd3D0hrfWxNXWWEzcvbArVrhA00xgmZb23V2z_HufHVsnlyFlWKNcXmepB_76wlW5RKoh2Ns0ZbOrASvbTYB4NqLHZy3YQ0mkimNH1JOUmtZ-XeGIPOUpKPM_bah0BTaFOzFq8UaNLhs2_kgOfzgZI5bFx__JmwOYbVBhR_MAJw",
};
