export enum ErrorCodeEnum {
  FinancialReliefs01 = "FinancialReliefs01",
  FinancialReliefs02 = "FinancialReliefs02",
  FinancialReliefs03 = "FinancialReliefs03",
  FinancialReliefs04 = "FinancialReliefs04",
  FinancialReliefs05 = "FinancialReliefs05",
  FinancialReliefs06 = "FinancialReliefs06",
  FinancialReliefs07 = "FinancialReliefs07",
  FinancialReliefs08 = "FinancialReliefs08",
  FinancialReliefs09 = "FinancialReliefs09",
  FinancialReliefs10 = "FinancialReliefs10",
  FinancialReliefs11 = "FinancialReliefs11",
  FinancialReliefs12 = "FinancialReliefs12",
  FinancialReliefs14 = "FinancialReliefs14",
  FinancialReliefs998 = "FinancialReliefs998",
  FinancialReliefs999 = "FinancialReliefs999",
}
