import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, delay, mergeMap, tap } from "rxjs/internal/operators";

import { CustomerService } from "../../services/local/customer.service";
import { RedirectService } from "../../services/local/redirect.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private customerService: CustomerService,
    private redirectService: RedirectService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.buildAdditionalParams(req)).pipe(
      tap(
        (event: any) => {
          if (event instanceof HttpResponse) {
            if (event.status === 206) {
              // tslint:disable-next-line:no-console
              console.log(event.status);
            }
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 || error.status === 403) {
              // tslint:disable-next-line:no-console
              console.log(error.status);
            }
          }
        },
      ),
      catchError((error) => {
        let error$ = of(error);
        if (error.status === 303) {
          this.redirectService.redirectToBankRelief();
          error$ = error$.pipe(delay(1000));
        }
        return error$.pipe(mergeMap((newError) => throwError(newError)));
      }),
    );
  }

  private buildAdditionalParams(req: HttpRequest<any>): any {
    if (!req.headers.get("X-Amz-Security-Token")) {
      const customer = this.customerService.getCustomer();
      const header = new HttpHeaders(
        customer && customer.token
          ? {
              "Content-Type": "application/json",
              Authorization: customer.token,
              Authorizer_Data: customer.documentDataEncrypted,
            }
          : {
              "Content-Type": "application/json",
            },
      );

      return req.clone({
        headers: header,
      });
    } else {
      return req;
    }
  }
}
