/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./text-description.component";
var styles_TextDescriptionComponent = [i0.styles];
var RenderType_TextDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextDescriptionComponent, data: {} });
export { RenderType_TextDescriptionComponent as RenderType_TextDescriptionComponent };
function View_TextDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Icono en el texto"], ["class", "mr-3"]], [[8, "src", 4], [8, "hidden", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/images/icons/", _co.icon, ".png"); var currVal_1 = ((_co.icon === undefined) || (_co.icon === "")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TextDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textBold; _ck(_v, 1, 0, currVal_0); }); }
export function View_TextDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextDescriptionComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextDescriptionComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cssClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.icon; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.textBold; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 5, 0, currVal_2); }); }
export function View_TextDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-description", [], null, null, null, View_TextDescriptionComponent_0, RenderType_TextDescriptionComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextDescriptionComponent, [], null, null)], null, null); }
var TextDescriptionComponentNgFactory = i1.ɵccf("app-text-description", i3.TextDescriptionComponent, View_TextDescriptionComponent_Host_0, { cssClass: "cssClass", icon: "icon", text: "text", textBold: "textBold" }, {}, []);
export { TextDescriptionComponentNgFactory as TextDescriptionComponentNgFactory };
