import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { BenefitTypeEnum } from "../../../core/enums/benefit-type.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

import { MessageService } from "../../../services/local/message.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";
import { RedirectService } from "../../../services/local/redirect.service";

@Component({
  selector: "app-approved-relief-view",
  templateUrl: "./approved-relief-view.component.html",
})
export class ApprovedReliefViewComponent implements OnInit {
  icon: string;
  title: string;
  cssClassTitle: string;
  description: string;
  htmlDescription: boolean;

  formGroup: FormGroup;

  constructor(
    private messageService: MessageService,
    private payrollLoanService: PayrollLoanService,
    private redirectService: RedirectService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.getMessageForm();
    this.initForm();
    await this.getCustomerActivePayrollLoans();
  }

  async getCustomerActivePayrollLoans() {
    await this.payrollLoanService
      .getCustomerActivePayrollLoans()
      .toPromise()
      .catch(() => {
        this.router.navigate(["overflowerror"]);
      });
  }

  applyOtherLoan() {
    return this.payrollLoanService.hasActiveRelief();
  }

  getMessageForm() {
    const payrollLoanSelected: IPayrollLoan = this.payrollLoanService.getPayrollLoanSelected();
    const message = this.messageService.getMessageById(
      payrollLoanSelected.benefitType === BenefitTypeEnum.BENEFIT_TYPE_ONE
        ? MessageEnum.APPROVED_RELIEF_WITH_QUOTA
        : MessageEnum.APPROVED_RELIEF_5_DAYS,
    );

    if (message.length > 0) {
      this.icon = message[0].icon;
      this.title = message[0].title;
      this.description = message[0].description;
      this.cssClassTitle = message[0].cssClassTitle;
      this.htmlDescription = message[0].htmlDescription;
    }
  }

  clickApplyOtherLoan() {
    this.router.navigate(["salaryloan"]);
  }

  clickExit() {
    this.redirectService.redirectToBank();
  }

  private initForm() {
    this.formGroup = new FormGroup({});
  }
}
