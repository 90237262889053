import { MessageRemoteService } from "../remote/message-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/message-remote.service";
var MessageService = /** @class */ (function () {
    function MessageService(messageRemoteService) {
        this.messageRemoteService = messageRemoteService;
    }
    MessageService.prototype.getMessageById = function (messageEnum) {
        return this.messageRemoteService.getMessageList(messageEnum);
    };
    MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.MessageRemoteService)); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
