<nav class="nav nav-pills nav-justified" [formGroup]="parentForm">
  <a
    class="nav-item nav-link"
    *ngFor="let pill of pills; let i = index"
    [ngClass]="{ active: pill.active }"
    (click)="activateLink(i, pill.without)"
  >
    <span>{{ pill.text }}</span>
    <span *ngIf="checkMark && pill.active" class="ml-1">
      <img src="./assets/images/icons/checkmark.png" alt="" />
    </span>
  </a>

  <input type="hidden" [formControl]="valueControl" />
</nav>
