import { CirclePointTypeEnum } from "../../../core/enums/circle-point-type.enum";
var CirclePointWithNumberComponent = /** @class */ (function () {
    function CirclePointWithNumberComponent() {
    }
    Object.defineProperty(CirclePointWithNumberComponent.prototype, "setCirclePointType", {
        set: function (value) {
            this.circlePointType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CirclePointWithNumberComponent.prototype, "setNumber", {
        set: function (value) {
            this.number = value;
        },
        enumerable: true,
        configurable: true
    });
    CirclePointWithNumberComponent.prototype.getClassCircle = function () {
        return this.circlePointType === CirclePointTypeEnum.OK
            ? "circle-ok"
            : this.circlePointType === CirclePointTypeEnum.ACTIVE
                ? "circle-active"
                : "circle-off";
    };
    return CirclePointWithNumberComponent;
}());
export { CirclePointWithNumberComponent };
