/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../templates/one-column/one-column.component.ngfactory";
import * as i2 from "../../templates/one-column/one-column.component";
import * as i3 from "./login.component";
import * as i4 from "@angular/router";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-one-column", [], null, null, null, i1.View_OneColumnComponent_0, i1.RenderType_OneColumnComponent)), i0.ɵdid(1, 49152, null, 0, i2.OneColumnComponent, [], null, null)], null, null); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoginComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("app-login", i3.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
