export enum ErrorTypeEnum {
  ERROR_AGE_OUT_OF_RANGE = "ERROR_AGE_OUT_OF_RANGE",
  ERROR_COLLECTION_CUSTOMER = "ERROR_COLLECTION_CUSTOMER",
  ERROR_LOGIN = "ERROR_LOGIN",
  ERROR_SIM = "ERROR_SIM",

  ERROR_GENERATE_OTP = "ERROR_GENERATE_OTP",
  ERROR_INCORRECT_OTP = "ERROR_INCORRECT_OTP",
  ERROR_EXPIRE_OTP = "ERROR_EXPIRE_OTP",

  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS",
  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_AGAIN = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_AGAIN",
  ERROR_INCOMPLETE_DATA_RELIEF = "ERROR_INCOMPLETE_DATA_RELIEF",
  SIMULATION_ERROR = "SIMULATION_ERROR",
  TECHNICAL_ERROR = "TECHNICAL_ERROR",
}
