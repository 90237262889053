import { Injectable } from "@angular/core";

import { CommonTextsRemoteService } from "../remote/common-texts-remote.service";

@Injectable({
  providedIn: "root",
})
export class CommonTextsService {
  constructor(private commonTextsRemoteService: CommonTextsRemoteService) {}

  getCommonTextData(textId: number): string {
    return this.commonTextsRemoteService.searchCommonTextData(textId).text;
  }
}
