import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { ICustomer } from "../../core/interfaces/customer.interface";
import { IPayrollLoan } from "../../core/interfaces/payroll-loan.interface";

import { ReliefConfirmationRequestMapper } from "./mapper/relief-confirmationRequest.mapper";
import { SearchCustomerActivePayrollLoanRequestMapper } from "./mapper/search-customerActivePayrollLoanRequest.mapper";

@Injectable({
  providedIn: "root",
})
export class PayrollLoanRemoteService {
  constructor(private httpClient: HttpClient) {}

  getCustomerActivePayrollLoans(customer: ICustomer): Observable<object> {
    const searchCustomerActivePayrollLoanRequestMapper = new SearchCustomerActivePayrollLoanRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/payroll-loans/active",
      searchCustomerActivePayrollLoanRequestMapper.setSearchCustomerActivePayrollLoanRequestMapper(
        customer,
      ),
    );
  }

  confirmRelief(
    customer: ICustomer,
    payrollLoan: IPayrollLoan,
  ): Observable<object> {
    const reliefConfirmationRequestMapper = new ReliefConfirmationRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/confirmation",
      reliefConfirmationRequestMapper.setReliefConfirmationRequestMapper(
        customer,
        payrollLoan,
      ),
    );
  }
}
