import { Injectable } from "@angular/core";

import { IBenefitTypeMessage } from "../../core/interfaces/benefit-type-message.interface";

import { BenefitTypeDataService } from "../data/benefit-type-data.service";

@Injectable({
  providedIn: "root",
})
export class BenefitTypeRemoteService {
  constructor(private benefitTypeDataService: BenefitTypeDataService) {}

  getBenefitTypeMessage(
    benefitTypeEnum: string,
    dataPayrollLoanSimulation: any,
  ): IBenefitTypeMessage {
    return this.benefitTypeDataService
      .getBenefitTypeMessage(dataPayrollLoanSimulation)
      .filter((benefitType) => benefitType.benefitType === benefitTypeEnum)[0];
  }
}
