import { EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
var FormSelectComponent = /** @class */ (function () {
    function FormSelectComponent() {
        this.selectItemEvent = new EventEmitter();
        this.hiddenList = true;
    }
    Object.defineProperty(FormSelectComponent.prototype, "setListItemSelect", {
        set: function (value) {
            this.listSelect = value;
            this.listItemSelect = value;
        },
        enumerable: true,
        configurable: true
    });
    FormSelectComponent.prototype.showList = function () {
        this.hiddenList = !this.hiddenList;
    };
    FormSelectComponent.prototype.showListText = function () {
        this.hiddenList = false;
    };
    FormSelectComponent.prototype.selectItem = function (item) {
        this.hiddenList = true;
        this.itemSelect = item;
        this.fieldControl.setValue(item.text);
        this.valueControl.setValue(item);
        this.selectItemEvent.emit();
    };
    FormSelectComponent.prototype.changeValue = function () {
        var _this = this;
        this.itemSelect = undefined;
        if (this.fieldControl.value !== undefined) {
            this.listItemSelect = this.listSelect.filter(function (x) {
                return x.text.toLowerCase().indexOf(_this.fieldControl.value.toLowerCase()) >
                    -1;
            });
        }
    };
    FormSelectComponent.prototype.blurSelect = function () {
        var _this = this;
        setTimeout(function () {
            _this.hiddenList = true;
            if (_this.itemSelect === undefined) {
                _this.fieldControl.setValue(undefined);
                _this.changeValue();
            }
        }, 1000);
    };
    return FormSelectComponent;
}());
export { FormSelectComponent };
