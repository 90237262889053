import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { ICustomer } from "./core/interfaces/customer.interface";
import { CustomerService } from "./services/local/customer.service";

@Injectable({
  providedIn: "root",
})
export class AppAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private customerService: CustomerService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const currentCustomer: ICustomer = this.customerService.getCustomer();

    if (currentCustomer !== undefined) {
      return true;
    }

    this.router.navigate(["login"]);
    return false;
  }
}
