/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../groups/form-salary-loan/form-salary-loan.component.ngfactory";
import * as i2 from "../../groups/form-salary-loan/form-salary-loan.component";
import * as i3 from "../../../services/local/formats.service";
import * as i4 from "../../groups/progress-step-bar-with-number/progress-step-bar-with-number.component.ngfactory";
import * as i5 from "../../groups/progress-step-bar-with-number/progress-step-bar-with-number.component";
import * as i6 from "../../elements/text-title/text-title.component.ngfactory";
import * as i7 from "../../elements/text-title/text-title.component";
import * as i8 from "@angular/common";
import * as i9 from "./salary-loan-view.component";
import * as i10 from "../../../services/local/payroll-loan.service";
import * as i11 from "@angular/router";
var styles_SalaryLoanViewComponent = [];
var RenderType_SalaryLoanViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalaryLoanViewComponent, data: {} });
export { RenderType_SalaryLoanViewComponent as RenderType_SalaryLoanViewComponent };
function View_SalaryLoanViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-form-salary-loan", [], null, [[null, "reliefTypeSelectionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reliefTypeSelectionEvent" === en)) {
        var pd_0 = (_co.reliefTypeSelection(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormSalaryLoanComponent_0, i1.RenderType_FormSalaryLoanComponent)), i0.ɵdid(2, 114688, null, 0, i2.FormSalaryLoanComponent, [i3.FormatsService], { payrollLoan: [0, "payrollLoan"], number: [1, "number"] }, { reliefTypeSelectionEvent: "reliefTypeSelectionEvent" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SalaryLoanViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "d-flex flex-column justify-with-scroll-md align-items-center"], ["id", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "content-step-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-progress-step-bar-with-number", [], null, null, null, i4.View_ProgressStepBarWithNumberComponent_0, i4.RenderType_ProgressStepBarWithNumberComponent)), i0.ɵdid(3, 49152, null, 0, i5.ProgressStepBarWithNumberComponent, [], { setStpes: [0, "setStpes"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "pb-4 p-b-sm-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-text-title", [], null, null, null, i6.View_TextTitleComponent_0, i6.RenderType_TextTitleComponent)), i0.ɵdid(6, 49152, null, 0, i7.TextTitleComponent, [], { text: [0, "text"], level: [1, "level"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "content-cards cards-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryLoanViewComponent_1)), i0.ɵdid(10, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steps; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Libranzas activas"; var currVal_2 = 3; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.payrollLoans; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_SalaryLoanViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-salary-loan-view", [], null, null, null, View_SalaryLoanViewComponent_0, RenderType_SalaryLoanViewComponent)), i0.ɵdid(1, 114688, null, 0, i9.SalaryLoanViewComponent, [i10.PayrollLoanService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalaryLoanViewComponentNgFactory = i0.ɵccf("app-salary-loan-view", i9.SalaryLoanViewComponent, View_SalaryLoanViewComponent_Host_0, {}, {}, []);
export { SalaryLoanViewComponentNgFactory as SalaryLoanViewComponentNgFactory };
