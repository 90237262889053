<div class="row d-flex justify-content-center">
  <div class="col-12 col-sm-6">
    <ul class="progress-bar-step">
      <li
        class="d-flex flex-column justify-content-center"
        [ngClass]="getClassLine(step)"
        [style.width.%]="widthSteps"
        *ngFor="let step of listSteps"
      >
        <div class="step">
          <app-circle-point-with-number
            [circlePointType]="step.circlePointType"
            [number]="step.number"
          ></app-circle-point-with-number>
        </div>
      </li>
    </ul>
  </div>
</div>
