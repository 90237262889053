import { Component, Input } from "@angular/core";

@Component({
  selector: "app-text-description",
  templateUrl: "./text-description.component.html",
  styleUrls: ["./text-description.component.scss"],
})
export class TextDescriptionComponent {
  @Input() cssClass: string;
  @Input() icon: string;
  @Input() text: string;
  @Input() textBold: string;
}
