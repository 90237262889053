<div class="position-relative" [formGroup]="parentForm">
  <input
    autocomplete="off"
    type="text"
    (focus)="focusText()"
    [ngClass]="{
      'form-input-green': fieldControl.untouched || fieldControl.valid,
      'form-input-red': fieldControl.touched && !fieldControl.valid
    }"
    [formControl]="fieldControl"
    attr.maxlength="{{ lengthInput }}"
    required
  />
  <label
    [ngClass]="{
      'form-label-green': fieldControl.untouched || fieldControl.valid,
      'form-label-red': fieldControl.touched && !fieldControl.valid
    }"
  >
    {{ text }}
  </label>
  <div class="div-loading" *ngIf="loading">
    <app-loading-three-point [pointWhite]="false"></app-loading-three-point>
  </div>
</div>
<a class="a-error" *ngIf="showMessageError()">{{ textError }}</a>
