import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-form-text-overflow-y",
  templateUrl: "./form-text-overflow-y.component.html",
  styleUrls: ["./form-text-overflow-y.component.scss"],
})
export class FormTextOverflowYComponent implements OnInit {
  @Input() cssClass: string;
  @Input() cssClassDiv: string;
  @Input() text: string;

  ngOnInit() {
    this.cssClass += " content-text";
    this.cssClassDiv += " effect-content-text";
  }
}
