import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-checkbox",
  templateUrl: "./form-checkbox.component.html",
  styleUrls: ["./form-checkbox.component.scss"],
})
export class FormCheckboxComponent {
  @Input() parentForm: FormGroup;
  @Input() fieldControl: FormControl;
  @Input() typeMessageBox: string;
  @Input() text: string;
  @Input() textEnd: string;
  @Input() textSeeMore: string;
  @Output() seeMoreEvent = new EventEmitter<string>();

  seeMore(message: string) {
    this.seeMoreEvent.emit(message);
  }
}
