import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ToggleRemoteService {
  constructor(private http: HttpClient) {}

  asyncToggleList() {
    return this.http
      .get(environment.serverUrlSalaryLoans + "features/all")
      .toPromise();
  }
}
