import { Injectable } from "@angular/core";

import { ToggleEnum } from "../../core/enums/toggle.enum";

import { IToggle } from "../../core/interfaces/toggle.interface";
import { ToggleRemoteService } from "../remote/toggle-remote.service";

@Injectable({
  providedIn: "root",
})
export class ToggleService {
  listToggle: IToggle[] = [];
  constructor(private toggleRemoteService: ToggleRemoteService) {}

  async asyncToggle() {
    if (this.listToggle.length === 0) {
      // tslint:disable-next-line: no-this-assignment
      const $this = this;
      await this.toggleRemoteService
        .asyncToggleList()
        .then((data: any) => {
          $this.listToggle = data;
        })
        .catch((error) => {
          // Log
        });
    }
  }

  getToggleEnabledById(toggleEnum: ToggleEnum): boolean {
    let enabled: boolean;
    const toggle = this.listToggle.filter((x) => x.featureName === toggleEnum);

    enabled = false;
    if (toggle.length > 0) {
      enabled = toggle[0].featureEnabled;
    }

    return enabled;
  }
}
