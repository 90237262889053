import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-approved-relief",
  templateUrl: "./approved-relief.component.html",
})
export class ApprovedReliefComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "onecolumn",
        {
          outlets: { "part-center": ["approvedreliefview"] },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
