import * as tslib_1 from "tslib";
import * as encoding from "text-encoding";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
var CipherService = /** @class */ (function () {
    function CipherService() {
    }
    CipherService.prototype.asyncCipherStringRSAOAEP512 = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, window.crypto.subtle
                        .importKey("jwk", {
                        kty: "RSA",
                        e: "AQAB",
                        n: environment.rsaOaep512PublicKey,
                        alg: "RSA-OAEP-512",
                        ext: true,
                    }, {
                        name: "RSA-OAEP",
                        hash: { name: "SHA-512" },
                    }, false, ["encrypt"])
                        .then(function (key) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            return [2 /*return*/, window.crypto.subtle
                                    .encrypt({
                                    name: "RSA-OAEP",
                                }, key, new encoding.TextEncoder().encode(message))
                                    .then(function (encrypted) {
                                    var binary = "";
                                    var myBytes = new Uint8Array(encrypted);
                                    for (var i = 0; i < myBytes.byteLength; i++) {
                                        binary += String.fromCharCode(myBytes[i]);
                                    }
                                    return window.btoa(binary);
                                })];
                        });
                    }); })];
            });
        });
    };
    CipherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CipherService_Factory() { return new CipherService(); }, token: CipherService, providedIn: "root" });
    return CipherService;
}());
export { CipherService };
