import { ICustomer } from "../../../core/interfaces/customer.interface";

export class AuthenticationRequestMapper {
  setAuthenticationRequestMapper(customer: ICustomer) {
    return {
      documentType: customer.documentType,
      documentNumber: customer.documentNumber,
    };
  }
}
