<div class="radio-button" [formGroup]="parentForm">
  <input
    #radioInput
    type="radio"
    id="radio"
    name="radio"
    [formControl]="fieldControl"
    [value]="value"
  />
  <label for="radio"></label>
  <span class="check"></span>
</div>
