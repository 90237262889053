import { AfterViewInit, ElementRef, } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
var OtpInputComponent = /** @class */ (function () {
    function OtpInputComponent() {
    }
    Object.defineProperty(OtpInputComponent.prototype, "setTextFocusBack", {
        set: function (textFocusBack) {
            this.textFocusBack = textFocusBack;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OtpInputComponent.prototype, "setTextFocusNext", {
        set: function (textFocusNext) {
            this.textFocusNext = textFocusNext;
        },
        enumerable: true,
        configurable: true
    });
    OtpInputComponent.prototype.ngAfterViewInit = function () {
        if (this.focusInit) {
            this.otpInput.nativeElement.focus();
        }
    };
    OtpInputComponent.prototype.keyUp = function (event) {
        if (event.key === "Backspace") {
            if (this.textFocusBack !== undefined) {
                // tslint:disable-next-line:no-string-literal
                this.textFocusBack["otpInput"].nativeElement.focus();
            }
        }
        else {
            if (this.textFocusNext !== undefined) {
                // tslint:disable-next-line:no-string-literal
                this.textFocusNext["otpInput"].nativeElement.focus();
            }
        }
    };
    return OtpInputComponent;
}());
export { OtpInputComponent };
