import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { CommonTextsEnum } from "../../../core/enums/common-texts.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { FormPopupViewMoreComponent } from "../form-popup-view-more/form-popup-view-more.component";

import { AuthorizationService } from "../../../services/local/authorization.service";
import { CommonTextsService } from "../../../services/local/common-texts.service";

@Component({
  selector: "app-form-authorization-data",
  templateUrl: "./form-authorization-data.component.html",
})
export class FormAuthorizationDataComponent implements OnInit {
  @ViewChild("formPopupViewMore", { static: false })
  formPopupViewMore: FormPopupViewMoreComponent;

  description: string;
  hiddenViewMore = true;
  loadingButtonAuthorization: boolean;
  steps: any;
  title: string;

  formGroup: FormGroup;
  messageEnum = MessageEnum;

  constructor(
    private authorizationService: AuthorizationService,
    private commonTextsService: CommonTextsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.description =
      "Debes autorizar el tratamiento de datos y la consulta de centrales de riesgo para continuar.";
    this.steps = {
      numberSteps: 7,
      stepActive: 1,
    };
    this.title = "Tratamiento de datos";
    this.initForm();
  }

  closeSeeMore() {
    this.hiddenViewMore = true;
  }

  goToAuthentication() {
    if (this.formGroup.valid) {
      this.loadingButtonAuthorization = true;

      this.authorizationService.acceptData().subscribe(
        () => this.router.navigate(["otpauthentication"]),
        () => this.router.navigate(["overflowerror"]),
      );
    }
  }

  initForm() {
    this.formGroup = new FormGroup({});
    this.formGroup.addControl(
      "checkDataTreatment",
      new FormControl("", Validators.required),
    );
    this.formGroup.addControl(
      "checkCreditReporting",
      new FormControl("", Validators.required),
    );
  }

  seeMoreDataTreatment(event: string) {
    this.formPopupViewMore.title =
      "Autorización Para el Tratamiento de Datos Personales";
    this.formPopupViewMore.contentText = this.commonTextsService.getCommonTextData(
      CommonTextsEnum.DATA_TREATMENT,
    );
    this.hiddenViewMore = false;
  }

  seeMoreCreditReporting(event: string) {
    this.formPopupViewMore.title = "Consulta en Centrales de Riesgo";
    this.formPopupViewMore.contentText = this.commonTextsService.getCommonTextData(
      CommonTextsEnum.CREDIT_REPORTING,
    );
    this.hiddenViewMore = false;
  }
}
