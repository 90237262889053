<div id="main" class="d-flex flex-column justify-with-scroll-md">
  <div class="simply-margin-top"></div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <app-content-message
          [icon]="icon"
          [title]="title"
          [cssClassTitle]="cssClassTitle"
          [description]="description"
          [htmlDescription]="htmlDescription"
        >
        </app-content-message>

        <div
          class="w-100 d-flex justify-content-center"
          *ngIf="applyOtherLoan()"
        >
          <div class="p-t-30-px container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data-apply-other-loan"
                [cssClass]="'w-100 buttonTextMedium'"
                [mandatory]="true"
                [text]="'Aplicar beneficio a otra Libranza'"
                [parentForm]="formGroup"
                (clickEvent)="clickApplyOtherLoan()"
              >
              </app-button>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="p-t-30-px container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data"
                [cssClass]="'w-100 alternativeButton buttonTextMedium'"
                [mandatory]="true"
                [text]="'Salir'"
                [parentForm]="formGroup"
                (clickEvent)="clickExit()"
              >
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
