import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-salary-loan",
  templateUrl: "./salary-loan.component.html",
})
export class SalaryLoanComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      ["onecolumn", { outlets: { "part-center": ["salaryloanview"] } }],
      { skipLocationChange: true },
    );
  }
}
