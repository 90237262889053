import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { CustomerService } from "../local/customer.service";
import { ErrorHandlerService } from "./error-handler.service";

import { AuthorizationRemoteService } from "../remote/authorization-remote.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  customer: ICustomer;

  constructor(
    private authorizationRemoteService: AuthorizationRemoteService,
    private customerService: CustomerService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  acceptData(): Observable<any> {
    this.customer = this.customerService.getCustomer();
    this.customer.clientAcceptsDataProcessing = true;
    this.customer.clientAcceptsInquiries = true;

    return this.authorizationRemoteService
      .saveAuthorization(this.customer)
      .pipe(
        map((dataAuthorization: any) => {
          if (
            this.errorHandlerService.validateIfError(
              dataAuthorization,
              TypeMessageErrorEnum.ERROR_206,
            )
          ) {
            throw new Error(TypeMessageErrorEnum.ERROR_206);
          } else {
            this.customer.maskedCellphoneNumber =
              dataAuthorization.maskedCellphoneNumber;
            return;
          }
        }),
        catchError((errorAuthorization: any) => {
          return errorAuthorization.message === TypeMessageErrorEnum.ERROR_206
            ? throwError([])
            : this.errorHandlerService.errorUnknow(errorAuthorization);
        }),
      );
  }
}
