import { Injectable } from "@angular/core";
import * as encoding from "text-encoding";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CipherService {
  async asyncCipherStringRSAOAEP512(message: string) {
    return window.crypto.subtle
      .importKey(
        "jwk",
        {
          kty: "RSA",
          e: "AQAB",
          n: environment.rsaOaep512PublicKey,
          alg: "RSA-OAEP-512",
          ext: true,
        },
        {
          name: "RSA-OAEP",
          hash: { name: "SHA-512" },
        },
        false,
        ["encrypt"],
      )
      .then(async (key) => {
        return window.crypto.subtle
          .encrypt(
            {
              name: "RSA-OAEP",
            },
            key,
            new encoding.TextEncoder().encode(message),
          )
          .then((encrypted) => {
            let binary = "";
            const myBytes = new Uint8Array(encrypted);

            for (let i = 0; i < myBytes.byteLength; i++) {
              binary += String.fromCharCode(myBytes[i]);
            }

            return window.btoa(binary);
          });
      });
  }
}
