import { Injectable } from "@angular/core";

import { BenefitTypeEnum } from "../../core/enums/benefit-type.enum";

import { IBenefitTypeMessage } from "../../core/interfaces/benefit-type-message.interface";
import { IListSelect } from "../../core/interfaces/list-select.interface";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";

@Injectable({
  providedIn: "root",
})
export class BenefitTypeDataService {
  getBenefitTypeMessage(dataPayrollLoanSimulation: any): IBenefitTypeMessage[] {
    return [
      {
        benefitType: BenefitTypeEnum.BENEFIT_TYPE_ONE,
        icon: "./assets/images/icon-money.png",
        title: "Personaliza tu beneficio",
        description:
          "Puedes ajustar tu beneficio de acuerdo a tus necesidades, con la ayuda de un asesor especializado.",
        payrollLoan: dataPayrollLoanSimulation,
      },
      {
        benefitType: BenefitTypeEnum.BENEFIT_TYPE_TWO,
        icon: "./assets/images/icon-express.png",
        title:
          "Desembolso hasta de 6 cuotas mensuales de tu Libranza a tu cuenta",
        description: "Recibirás el dinero en 24 horas",
        inputText: "Selecciona el número de cuotas",
        labelTerm: ApplicationConstantsEnum.FEE_NUMBER,
        recommended: false,
        listTerm: this.setReliefTerms(
          dataPayrollLoanSimulation.minTerm,
          dataPayrollLoanSimulation.maxTerm,
          ApplicationConstantsEnum.FEE,
        ),
        payrollLoan: dataPayrollLoanSimulation,
      },
      {
        benefitType: BenefitTypeEnum.BENEFIT_TYPE_THREE,
        icon: "./assets/images/decision/extend-payroll-loan.svg",
        title:
          "Ampliación del monto de tu Libranza conservando la cuota actual",
        description: "Recibirás el dinero en 5 días",
        inputText: "Selecciona el plazo",
        labelTerm: ApplicationConstantsEnum.TERM,
        recommended: true,
        listTerm: this.setReliefTerms(
          dataPayrollLoanSimulation.minTerm,
          dataPayrollLoanSimulation.maxTerm,
          ApplicationConstantsEnum.MONTHS,
        ),
        payrollLoan: dataPayrollLoanSimulation,
      },
    ];
  }

  private setReliefTerms(
    minTerm: number,
    maxTerm: number,
    labelTerm: string,
  ): IListSelect[] {
    const terms = [];
    for (let index = maxTerm; index >= minTerm; index--) {
      terms.push({ value: index, text: `${index} ${labelTerm}` });
    }

    return terms;
  }
}
