<div class="position-relative" [formGroup]="parentForm">
  <input [ngClass]="{ 'form-input-green' : true, 'form-input-red': fieldControl.touched && !fieldControl.valid }" [formControl]="fieldControl" 
    type="text" attr.maxlength="{{lengthInput}}" (blur)="blurSelect()" (click)="showListText()" (input)="changeValue()" required />
  <label [ngClass]="{ 
    'form-label-green': fieldControl.untouched || fieldControl.valid,
    'form-label-red': fieldControl.touched && !fieldControl.valid 
  }">{{text}}</label>
  
  <div [ngClass]="hiddenList ? 'arrow-down' : 'arrow-up'" (click)="showList()"></div>
  
  <span [ngClass]="'span-list'" [hidden]="hiddenList">
    <div [ngClass]="'span-list-item'" *ngFor="let item of listItemSelect" (click)="selectItem(item)">
      <div [ngClass]="'span-list-item-text'">
        {{item.text}}
      </div>
    </div>
  </span>

  <input type="hidden" [formControl]="valueControl" />
</div>