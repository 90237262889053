import { Component, Input } from "@angular/core";

@Component({
  selector: "app-loading-three-point",
  templateUrl: "./loading-three-point.component.html",
  styleUrls: ["./loading-three-point.component.scss"],
})
export class LoadingThreePointComponent {
  @Input() pointWhite: boolean;
}
