import { CommonTextsRemoteService } from "../remote/common-texts-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/common-texts-remote.service";
var CommonTextsService = /** @class */ (function () {
    function CommonTextsService(commonTextsRemoteService) {
        this.commonTextsRemoteService = commonTextsRemoteService;
    }
    CommonTextsService.prototype.getCommonTextData = function (textId) {
        return this.commonTextsRemoteService.searchCommonTextData(textId).text;
    };
    CommonTextsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonTextsService_Factory() { return new CommonTextsService(i0.ɵɵinject(i1.CommonTextsRemoteService)); }, token: CommonTextsService, providedIn: "root" });
    return CommonTextsService;
}());
export { CommonTextsService };
