import { Injectable } from "@angular/core";

import { MessageEnum } from "../../core/enums/message.enum";

import { IMessage } from "../../core/interfaces/message.interface";

import { MessageDataService } from "../data/message-data.service";

@Injectable({
  providedIn: "root",
})
export class MessageRemoteService {
  constructor(private messageDataService: MessageDataService) {}

  getMessageList(messageEnum: MessageEnum): IMessage[] {
    return this.messageDataService
      .getMessageData()
      .filter((msg) => msg.messageId === messageEnum);
  }
}
