import { throwError } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { BenefitTypeEnum } from "../../core/enums/benefit-type.enum";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { SimulationRemoteService } from "../remote/simulation-remote.service";
import { BenefitTypeService } from "./benefit-type.service";
import { CustomerService } from "./customer.service";
import { ErrorHandlerService } from "./error-handler.service";
import { PayrollLoanService } from "./payroll-loan.service";
import * as i0 from "@angular/core";
import * as i1 from "./benefit-type.service";
import * as i2 from "./customer.service";
import * as i3 from "./error-handler.service";
import * as i4 from "./payroll-loan.service";
import * as i5 from "../remote/simulation-remote.service";
var SimulationService = /** @class */ (function () {
    function SimulationService(benefitTypeService, customerService, errorHandlerService, payrollLoanService, simulationRemoteService) {
        this.benefitTypeService = benefitTypeService;
        this.customerService = customerService;
        this.errorHandlerService = errorHandlerService;
        this.payrollLoanService = payrollLoanService;
        this.simulationRemoteService = simulationRemoteService;
        this.reliefTypes = [];
        this.customer = this.customerService.getCustomer();
    }
    SimulationService.prototype.simulation = function (obligationId, term, benefitType) {
        var _this = this;
        var simulationRequest = {
            documentType: this.customer.documentType,
            documentNumber: this.customer.documentNumber,
            obligationId: obligationId,
            term: term,
            benefitType: benefitType,
        };
        return this.simulationRemoteService.simulation(simulationRequest).pipe(map(function (dataSimulation) {
            if (_this.errorHandlerService.validateIfError(dataSimulation, TypeMessageErrorEnum.ERROR_206)) {
                throw new Error(TypeMessageErrorEnum.ERROR_206);
            }
            else {
                return dataSimulation;
            }
        }), catchError(function (errorSimulation) {
            return errorSimulation.message === TypeMessageErrorEnum.ERROR_206
                ? throwError([])
                : _this.errorHandlerService.errorUnknow(errorSimulation);
        }));
    };
    SimulationService.prototype.reliefSimulation = function (obligationId, benefitType) {
        var _this = this;
        this.payrollLoanSelected = this.payrollLoanService.getPayrollLoanSelected();
        this.reliefTypes = [];
        var setReliefTypes;
        if (benefitType === BenefitTypeEnum.BENEFIT_TYPE_THREE) {
            setReliefTypes = this.setReliefType(obligationId, BenefitTypeEnum.BENEFIT_TYPE_THREE).pipe(mergeMap(function () {
                return _this.setReliefType(obligationId, BenefitTypeEnum.BENEFIT_TYPE_TWO);
            }));
        }
        else {
            setReliefTypes = this.setReliefType(obligationId, BenefitTypeEnum.BENEFIT_TYPE_TWO).pipe(tap(function () {
                _this.addReliefType(BenefitTypeEnum.BENEFIT_TYPE_ONE, {
                    benefitType: BenefitTypeEnum.BENEFIT_TYPE_ONE,
                    disbursementAmount: 0,
                    minTerm: 0,
                    maxTerm: 0,
                });
            }));
        }
        return setReliefTypes.pipe(map(function () { return _this.reliefTypes; }));
    };
    SimulationService.prototype.setReliefType = function (obligationId, benefitType) {
        var _this = this;
        return this.simulation(obligationId, 0, benefitType).pipe(tap(function (dataPayrollLoanSimulation) {
            _this.addReliefType(benefitType, _this.updatePayrollLoanSimulation(benefitType, dataPayrollLoanSimulation));
        }));
    };
    SimulationService.prototype.addReliefType = function (benefitType, dataPayrollLoanSimulation) {
        this.reliefTypes.push(this.benefitTypeService.getBenefitTypeMessage(benefitType, dataPayrollLoanSimulation));
    };
    SimulationService.prototype.updatePayrollLoanSimulation = function (benefitType, dataPayrollLoanSimulation) {
        dataPayrollLoanSimulation.benefitType = benefitType;
        dataPayrollLoanSimulation.payerName = this.payrollLoanSelected.payerName;
        dataPayrollLoanSimulation.feePayment = this.payrollLoanSelected.feePayment;
        dataPayrollLoanSimulation.nominalRate = this.payrollLoanSelected.nominalRate;
        dataPayrollLoanSimulation.feeNumber = dataPayrollLoanSimulation.maxTerm;
        return dataPayrollLoanSimulation;
    };
    SimulationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SimulationService_Factory() { return new SimulationService(i0.ɵɵinject(i1.BenefitTypeService), i0.ɵɵinject(i2.CustomerService), i0.ɵɵinject(i3.ErrorHandlerService), i0.ɵɵinject(i4.PayrollLoanService), i0.ɵɵinject(i5.SimulationRemoteService)); }, token: SimulationService, providedIn: "root" });
    return SimulationService;
}());
export { SimulationService };
