import { Injectable } from "@angular/core";

import { IErrorData } from "../../core/interfaces/error-data.interface";

import { ErrorHandlerDataService } from "../data/error-handler-data.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerRemoteService {
  constructor(private errorHandlerDataService: ErrorHandlerDataService) {}

  public searchErrorsData(errorCode: string): IErrorData {
    return this.errorHandlerDataService
      .getErrorsData()
      .find((errorData: IErrorData) => errorData.errorCode === errorCode);
  }
}
