import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appOnlyNumber]",
})
export class OnlyNumberDirective {
  @HostListener("keydown", ["$event"]) onKeyDown(event: any) {
    const e = event as KeyboardEvent;

    const allowKeys: string[] = [
      "Backspace",
      "Home",
      "End",
      "ArrowRight",
      "ArrowLeft",
    ];

    if (allowKeys.indexOf(e.key) !== -1) {
      return;
    } else {
      if (isNaN(parseInt(e.key, 10))) {
        e.preventDefault();
      }
    }
  }
}
