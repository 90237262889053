import { ErrorHandlerDataService } from "../data/error-handler-data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/error-handler-data.service";
var ErrorHandlerRemoteService = /** @class */ (function () {
    function ErrorHandlerRemoteService(errorHandlerDataService) {
        this.errorHandlerDataService = errorHandlerDataService;
    }
    ErrorHandlerRemoteService.prototype.searchErrorsData = function (errorCode) {
        return this.errorHandlerDataService
            .getErrorsData()
            .find(function (errorData) { return errorData.errorCode === errorCode; });
    };
    ErrorHandlerRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerRemoteService_Factory() { return new ErrorHandlerRemoteService(i0.ɵɵinject(i1.ErrorHandlerDataService)); }, token: ErrorHandlerRemoteService, providedIn: "root" });
    return ErrorHandlerRemoteService;
}());
export { ErrorHandlerRemoteService };
