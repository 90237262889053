import { Injectable } from "@angular/core";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable, throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { IAccount } from "../../core/interfaces/account.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";

import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { ErrorHandlerService } from "./error-handler.service";

import { CustomerRemoteService } from "../remote/customer-remote.service";
import { RecaptchaRemoteService } from "../remote/recaptcha-remote.service";
import { CipherService } from "./cipher.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private customer: ICustomer;

  constructor(
    private cipherService: CipherService,
    private customerRemoteService: CustomerRemoteService,
    private errorHandlerService: ErrorHandlerService,
    private recaptchaRemoteService: RecaptchaRemoteService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  getCustomer() {
    return this.customer;
  }

  async setCustomer(customer: ICustomer) {
    this.customer = customer;
    await this.setDocumentDataEncrypted(customer);
  }

  async setDocumentDataEncrypted(customer: ICustomer) {
    await this.cipherService
      .asyncCipherStringRSAOAEP512(JSON.stringify(customer))
      .then((x) => {
        this.customer.documentDataEncrypted = x;
      });
  }

  loginCustomer(): Observable<any> {
    return this.recaptchaV3Service.execute("onInit").pipe(
      mergeMap((token) => {
        return this.recaptchaRemoteService.validations(token).pipe(
          mergeMap(() => {
            return this.validateLoginCustomer();
          }),
          catchError((errorLoginCustomer: any) => {
            return this.errorHandlerService.errorUnknow(errorLoginCustomer);
          }),
        );
      }),
      catchError((errorLoginCustomer: any) => {
        return this.errorHandlerService.errorUnknow(errorLoginCustomer);
      }),
    );
  }

  getCustomerAccounts(): Observable<IAccount[]> {
    return this.customerRemoteService.getCustomerAccounts(this.customer).pipe(
      map((dataCustomerAccounts: IAccount[]) => {
        return dataCustomerAccounts;
      }),
      catchError((errorCustomerAccounts: any) => {
        return this.errorHandlerService.errorUnknow(errorCustomerAccounts);
      }),
    );
  }

  private validateLoginCustomer(): Observable<any> {
    return this.customerRemoteService.loginCustomer(this.getCustomer()).pipe(
      map((dataLoginCustomer: any) => {
        if (
          this.errorHandlerService.validateIfError(
            dataLoginCustomer,
            TypeMessageErrorEnum.ERROR_206,
          )
        ) {
          throw new Error(TypeMessageErrorEnum.ERROR_206);
        } else {
          this.customer.firstName = dataLoginCustomer.firstName;
          this.customer.lastName = dataLoginCustomer.lastName;

          return;
        }
      }),
      catchError((errorLoginCustomer: any) => {
        return errorLoginCustomer.message === TypeMessageErrorEnum.ERROR_206
          ? throwError([])
          : this.errorHandlerService.errorUnknow(errorLoginCustomer);
      }),
    );
  }
}
