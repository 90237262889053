<div class="container-fluid h-100">
  <div class="header">
    <div class="row">
      <div class="col-10 header-content"></div>
    </div>
  </div>
  <div class="row h-100">
    <div class="col-12 h-100 w-100">
      <router-outlet name="part-center"></router-outlet>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-xl-4 col-md-12 footer-left-content"></div>
      <div class="col-xl-8 col-md-12 footer-right-content"></div>
    </div>
  </div>
</div>
