import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-confirm-relief",
  templateUrl: "./confirm-relief.component.html",
})
export class ConfirmReliefComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "onecolumn",
        {
          outlets: { "part-center": ["formconfirmrelief"] },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
