import { Injectable } from "@angular/core";

import { CommonTextsEnum } from "../../core/enums/common-texts.enum";
import { ICommonText } from "../../core/interfaces/common-text.interface";

@Injectable({
  providedIn: "root",
})
export class CommonTextsDataService {
  getCommonTextsData(): ICommonText[] {
    return [
      {
        textId: CommonTextsEnum.DATA_TREATMENT,
        text: `
        <p id="textAcceptanceData" style="display: none;">
          En adición y complemento de las autorizaciones previamente otorgadas, autorizo de manera expresa y previa sin lugar a pagos ni retribuciones al BANCO POPULAR S.A. (en adelante “EL BANCO”), a sus cesionarios o a quien represente los derechos, para que efectúe el Tratamiento de mis Datos Personales de la manera y para las finalidades que se señalan a continuación. Para efectos de la presente autorización, se entiende por “Datos Personales” la información personal de carácter financiero, crediticio, comercial, profesional, sensible (definidas en la ley tales como mis huellas, imagen o voz entre otros), técnico y administrativo, privada, semiprivada pasada, presente o futura, contenida en medios físicos, digitales o electrónicos y por “Tratamiento de datos personales” todo tipo de actividad, acción, operación o proceso, incluyendo pero sin limitarse a: recolectar, consultar, recopilar, evaluar, catalogar, clasificar, ordenar, grabar, almacenar, actualizar, modificar, aclarar, reportar, informar, analizar, utilizar, compartir, circularizar, suministrar, suprimir, procesar, solicitar, verificar, intercambiar, retirar, transferir, transmitir, disponer, organizar, ajustar, modelar, unir, consolidar, fusionar, combinar, agregar, resumir, digitalizar, explotar, rentabilizar, aprovechar, emplear y/o divulgar, y, efectuar operaciones sobre mis Datos Personales.
          <br /><br />
          Esta autorización para el Tratamiento de mis Datos Personales se hace extensiva a las entidades que conforman el Conglomerado Financiero Aval, listadas en la Resolución 0155 de 2019 expedida por la Superintendencia Financiera de Colombia o cualquier otra norma que la modifique, adicione o reemplace, así como a las entidades que forman parte del Grupo Empresarial Sarmiento Angulo, listadas en el Certificado de Existencia y Representación Legal de EL BANCO expedido por la Cámara de Comercio de Bogotá (en conjunto “LAS ENTIDADES AUTORIZADAS”).
          <br /><br />
          La autorización que otorgo a EL BANCO y a LAS ENTIDADES AUTORIZADAS por el presente medio para el Tratamiento de mis Datos Personales tendrá las siguientes finalidades:
          <br /><br />
          &nbsp; a.	Promocionar, comercializar u ofrecer, de manera individual o conjunta productos y/o servicios propios u ofrecidos en alianza comercial, a través de los canales autorizados en la ley o contrato, o para complementar, optimizar o profundizar el portafolio de productos y/o servicios actualmente ofrecidos.
          <br /><br />
          &nbsp; b.	Como elemento de análisis en etapas pre-contractuales, contractuales y post-contractuales para establecer y/o mantener relaciones contractuales, incluyendo como parte de ello, los siguientes propósitos:
          <br /><br />
          &nbsp; &nbsp; i. Evaluar riesgos derivados de la relación contractual potencial, vigente o concluida,
          <br /><br />
          &nbsp; &nbsp; ii. Realizar, validar, autorizar o verificar transacciones incluyendo, cuando sea requerido, la consulta y reproducción de datos sensibles tales como mi huella, imagen o voz,
          <br /><br />
          &nbsp; &nbsp; iii. Obtener conocimiento de mi perfil comercial o transaccional.
          <br /><br />
          &nbsp; &nbsp; iv. Conocer sobre el nacimiento, modificación, celebración, extinción, novedades, hábitos de pago, comportamiento crediticio o cumplimiento de obligaciones directas, contingentes o indirectas, de las obligaciones vigentes, activas o pasivas, o las que en el futuro llegue a celebrar, incluyendo información referente al manejo, estado, cumplimiento de mis obligaciones de pago derivadas de las relaciones, contratos y servicios, hábitos de pago, incluyendo aportes al sistema de seguridad social, obligaciones y las deudas vigentes, vencidas sin cancelar, procesos, o la utilización indebida de servicios financieros, en bases de datos administradas directamente por las partes contratantes, por operadores de información financiera, autoridades o entidades estatales, operadores de información y/o entidades que formen parte del Sistema de Seguridad Social Integral, empresas prestadoras de servicios públicos y de telefonía móvil, o administrador de bases de datos o entidades similares que en un futuro se establezca y que tenga por objeto alguna de las anteriores actividades.
          <br /><br />
          &nbsp; &nbsp; v. Ejercer mis derechos, incluyendo los referentes a actividades de cobranza judicial y extrajudicial y las gestiones conexas para obtener el pago de las obligaciones a mi cargo o de mi empleador.
          <br /><br />
          &nbsp; &nbsp; vi. Recolectar y entregar información a autoridades extranjeras con competencia sobre EL BANCO, LAS ENTIDADES AUTORIZADAS o sobre sus actividades, productos y/o servicios, cuando se requiera para dar cumplimiento a sus deberes legales o reglamentarios, incluyendo dentro de éstos, aquellos referentes a la prevención de la evasión fiscal, lavado de activos y financiación del terrorismo u otros propósitos similares.
          <br /><br />
          &nbsp; Para efectos de lo dispuesto en el presente literal b, EL BANCO en lo que resulte aplicable, podrá efectuar el Tratamiento de mis Datos Personales ante entidades de consulta, que manejen o administren bases de datos para los fines legalmente definidos, domiciliadas en Colombia o en el exterior, sean personas naturales o jurídicas, colombianas o extranjeras,
          <br /><br />
          &nbsp; c. Realizar ventas cruzadas de productos y/o servicios ofrecidos por EL BANCO o por alguna de LAS ENTIDADES AUTORIZADAS o sus aliados comerciales, incluyendo la celebración de convenios de marca compartida,
          <br /><br />
          &nbsp; d. Elaborar y reportar información contenida en encuestas de satisfacción, estudios y análisis de mercado, para lo cual autorizo la posibilidad de contactarme para dichos propósitos,
          <br /><br />
          &nbsp; e. Enviar mensajes a través de medios físicos o digitales, sobre promociones, campañas comerciales, publicitarias, de mercadeo, sorteos, eventos u otros beneficios, así como dar a conocer otros servicios y/o productos ofrecidos por EL BANCO; LAS ENTIDADES AUTORIZADAS o sus aliados comerciales,
          <br /><br />
          &nbsp; f. Enviar notificaciones a través de medios físicos o digitales, para remitir extractos, campañas institucionales o de educación financiera e informar acerca de las innovaciones o modificaciones efectuadas en sus productos y/o servicios, dar a conocer las mejoras o cambios en sus canales de atención,
          <br /><br />
          &nbsp; g. Para que mis Datos Personales puedan ser utilizados como medio de prueba.
          <br /><br />
          Mis Datos Personales podrán consultarse, circular, transmitirse y/o transferirse a la totalidad de las áreas de EL BANCO así como a los proveedores de servicios, entidades públicas o que presten servicios públicos tales como notarías, Registraduría nacional del Estado  Civil, Contraloría, Procuraduría, DIAN, oficinas de registro, cajas de compensación, administradoras de fondos de pensiones y de cesantías, operadores de información a través de las cuales se liquidan cesantías, aportes a seguridad social y parafiscales (tales como Aportes en Línea, SOI, SIMPLE), usuarios de red, redes de distribución y personas que realicen la promoción de sus productos y servicios, incluidos call centers, domiciliados en Colombia o en el exterior, sean personas naturales o jurídicas, colombianas o extranjeras a su fuerza comercial, equipos de telemercadeo y/o procesadores de datos que trabajen en nombre de EL BANCO, incluyendo contratistas, delegados, outsourcing, tercerización, red de oficinas o aliados, con el objeto de desarrollar servicios de alojamiento de sistemas, de mantenimiento, servicios de análisis, servicios de mensajería por e-mail o correo físico, servicios de entrega, gestión de transacciones de pago y cobranza, implementación de software o servicios tecnológicos (en adelante los “Terceros”) los cuales están obligados a garantizar la reserva de la información de acuerdo con lo estipulado en la Ley 1581 de 2012. En consecuencia, como titular, entiendo y acepto que mediante la presente autorización concedo a estos Terceros, autorización para:
          <br /><br />
          - Acceder a mis Datos Personales en la medida en que así lo requieran para la prestación de los servicios para los cuales fueron contratados y sujeto al cumplimiento de los deberes que les correspondan como encargados del Tratamiento de mis Datos Personales.
          <br /><br />
          - Compartir mis Datos Personales con las entidades gremiales a las que pertenezca la entidad, para fines comerciales, estadísticos y de estudio y análisis de mercadeo.
          <br /><br />
          Es entendido que las personas naturales y jurídicas, nacionales y extrajeras mencionadas anteriormente ante las cuales EL BANCO puede llevar a cabo el Tratamiento de mis Datos Personales, también cuentan con mi autorización para permitir dicho Tratamiento.
          <br /><br />
          Adicionalmente, mediante el otorgamiento de la presente autorización, manifiesto:
          <br /><br />
          &nbsp; (i) que los Datos Personales suministrados son veraces, verificables y completos,
          <br /><br />
          &nbsp; (ii) que conozco y entiendo que el suministro de la presente autorización es voluntaria, razón por la cual no me encuentro obligado a otorgar la presente autorización,
          <br /><br />
          &nbsp; (iii) que conozco y entiendo que mediante la simple presentación de una comunicación escrita puedo limitar en todo o en parte el alcance de la presente autorización, y haber sido informado sobre mis derechos a conocer, actualizar y rectificar mis Datos Personales, el carácter facultativo de mis respuestas a las preguntas que sean hechas cuando versen sobre datos sensibles o sobre datos de los niños, niñas o adolescentes, solicitar prueba de la autorización otorgada para su tratamiento, ser informado sobre el uso que se ha dado a los mismos, presentar quejas ante la autoridad competente por infracción a la ley una vez haya agotado el trámite de consulta o reclamo ante EL BANCO, revocar la presente autorización, solicitar la supresión de mis datos en los casos en que sea procedente y ejercer en forma gratuita mis derechos y garantías constitucionales y legales.
          <br /><br />
          El tratamiento de mis Datos Personales se efectuará de acuerdo con la Política de Privacidad y Protección de Datos Personales en la materia, la cual puede ser consultada en <a href='https://www.bancopopular.com.co' target='_blank'>www.bancopopular.com.co</a>
          <br /><br />
          Para el ejercicio de mis derechos, y la atención de consultas y reclamos, podré acercarme a las oficinas de EL BANCO a nivel nacional o usar la línea de servicio al cliente 743 4646 o en la Línea Nacional 01 8000 184646, o al correo electrónico: servicio@bancopopular.com.co.
        </p>`,
      },
      {
        textId: CommonTextsEnum.CREDIT_REPORTING,
        text: `
        <p>
          Autorizo de manera expresa y previa a el BANCO POPULAR y a sus filiales, subsidiarias, asociadas o vinculadas, a su matriz o controlante, a las
          filiales, subsidiarias, asociadas o vinculadas de su matriz (en adelante “LAS ENTIDADES AUTORIZADAS”) y a sus sucesores, cesionarios o a quien
          represente u ostente sus derechos, para que directamente o a través de terceros, realicen el e tratamiento en medios físicos, digitales o por
          cualquier otro, sobre mi información personal.
        </p>`,
      },
      {
        textId: CommonTextsEnum.TERMS_CONDITIONS,
        text:
          "Teniendo en cuenta la condición actual de EL CLIENTE como deudor de crédito de libranzas “Prestayá” del Banco Popular," +
          " en adelante EL BANCO, y atendiendo las actuales condiciones financieras por las que atraviesa el país, en virtud de la" +
          " coyuntura actual derivada de la pandemia ocasionada por el virus Covid-19 y con fundamento en las disposiciones señaladas" +
          " en la Circular Externa 022 de 2020 de la Superintendencia Financiera de Colombia y en el Programa de Acompañamiento a" +
          " Deudores (P.A.D.) de EL BANCO, se ponen de presente los siguientes términos y condiciones para el perfeccionamiento del" +
          " alivio otorgado por EL BANCO en favor de EL CLIENTE: 1. OBLIGACIONES DE EL BANCO: a. EL BANCO se encuentra genuinamente" +
          " comprometido en brindar a nuestros clientes posibilidades para atender sus necesidades y continuar con el debido" +
          " cumplimiento de sus obligaciones. b. Para efectos de lo anterior, EL BANCO ofrece desembolsar en favor de EL CLIENTE un monto" +
          " determinado por la altura de la madurez de su crédito actual, equivalente hasta el cupo de crédito otorgado inicialmente" +
          " sin que implique modificación de la cuota y tasa de interés pactada en el crédito. c. Desembolsar la suma de dinero previamente" +
          " aprobada a EL CLIENTE, de acuerdo con las políticas de crédito de la Entidad. d. EL BANCO realizará el desembolso del" +
          " PRESTAYA a favor de EL CLIENTE por los medios acordados y dispuestos por EL BANCO para ese fin. e. Cumplir a cabalidad con" +
          " las obligaciones a su cargo que se desprendan de este Reglamento, la ley 1527 del 2012, ley 1328 del 2009 y demás normas" +
          " que las reglamenten, adicionen, modifiquen o sustituyan. 2. OBLIGACIONES DE EL CLIENTE: a. El alivio que otorga EL BANCO" +
          " para el producto de libranzas “Prestayá” a favor del EL CLIENTE, se entenderá aceptado por éste, una vez EL CLIENTE confirme la" +
          " aceptación del Alivio, haciendo click en el check box de aceptación del mismo. b. EL CLIENTE acepta el monto y plazo aprobados" +
          " por EL BANCO, como resultado del estudio de la solicitud de crédito. En todo caso, el valor del crédito que EL BANCO apruebe" +
          " en favor del CLIENTE, se sujetará a las políticas de crédito del BANCO, especialmente, a la capacidad de pago de EL CLIENTE." +
          " c. Realizar el pago oportuno del crédito. El plazo para el pago de las cuotas pactadas empezará a contarse a partir de la" +
          " fecha de desembolso del crédito. d. En caso de que el descuento del crédito no opere por Libranza, EL CLIENTE deberá realizar" +
          " el correspondiente pago por el valor y en las fechas acordadas, de manera directa por los canales que EL BANCO destine para ese fin." +
          " e. Se obliga a suministrar información verídica, transparente, actualizada y ajustada a la realidad de sus ingresos y egresos." +
          " f. Informar a EL BANCO el cambio de ENTIDAD PAGADORA y cualquier otra novedad que se presente. g. Actualizar al menos una vez" +
          " al año, los datos suministrados a EL BANCO. h. Si luego de desembolsado el crédito, EL CLIENTE no hace uso de estos dineros," +
          " esta circunstancia no lo eximirá de su obligación de pagar el valor del crédito desembolsado junto con los cargos que se causen." +
          " i. EL CLIENTE pagará a EL BANCO intereses remuneratorios sobre el saldo insoluto de capital del crédito. En caso de incumplimiento" +
          " en el pago, EL BANCO cobrará a EL CLIENTE intereses moratorios a la tasa máxima permitida por la ley, sin perjuicio de las acciones" +
          " legales que EL BANCO pueda instaurar para obtener el recaudo de la obligación. j. Cumplir con las cláusulas generales señaladas en" +
          " el Reglamento Universal de Productos. Para mayor información lo invitamos a que consulte" +
          " <a href='https://www.bancopopular.com.co' target='_blank'>www.bancopopular.com.co</a>",
      },
    ];
  }
}
