import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { CustomerService } from "../local/customer.service";
import { ErrorHandlerService } from "./error-handler.service";
import { AuthorizationRemoteService } from "../remote/authorization-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/authorization-remote.service";
import * as i2 from "./customer.service";
import * as i3 from "./error-handler.service";
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService(authorizationRemoteService, customerService, errorHandlerService) {
        this.authorizationRemoteService = authorizationRemoteService;
        this.customerService = customerService;
        this.errorHandlerService = errorHandlerService;
    }
    AuthorizationService.prototype.acceptData = function () {
        var _this = this;
        this.customer = this.customerService.getCustomer();
        this.customer.clientAcceptsDataProcessing = true;
        this.customer.clientAcceptsInquiries = true;
        return this.authorizationRemoteService
            .saveAuthorization(this.customer)
            .pipe(map(function (dataAuthorization) {
            if (_this.errorHandlerService.validateIfError(dataAuthorization, TypeMessageErrorEnum.ERROR_206)) {
                throw new Error(TypeMessageErrorEnum.ERROR_206);
            }
            else {
                _this.customer.maskedCellphoneNumber =
                    dataAuthorization.maskedCellphoneNumber;
                return;
            }
        }), catchError(function (errorAuthorization) {
            return errorAuthorization.message === TypeMessageErrorEnum.ERROR_206
                ? throwError([])
                : _this.errorHandlerService.errorUnknow(errorAuthorization);
        }));
    };
    AuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.ɵɵinject(i1.AuthorizationRemoteService), i0.ɵɵinject(i2.CustomerService), i0.ɵɵinject(i3.ErrorHandlerService)); }, token: AuthorizationService, providedIn: "root" });
    return AuthorizationService;
}());
export { AuthorizationService };
