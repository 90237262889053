import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";
import { FormatsService } from "../../../services/local/formats.service";

@Component({
  selector: "app-form-salary-loan",
  templateUrl: "./form-salary-loan.component.html",
})
export class FormSalaryLoanComponent implements OnInit {
  @Input() payrollLoan: IPayrollLoan;
  @Input() number: string;

  @Output() reliefTypeSelectionEvent = new EventEmitter();

  approvalAmount: string;
  feeAmount: string;
  feeNumber: string;
  feePendings: any;

  formGroup: FormGroup;

  constructor(private formatsService: FormatsService) {}

  ngOnInit() {
    this.approvalAmount = this.formatsService.formatCurrency(
      this.payrollLoan.approvalAmount,
    );
    this.feeAmount = this.formatsService.formatCurrency(
      this.payrollLoan.feeAmount,
    );
    this.feeNumber = `${this.payrollLoan.feeNumber} Meses`;
    this.feePendings = this.payrollLoan.feeNumber - this.payrollLoan.feePayment;
    this.feePendings = `${this.feePendings} Meses`;
    this.formGroup = new FormGroup({});
  }

  goToReliefTypeSelection() {
    this.reliefTypeSelectionEvent.emit();
  }
}
