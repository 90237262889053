import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatsService {
  constructor(private currencyPipe: CurrencyPipe) {}

  formatCurrency(value: any) {
    return this.currencyPipe.transform(value, "COP", "$", ".0-0", "es-CO");
  }

  formatRate(value: number) {
    return `${value.toFixed(2)} %`;
  }
}
