import { InitialNavPillsRemoteService } from "../remote/initial-nav-pills-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/initial-nav-pills-remote.service";
var InitialNavPillsService = /** @class */ (function () {
    function InitialNavPillsService(initialNavPillsRemoteService) {
        this.initialNavPillsRemoteService = initialNavPillsRemoteService;
    }
    InitialNavPillsService.prototype.getInitialNavPills = function (initialNavPillsEnum) {
        return this.initialNavPillsRemoteService.getInitialNavPillsByEnum(initialNavPillsEnum);
    };
    InitialNavPillsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InitialNavPillsService_Factory() { return new InitialNavPillsService(i0.ɵɵinject(i1.InitialNavPillsRemoteService)); }, token: InitialNavPillsService, providedIn: "root" });
    return InitialNavPillsService;
}());
export { InitialNavPillsService };
