import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { AuthenticationRequestMapper } from "./mapper/authentication-request.mapper";
import { OtpRequestMapper } from "./mapper/otp-request.mapper";

@Injectable({
  providedIn: "root",
})
export class OtpAuthenticationRemoteService {
  constructor(private httpClient: HttpClient) {}

  authenticationOtp(otpValidate: any) {
    const otpRequestMapper = new OtpRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/auth/otp",
      otpRequestMapper.setOtpRequestMapper(otpValidate),
    );
  }

  generateOtpByCall(customer: ICustomer): Observable<object> {
    const authenticationRequestMapper = new AuthenticationRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/auth/otp/call",
      authenticationRequestMapper.setAuthenticationRequestMapper(customer),
    );
  }
}
