import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input("loading")
  set setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Input() cssClass: string;
  @Input() mandatory: boolean;
  @Input() text: string;
  @Input() parentForm: FormGroup;
  @Output() clickEvent = new EventEmitter<boolean>();

  loading: boolean;

  onClick() {
    this.clickEvent.emit(true);
  }
}
