/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../elements/text-title/text-title.component.ngfactory";
import * as i3 from "../../elements/text-title/text-title.component";
import * as i4 from "../../elements/text-description/text-description.component.ngfactory";
import * as i5 from "../../elements/text-description/text-description.component";
import * as i6 from "../../elements/text-html/text-html.component.ngfactory";
import * as i7 from "../../elements/text-html/text-html.component";
import * as i8 from "./content-message.component";
var styles_ContentMessageComponent = [];
var RenderType_ContentMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentMessageComponent, data: {} });
export { RenderType_ContentMessageComponent as RenderType_ContentMessageComponent };
function View_ContentMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100 h-135-px d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Icon Error"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 2, 0, currVal_0); }); }
function View_ContentMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "w-100 d-flex align-items-start justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "w-85"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-text-title", [], null, null, null, i2.View_TextTitleComponent_0, i2.RenderType_TextTitleComponent)), i0.ɵdid(5, 49152, null, 0, i3.TextTitleComponent, [], { text: [0, "text"], level: [1, "level"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "w-85"; var currVal_1 = ((_co.cssClassTitle !== "") ? _co.cssClassTitle : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.title; var currVal_3 = 3; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
function View_ContentMessageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-description", [], null, null, null, i4.View_TextDescriptionComponent_0, i4.RenderType_TextDescriptionComponent)), i0.ɵdid(1, 49152, null, 0, i5.TextDescriptionComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ContentMessageComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-html", [], null, null, null, i6.View_TextHtmlComponent_0, i6.RenderType_TextHtmlComponent)), i0.ɵdid(1, 49152, null, 0, i7.TextHtmlComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ContentMessageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "w-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "w-85 text-justify"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentMessageComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentMessageComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.htmlDescription; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.htmlDescription; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ContentMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentMessageComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentMessageComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentMessageComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.title !== ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.description !== ""); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ContentMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-content-message", [], null, null, null, View_ContentMessageComponent_0, RenderType_ContentMessageComponent)), i0.ɵdid(1, 49152, null, 0, i8.ContentMessageComponent, [], null, null)], null, null); }
var ContentMessageComponentNgFactory = i0.ɵccf("app-content-message", i8.ContentMessageComponent, View_ContentMessageComponent_Host_0, { icon: "icon", title: "title", cssClassTitle: "cssClassTitle", description: "description", htmlDescription: "htmlDescription" }, {}, []);
export { ContentMessageComponentNgFactory as ContentMessageComponentNgFactory };
