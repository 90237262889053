import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from "@angular/common";
import localesCO from "@angular/common/locales/es-CO";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { AppRoutingModule } from "../app-routing.module";
import { AppCoreModule } from "../core/app-core.module";

// Elements
import { BackgroundBlackComponent } from "./elements/background-black/background-black.component";
import { ButtonCloseComponent } from "./elements/button-close/button-close.component";
import { ButtonComponent } from "./elements/button/button.component";
import { CirclePointWithNumberComponent } from "./elements/circle-point-with-number/circle-point-with-number.component";
import { CirclePointComponent } from "./elements/circle-point/circle-point.component";
import { LoadingThreePointComponent } from "./elements/loading-three-point/loading-three-point.component";
import { OtpInputComponent } from "./elements/otp-input/otp-input.component";
import { ProgressBarComponent } from "./elements/progress-bar/progress-bar.component";
import { RadioButtonComponent } from "./elements/radio-button/radio-button.component";
import { TextDescriptionComponent } from "./elements/text-description/text-description.component";
import { TextHtmlComponent } from "./elements/text-html/text-html.component";
import { TextLabelComponent } from "./elements/text-label/text-label.component";
import { TextLinkComponent } from "./elements/text-link/text-link.component";
import { TextTitleComponent } from "./elements/text-title/text-title.component";

// Groups
import { CloseSessionComponent } from "./groups/close-session/close-session.component";
import { ContentMessageComponent } from "./groups/content-message/content-message.component";
import { FormCheckboxComponent } from "./groups/form-checkbox/form-checkbox.component";
import { FormFieldComponent } from "./groups/form-field/form-field.component";
import { FormFileInputButtonComponent } from "./groups/form-file-input-button/form-file-input-button.component";
import { FormFileInputDragComponent } from "./groups/form-file-input-drag/form-file-input-drag.component";
import { FormFileInputViewComponent } from "./groups/form-file-input-view/form-file-input-view.component";
import { FormReliefTypeComponent } from "./groups/form-relief-type/form-relief-type.component";
import { FormSalaryLoanComponent } from "./groups/form-salary-loan/form-salary-loan.component";
import { FormSelectComponent } from "./groups/form-select/form-select.component";
import { FormTextOverflowYComponent } from "./groups/form-text-overflow-y/form-text-overflow-y.component";
import { LoadingComponent } from "./groups/loading/loading.component";
import { LottieAnimationComponent } from "./groups/lottie-animation/lottie-animation.component";
import { NavPillsComponent } from "./groups/nav-pills/nav-pills.component";
import { OtpFieldComponent } from "./groups/otp-field/otp-field.component";
import { ProgressStepBarWithNumberComponent } from "./groups/progress-step-bar-with-number/progress-step-bar-with-number.component";
import { ProgressStepBarComponent } from "./groups/progress-step-bar/progress-step-bar.component";

// Structures
import { ApprovedReliefViewComponent } from "./structures/approved-relief-view/approved-relief-view.component";
import { FormAccountSelectionComponent } from "./structures/form-account-selection/form-account-selection.component";
import { FormAuthorizationDataComponent } from "./structures/form-authorization-data/form-authorization-data.component";
import { FormConfirmReliefComponent } from "./structures/form-confirm-relief/form-confirm-relief.component";
import { FormLoginComponent } from "./structures/form-login/form-login.component";
import { FormOtpAuthenticationComponent } from "./structures/form-otp-authentication/form-otp-authentication.component";
import { FormPopupMessageBoxComponent } from "./structures/form-popup-message-box/form-popup-message-box.component";
import { FormPopupViewMoreComponent } from "./structures/form-popup-view-more/form-popup-view-more.component";
import { OverflowErrorViewComponent } from "./structures/overflow-error-view/overflow-error-view.component";
import { PopupErrorViewComponent } from "./structures/popup-error-view/popup-error-view.component";
import { ReliefTypeViewComponent } from "./structures/relief-type-view/relief-type-view.component";
import { SalaryLoanViewComponent } from "./structures/salary-loan-view/salary-loan-view.component";

// Templates
import { OneColumnComponent } from "./templates/one-column/one-column.component";
import { TwoColumnComponent } from "./templates/two-column/two-column.component";

// Pages
import { AccountSelectionComponent } from "./pages/account-selection/account-selection.component";
import { ApprovedReliefComponent } from "./pages/approved-relief/approved-relief.component";
import { AuthorizationDataComponent } from "./pages/authorization-data/authorization-data.component";
import { ConfirmReliefComponent } from "./pages/confirm-relief/confirm-relief.component";
import { LoginComponent } from "./pages/login/login.component";
import { OtpAuthenticationComponent } from "./pages/otp-authentication/otp-authentication.component";
import { OverflowErrorComponent } from "./pages/overflow-error/overflow-error.component";
import { ReliefTypeComponent } from "./pages/relief-type/relief-type.component";
import { SalaryLoanComponent } from "./pages/salary-loan/salary-loan.component";

// Pipe
import { CapitalCasePipe } from "../core/pipes/capital-case.pipe";
import { SecondsToMinutesPipe } from "../core/pipes/seconds-to-minutes.pipe";

registerLocaleData(localesCO);

@NgModule({
  declarations: [
    AccountSelectionComponent,
    ApprovedReliefComponent,
    ApprovedReliefViewComponent,
    AuthorizationDataComponent,
    BackgroundBlackComponent,
    ButtonCloseComponent,
    ButtonComponent,
    CapitalCasePipe,
    CirclePointWithNumberComponent,
    CirclePointComponent,
    CloseSessionComponent,
    ConfirmReliefComponent,
    ContentMessageComponent,
    FormAccountSelectionComponent,
    FormAuthorizationDataComponent,
    FormCheckboxComponent,
    FormConfirmReliefComponent,
    FormFieldComponent,
    FormFileInputButtonComponent,
    FormFileInputDragComponent,
    FormFileInputViewComponent,
    FormLoginComponent,
    FormOtpAuthenticationComponent,
    FormPopupViewMoreComponent,
    FormPopupMessageBoxComponent,
    FormReliefTypeComponent,
    FormSalaryLoanComponent,
    FormSelectComponent,
    FormTextOverflowYComponent,
    LoadingThreePointComponent,
    LoginComponent,
    LoadingComponent,
    LottieAnimationComponent,
    NavPillsComponent,
    OneColumnComponent,
    OtpAuthenticationComponent,
    OtpFieldComponent,
    OtpInputComponent,
    OverflowErrorComponent,
    OverflowErrorViewComponent,
    PopupErrorViewComponent,
    ProgressBarComponent,
    ProgressStepBarWithNumberComponent,
    ProgressStepBarComponent,
    RadioButtonComponent,
    ReliefTypeComponent,
    ReliefTypeViewComponent,
    SalaryLoanComponent,
    SalaryLoanViewComponent,
    SecondsToMinutesPipe,
    TextDescriptionComponent,
    TextHtmlComponent,
    TextLabelComponent,
    TextLinkComponent,
    TextTitleComponent,
    TwoColumnComponent,
  ],
  imports: [
    AppCoreModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LfWScYUAAAAAMyl3_sWSZRGtfBeKZOuJnCDJQWq",
    },
    CapitalCasePipe,
    CurrencyPipe,
  ],
})
export class AppDesignModule {}
