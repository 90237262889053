/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-text-overflow-y.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../elements/text-html/text-html.component.ngfactory";
import * as i4 from "../../elements/text-html/text-html.component";
import * as i5 from "./form-text-overflow-y.component";
var styles_FormTextOverflowYComponent = [i0.styles];
var RenderType_FormTextOverflowYComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormTextOverflowYComponent, data: {} });
export { RenderType_FormTextOverflowYComponent as RenderType_FormTextOverflowYComponent };
export function View_FormTextOverflowYComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-text-html", [], null, null, null, i3.View_TextHtmlComponent_0, i3.RenderType_TextHtmlComponent)), i1.ɵdid(9, 49152, null, 0, i4.TextHtmlComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cssClassDiv; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.cssClass; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_FormTextOverflowYComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-text-overflow-y", [], null, null, null, View_FormTextOverflowYComponent_0, RenderType_FormTextOverflowYComponent)), i1.ɵdid(1, 114688, null, 0, i5.FormTextOverflowYComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormTextOverflowYComponentNgFactory = i1.ɵccf("app-form-text-overflow-y", i5.FormTextOverflowYComponent, View_FormTextOverflowYComponent_Host_0, { cssClass: "cssClass", cssClassDiv: "cssClassDiv", text: "text" }, {}, []);
export { FormTextOverflowYComponentNgFactory as FormTextOverflowYComponentNgFactory };
