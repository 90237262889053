import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppAccessGuard } from "./app-access.guard";

// Structures
import { ApprovedReliefViewComponent } from "./design/structures/approved-relief-view/approved-relief-view.component";
import { FormAccountSelectionComponent } from "./design/structures/form-account-selection/form-account-selection.component";
import { FormAuthorizationDataComponent } from "./design/structures/form-authorization-data/form-authorization-data.component";
import { FormConfirmReliefComponent } from "./design/structures/form-confirm-relief/form-confirm-relief.component";
import { FormLoginComponent } from "./design/structures/form-login/form-login.component";
import { OverflowErrorViewComponent } from "./design/structures/overflow-error-view/overflow-error-view.component";
import { ReliefTypeViewComponent } from "./design/structures/relief-type-view/relief-type-view.component";
import { SalaryLoanViewComponent } from "./design/structures/salary-loan-view/salary-loan-view.component";

// Templates
import { FormOtpAuthenticationComponent } from "./design/structures/form-otp-authentication/form-otp-authentication.component";
import { OneColumnComponent } from "./design/templates/one-column/one-column.component";

// Pages
import { AccountSelectionComponent } from "./design/pages/account-selection/account-selection.component";
import { ApprovedReliefComponent } from "./design/pages/approved-relief/approved-relief.component";
import { AuthorizationDataComponent } from "./design/pages/authorization-data/authorization-data.component";
import { ConfirmReliefComponent } from "./design/pages/confirm-relief/confirm-relief.component";
import { LoginComponent } from "./design/pages/login/login.component";
import { OtpAuthenticationComponent } from "./design/pages/otp-authentication/otp-authentication.component";
import { OverflowErrorComponent } from "./design/pages/overflow-error/overflow-error.component";
import { ReliefTypeComponent } from "./design/pages/relief-type/relief-type.component";
import { SalaryLoanComponent } from "./design/pages/salary-loan/salary-loan.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "accountselection",
    component: AccountSelectionComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "approvedrelief",
    component: ApprovedReliefComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "authorizationdata",
    component: AuthorizationDataComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "confirmrelief",
    component: ConfirmReliefComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "otpauthentication",
    component: OtpAuthenticationComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "overflowerror",
    component: OverflowErrorComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "relieftype",
    component: ReliefTypeComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "salaryloan",
    component: SalaryLoanComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "onecolumn",
    component: OneColumnComponent,
    children: [
      { path: "", component: OneColumnComponent, pathMatch: "full" },
      {
        path: "approvedreliefview",
        component: ApprovedReliefViewComponent,
        outlet: "part-center",
      },
      {
        path: "formaccountselection",
        component: FormAccountSelectionComponent,
        outlet: "part-center",
      },
      {
        path: "formauthorizationdata",
        component: FormAuthorizationDataComponent,
        outlet: "part-center",
      },
      {
        path: "formconfirmrelief",
        component: FormConfirmReliefComponent,
        outlet: "part-center",
      },
      {
        path: "formlogin",
        component: FormLoginComponent,
        outlet: "part-center",
      },
      {
        path: "formotpauthentication",
        component: FormOtpAuthenticationComponent,
        outlet: "part-center",
      },
      {
        path: "overflowerrorview",
        component: OverflowErrorViewComponent,
        outlet: "part-center",
      },
      {
        path: "relieftypeview",
        component: ReliefTypeViewComponent,
        outlet: "part-center",
      },
      {
        path: "salaryloanview",
        component: SalaryLoanViewComponent,
        outlet: "part-center",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
