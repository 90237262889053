/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circle-point-with-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./circle-point-with-number.component";
var styles_CirclePointWithNumberComponent = [i0.styles];
var RenderType_CirclePointWithNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CirclePointWithNumberComponent, data: {} });
export { RenderType_CirclePointWithNumberComponent as RenderType_CirclePointWithNumberComponent };
export function View_CirclePointWithNumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "h-100 w-100 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "d-flex align-items-center justify-content-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex align-items-center justify-content-center"; var currVal_1 = _co.getClassCircle(); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.number; _ck(_v, 5, 0, currVal_2); }); }
export function View_CirclePointWithNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-circle-point-with-number", [], null, null, null, View_CirclePointWithNumberComponent_0, RenderType_CirclePointWithNumberComponent)), i1.ɵdid(1, 49152, null, 0, i3.CirclePointWithNumberComponent, [], null, null)], null, null); }
var CirclePointWithNumberComponentNgFactory = i1.ɵccf("app-circle-point-with-number", i3.CirclePointWithNumberComponent, View_CirclePointWithNumberComponent_Host_0, { setCirclePointType: "circlePointType", setNumber: "number" }, {}, []);
export { CirclePointWithNumberComponentNgFactory as CirclePointWithNumberComponentNgFactory };
