import { Injectable } from "@angular/core";
import { BenefitTypeRemoteService } from "../remote/benefit-type-remote.service";

@Injectable({
  providedIn: "root",
})
export class BenefitTypeService {
  constructor(private benefitTypeRemoteService: BenefitTypeRemoteService) {}

  getBenefitTypeMessage(
    benefitTypeEnum: string,
    dataPayrollLoanSimulation: any,
  ) {
    return this.benefitTypeRemoteService.getBenefitTypeMessage(
      benefitTypeEnum,
      dataPayrollLoanSimulation,
    );
  }
}
