import { Component } from "@angular/core";
import { ToggleService } from "./services/local/toggle.service";

@Component({
  selector: "app-root",
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "bpop-reliefs-frontend";

  constructor(private toggleService: ToggleService) {
    this.getToggle();
  }

  private async getToggle() {
    await this.toggleService.asyncToggle();
  }
}
