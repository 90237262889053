import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SimulationSalaryLoanRequestMapper } from "./mapper/simulation-salaryLoanRequest.mapper";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SimulationRemoteService = /** @class */ (function () {
    function SimulationRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    SimulationRemoteService.prototype.simulation = function (simulationRequest) {
        var simulationSalaryLoanRequestMapper = new SimulationSalaryLoanRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/simulation", simulationSalaryLoanRequestMapper.setSimulationSalaryLoanRequestMapper(simulationRequest));
    };
    SimulationRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SimulationRemoteService_Factory() { return new SimulationRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: SimulationRemoteService, providedIn: "root" });
    return SimulationRemoteService;
}());
export { SimulationRemoteService };
