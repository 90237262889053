import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { AccountRequestMapper } from "./mapper/account-request.mapper";
import { SearchCustomerRequestMapper } from "./mapper/search-customerRequest.mapper";

@Injectable({
  providedIn: "root",
})
export class CustomerRemoteService {
  constructor(private httpClient: HttpClient) {}

  loginCustomer(customer: ICustomer): Observable<object> {
    const searchCustomerRequestMapper = new SearchCustomerRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/customer",
      searchCustomerRequestMapper.setSearchCustomerRequestMapper(customer),
    );
  }

  getCustomerAccounts(customer: ICustomer): Observable<object> {
    const accountRequestMapper = new AccountRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/customer/accounts",
      accountRequestMapper.setAccountRequestMapper(customer),
    );
  }
}
