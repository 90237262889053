/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./progress-bar.component";
var styles_ProgressBarComponent = [i0.styles];
var RenderType_ProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarComponent, data: {} });
export { RenderType_ProgressBarComponent as RenderType_ProgressBarComponent };
export function View_ProgressBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { timeRemainnigProgress: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "time-remainnig-bar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["timeRemainnigProgress", 1]], null, 0, "div", [["class", "time-remainnig-progress"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "time-remainnig-bar"; var currVal_1 = _ck(_v, 6, 0, (_co.time ? "" : "time-remainnig-bar-upload")); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_ProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-progress-bar", [], null, null, null, View_ProgressBarComponent_0, RenderType_ProgressBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressBarComponent, [i1.Renderer2], null, null)], null, null); }
var ProgressBarComponentNgFactory = i1.ɵccf("app-progress-bar", i3.ProgressBarComponent, View_ProgressBarComponent_Host_0, { setProgress: "progress", setSeconds: "seconds", time: "time" }, { infoBarCompleteEvent: "infoBarCompleteEvent" }, []);
export { ProgressBarComponentNgFactory as ProgressBarComponentNgFactory };
