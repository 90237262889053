/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-step-bar-with-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../elements/circle-point-with-number/circle-point-with-number.component.ngfactory";
import * as i4 from "../../elements/circle-point-with-number/circle-point-with-number.component";
import * as i5 from "./progress-step-bar-with-number.component";
var styles_ProgressStepBarWithNumberComponent = [i0.styles];
var RenderType_ProgressStepBarWithNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressStepBarWithNumberComponent, data: {} });
export { RenderType_ProgressStepBarWithNumberComponent as RenderType_ProgressStepBarWithNumberComponent };
function View_ProgressStepBarWithNumberComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "d-flex flex-column justify-content-center"]], [[4, "width", "%"]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "step"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-circle-point-with-number", [], null, null, null, i3.View_CirclePointWithNumberComponent_0, i3.RenderType_CirclePointWithNumberComponent)), i1.ɵdid(5, 49152, null, 0, i4.CirclePointWithNumberComponent, [], { setCirclePointType: [0, "setCirclePointType"], setNumber: [1, "setNumber"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "d-flex flex-column justify-content-center"; var currVal_2 = _co.getClassLine(_v.context.$implicit); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.circlePointType; var currVal_4 = _v.context.$implicit.number; _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widthSteps; _ck(_v, 0, 0, currVal_0); }); }
export function View_ProgressStepBarWithNumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "progress-bar-step"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressStepBarWithNumberComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listSteps; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ProgressStepBarWithNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-progress-step-bar-with-number", [], null, null, null, View_ProgressStepBarWithNumberComponent_0, RenderType_ProgressStepBarWithNumberComponent)), i1.ɵdid(1, 49152, null, 0, i5.ProgressStepBarWithNumberComponent, [], null, null)], null, null); }
var ProgressStepBarWithNumberComponentNgFactory = i1.ɵccf("app-progress-step-bar-with-number", i5.ProgressStepBarWithNumberComponent, View_ProgressStepBarWithNumberComponent_Host_0, { setStpes: "steps" }, {}, []);
export { ProgressStepBarWithNumberComponentNgFactory as ProgressStepBarWithNumberComponentNgFactory };
