/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./one-column.component";
var styles_OneColumnComponent = [];
var RenderType_OneColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OneColumnComponent, data: {} });
export { RenderType_OneColumnComponent as RenderType_OneColumnComponent };
export function View_OneColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "container-fluid h-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "col-10 header-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 h-100 w-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [["name", "part-center"]], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, "part-center"], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "col-xl-4 col-md-12 footer-left-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "col-xl-8 col-md-12 footer-right-content"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
export function View_OneColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-one-column", [], null, null, null, View_OneColumnComponent_0, RenderType_OneColumnComponent)), i0.ɵdid(1, 49152, null, 0, i2.OneColumnComponent, [], null, null)], null, null); }
var OneColumnComponentNgFactory = i0.ɵccf("app-one-column", i2.OneColumnComponent, View_OneColumnComponent_Host_0, {}, {}, []);
export { OneColumnComponentNgFactory as OneColumnComponentNgFactory };
