import { Component, Input } from "@angular/core";

import { CirclePointTypeEnum } from "../../../core/enums/circle-point-type.enum";

@Component({
  selector: "app-progress-step-bar-with-number",
  templateUrl: "./progress-step-bar-with-number.component.html",
  styleUrls: ["./progress-step-bar-with-number.component.scss"],
})
export class ProgressStepBarWithNumberComponent {
  @Input("steps")
  set setStpes(value: any) {
    this.widthSteps = 100 / value.numberSteps;

    for (let i = 0; i < value.numberSteps; i++) {
      this.listSteps.push({
        number: i + 1,
        circlePointType:
          i < value.stepActive
            ? CirclePointTypeEnum.OK
            : i === value.stepActive
            ? CirclePointTypeEnum.ACTIVE
            : CirclePointTypeEnum.OFF,
      });
    }
  }

  listSteps: any[] = [];
  widthSteps: number;

  getClassLine(step: any) {
    return step.circlePointType === CirclePointTypeEnum.OFF
      ? "line-step-off"
      : "line-step";
  }
}
