import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { BenefitTypeEnum } from "../../../core/enums/benefit-type.enum";
import { CommonTextsEnum } from "../../../core/enums/common-texts.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

import { FormPopupViewMoreComponent } from "../form-popup-view-more/form-popup-view-more.component";

import { CommonTextsService } from "../../../services/local/common-texts.service";
import { FormatsService } from "../../../services/local/formats.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";

@Component({
  selector: "app-form-confirm-relief",
  templateUrl: "./form-confirm-relief.component.html",
})
export class FormConfirmReliefComponent implements OnInit {
  @ViewChild("formPopupViewMore", { static: false })
  formPopupViewMore: FormPopupViewMoreComponent;

  description: string;
  hiddenViewMore = true;
  loadingButtonAuthorization: boolean;
  steps: any;
  title: string;

  formGroup: FormGroup;
  messageEnum = MessageEnum;

  payrollLoan: IPayrollLoan;

  disbursementAmount: string;
  totalAmount: string;
  feeAmount: string;
  feeNumber: string;
  nominalRate: string;
  effectiveRate: string;
  term: string;

  constructor(
    private commonTextsService: CommonTextsService,
    private formatsService: FormatsService,
    private payrollLoanService: PayrollLoanService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.description = "Estos serían los detalles de la Libranza";
    this.steps = {
      numberSteps: 7,
      stepActive: 6,
    };
    this.title = "Confirmación de la Libranza";
    this.payrollLoan = this.payrollLoanService.getPayrollLoanSend();
    this.disbursementAmount = this.formatsService.formatCurrency(
      this.payrollLoan.disbursementAmount,
    );
    this.totalAmount = this.formatsService.formatCurrency(
      this.payrollLoan.totalAmount,
    );
    this.feeAmount = this.formatsService.formatCurrency(
      this.payrollLoan.feeAmount,
    );
    this.feeNumber = `${this.payrollLoan.feeNumber} Meses`;
    this.nominalRate = this.formatsService.formatRate(
      this.payrollLoan.nominalRate * 100,
    );
    this.effectiveRate = this.formatsService.formatRate(
      this.payrollLoan.effectiveRate,
    );
    this.term =
      this.payrollLoan.benefitType === BenefitTypeEnum.BENEFIT_TYPE_THREE
        ? ApplicationConstantsEnum.TERM
        : ApplicationConstantsEnum.FEE_NUMBER_ADDED;
    this.initForm();
  }

  closeSeeMore() {
    this.hiddenViewMore = true;
  }

  goToApproved() {
    this.payrollLoan.authorizationAdditionalFees = this.formGroup.controls.checkDataTreatment.value;
    this.payrollLoan.authorizationTermsConditions = this.formGroup.controls.checkTermsConditions.value;

    this.payrollLoanService.confirmRelief(this.payrollLoan).subscribe(
      () => this.router.navigate(["approvedrelief"]),
      () => this.router.navigate(["overflowerror"]),
    );
  }

  initForm() {
    this.formGroup = new FormGroup({});
    this.formGroup.addControl(
      "checkDataTreatment",
      new FormControl("", Validators.required),
    );
    this.formGroup.addControl(
      "checkTermsConditions",
      new FormControl("", Validators.required),
    );
  }

  seeMoreTermsConditions(event: string) {
    this.formPopupViewMore.title = "Términos y Condiciones:";
    this.formPopupViewMore.contentText = this.commonTextsService.getCommonTextData(
      CommonTextsEnum.TERMS_CONDITIONS,
    );
    this.hiddenViewMore = false;
  }
}
