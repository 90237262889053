import { MessageDataService } from "../data/message-data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/message-data.service";
var MessageRemoteService = /** @class */ (function () {
    function MessageRemoteService(messageDataService) {
        this.messageDataService = messageDataService;
    }
    MessageRemoteService.prototype.getMessageList = function (messageEnum) {
        return this.messageDataService
            .getMessageData()
            .filter(function (msg) { return msg.messageId === messageEnum; });
    };
    MessageRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageRemoteService_Factory() { return new MessageRemoteService(i0.ɵɵinject(i1.MessageDataService)); }, token: MessageRemoteService, providedIn: "root" });
    return MessageRemoteService;
}());
export { MessageRemoteService };
