import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-account-selection",
  templateUrl: "./account-selection.component.html",
})
export class AccountSelectionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "onecolumn",
        {
          outlets: { "part-center": ["formaccountselection"] },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
