<div
  id="main"
  class="d-flex flex-column align-items-center justify-content-center"
>
  <div class="row">
    <div class="d-flex align-items-center justify-content-center p-b-18">
      <div>
        <img class="icon" [src]="overflowError.icon" alt="Icon Error" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex align-items-end pb-3">
      <div class="animation-title-bold text-center">
        <app-text-title
          [text]="overflowError.title"
          [level]="2"
        ></app-text-title>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="animation-description w-85">
        <app-text-description
          [cssClass]="'text-center'"
          [text]="overflowError.description"
        ></app-text-description>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="animation-button">
        <app-button
          id="app-button_overflow-error"
          [cssClass]="'w-100 p-t-7-px-s-40-px'"
          [loading]="false"
          [mandatory]="true"
          [parentForm]="formGroup"
          [text]="overflowError.captionButtom"
          (clickEvent)="goTo()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
