<div class="content-card pt-3 mb-4 animation-{{ number }}">
  <form class="d-flex flex-column" [formGroup]="formGroup" novalidate>
    <div class="pr-3 pl-3 bb-gray">
      <div class="row mb-3">
        <div class="col-12 d-flex align-items-end p-b-sm-6">
          <div class="title">
            <app-text-title
              [text]="payrollLoan.payerName"
              [level]="4"
            ></app-text-title>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-12">
          <div>
            <app-text-title
              [text]="'Monto inicial'"
              [level]="6"
            ></app-text-title>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <app-text-description
              [cssClass]="'font-lg-bold'"
              [text]="approvalAmount"
            ></app-text-description>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div class="row">
        <div class="col-4 br-gray">
          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Cuota'"
                ></app-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="feeAmount"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 br-gray">
          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Plazo'"
                ></app-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="feeNumber"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Pendientes'"
                ></app-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div>
                <app-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="feePendings"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="py-3 my-3 bg-gray"
      *ngIf="payrollLoan.processedRelief; else button"
    >
      <app-text-description
        [cssClass]="'mb-0 text-extra-small text-center font-weight-bold'"
        [text]="'Ya solicitaste un beneficio para esta Libranza'"
      >
      </app-text-description>
    </div>

    <ng-template #button>
      <div class="p-3 my-1">
        <div class="animation-button w-100">
          <app-button
            [cssClass]="'w-100'"
            [mandatory]="true"
            [text]="'Seleccionar'"
            [parentForm]="formGroup"
            (clickEvent)="goToReliefTypeSelection()"
          >
          </app-button>
        </div>
      </div>
    </ng-template>
  </form>
</div>
