<div class="animation-description">
  <div id="row" class="row d-flex flex-nowrap">
    <div
      class="p-l-15-px p-l-r-sm-10-px"
      *ngFor="let text of arrayText"
      [style.width]="widthPercentajeText"
    >
      <app-otp-input
        #textOtp
        [id]="text"
        [fieldControl]="formGroup.controls[text]"
        [focusInit]="true"
        [parentForm]="formGroup"
        appOnlyNumber
      >
      </app-otp-input>
    </div>
  </div>
</div>
