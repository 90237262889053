import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ErrorTypeEnum } from "../../../core/enums/error-type.enum";
import { ErrorHandlerService } from "../../../services/local/error-handler.service";

@Component({
  selector: "app-popup-error-view",
  templateUrl: "./popup-error-view.component.html",
})
export class PopupErrorViewComponent implements OnInit {
  @Input("popupError")
  set setPopupError(popupError: boolean) {
    this.popupError = popupError;
  }

  @Input("errorTypeEnum")
  set setErrorTypeEnum(errorTypeEnum: ErrorTypeEnum) {
    const errorData = this.errorHandlerService.getErrorById(errorTypeEnum);
    if (errorData) {
      this.icon = errorData.icon;
      this.title = errorData.title;
      this.description = errorData.description;
      this.captionButtom = errorData.captionButtom;
      this.urlNavigate = errorData.url;
    }
  }

  @Output() closePopupEvent = new EventEmitter<string>();

  icon: string;
  title: string;
  description: string;
  captionButtom: string;
  urlNavigate: string;
  popupError: boolean;

  formGroup: FormGroup;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({});
  }

  closePopupError() {
    this.closePopupEvent.emit();

    if (this.urlNavigate) {
      this.router.navigate([this.urlNavigate]);
    }
  }
}
