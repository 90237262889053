import { Injectable } from "@angular/core";

import { MessageEnum } from "../../core/enums/message.enum";
import { IMessage } from "../../core/interfaces/message.interface";

@Injectable({
  providedIn: "root",
})
export class MessageDataService {
  getMessageData(): IMessage[] {
    return [
      {
        messageId: MessageEnum.APPROVED_RELIEF_5_DAYS,
        icon: "./assets/images/icon-document.svg",
        title: "Solicitud éxitosa",
        cssClassTitle: "d-flex justify-content-center",
        description: `
          <p>
            Recibimos tu solicitud, el beneficio se verá reflejado en tu cuenta en los próximos 5 días.
            <br /> <br />
            Si tienes alguna inquietud comunícate al <b>743 46 46</b> en Bogotá o al <b>01 8000 184646</b> en el resto del pais.
          </p>
        `,
        htmlDescription: true,
      },
      {
        messageId: MessageEnum.APPROVED_RELIEF_WITH_QUOTA,
        icon: "./assets/images/icon-document.svg",
        title: "Solicitud éxitosa",
        cssClassTitle: "d-flex justify-content-center",
        description: `
          <p>
            Recibimos tu solicitud, el beneficio se verá reflejado en tu cuenta cuando las cuotas sean recibidas de tu empleador o entidad pagadora.
            <br /> <br />
            Si tienes alguna inquietud comunícate al <b>743 46 46</b> en Bogotá o al <b>01 8000 184646</b> en el resto del pais.
          </p>
        `,
        htmlDescription: true,
      },
    ];
  }
}
