import { BenefitTypeEnum } from "../../core/enums/benefit-type.enum";
import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import * as i0 from "@angular/core";
var BenefitTypeDataService = /** @class */ (function () {
    function BenefitTypeDataService() {
    }
    BenefitTypeDataService.prototype.getBenefitTypeMessage = function (dataPayrollLoanSimulation) {
        return [
            {
                benefitType: BenefitTypeEnum.BENEFIT_TYPE_ONE,
                icon: "./assets/images/icon-money.png",
                title: "Personaliza tu beneficio",
                description: "Puedes ajustar tu beneficio de acuerdo a tus necesidades, con la ayuda de un asesor especializado.",
                payrollLoan: dataPayrollLoanSimulation,
            },
            {
                benefitType: BenefitTypeEnum.BENEFIT_TYPE_TWO,
                icon: "./assets/images/icon-express.png",
                title: "Desembolso hasta de 6 cuotas mensuales de tu Libranza a tu cuenta",
                description: "Recibirás el dinero en 24 horas",
                inputText: "Selecciona el número de cuotas",
                labelTerm: ApplicationConstantsEnum.FEE_NUMBER,
                recommended: false,
                listTerm: this.setReliefTerms(dataPayrollLoanSimulation.minTerm, dataPayrollLoanSimulation.maxTerm, ApplicationConstantsEnum.FEE),
                payrollLoan: dataPayrollLoanSimulation,
            },
            {
                benefitType: BenefitTypeEnum.BENEFIT_TYPE_THREE,
                icon: "./assets/images/decision/extend-payroll-loan.svg",
                title: "Ampliación del monto de tu Libranza conservando la cuota actual",
                description: "Recibirás el dinero en 5 días",
                inputText: "Selecciona el plazo",
                labelTerm: ApplicationConstantsEnum.TERM,
                recommended: true,
                listTerm: this.setReliefTerms(dataPayrollLoanSimulation.minTerm, dataPayrollLoanSimulation.maxTerm, ApplicationConstantsEnum.MONTHS),
                payrollLoan: dataPayrollLoanSimulation,
            },
        ];
    };
    BenefitTypeDataService.prototype.setReliefTerms = function (minTerm, maxTerm, labelTerm) {
        var terms = [];
        for (var index = maxTerm; index >= minTerm; index--) {
            terms.push({ value: index, text: index + " " + labelTerm });
        }
        return terms;
    };
    BenefitTypeDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BenefitTypeDataService_Factory() { return new BenefitTypeDataService(); }, token: BenefitTypeDataService, providedIn: "root" });
    return BenefitTypeDataService;
}());
export { BenefitTypeDataService };
