import * as tslib_1 from "tslib";
import { ToggleRemoteService } from "../remote/toggle-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/toggle-remote.service";
var ToggleService = /** @class */ (function () {
    function ToggleService(toggleRemoteService) {
        this.toggleRemoteService = toggleRemoteService;
        this.listToggle = [];
    }
    ToggleService.prototype.asyncToggle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var $this_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.listToggle.length === 0)) return [3 /*break*/, 2];
                        $this_1 = this;
                        return [4 /*yield*/, this.toggleRemoteService
                                .asyncToggleList()
                                .then(function (data) {
                                $this_1.listToggle = data;
                            })
                                .catch(function (error) {
                                // Log
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ToggleService.prototype.getToggleEnabledById = function (toggleEnum) {
        var enabled;
        var toggle = this.listToggle.filter(function (x) { return x.featureName === toggleEnum; });
        enabled = false;
        if (toggle.length > 0) {
            enabled = toggle[0].featureEnabled;
        }
        return enabled;
    };
    ToggleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToggleService_Factory() { return new ToggleService(i0.ɵɵinject(i1.ToggleRemoteService)); }, token: ToggleService, providedIn: "root" });
    return ToggleService;
}());
export { ToggleService };
