/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../elements/background-black/background-black.component.ngfactory";
import * as i2 from "../../elements/background-black/background-black.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../elements/button-close/button-close.component.ngfactory";
import * as i5 from "../../elements/button-close/button-close.component";
import * as i6 from "../../elements/text-title/text-title.component.ngfactory";
import * as i7 from "../../elements/text-title/text-title.component";
import * as i8 from "../../groups/form-text-overflow-y/form-text-overflow-y.component.ngfactory";
import * as i9 from "../../groups/form-text-overflow-y/form-text-overflow-y.component";
import * as i10 from "../../elements/button/button.component.ngfactory";
import * as i11 from "../../elements/button/button.component";
import * as i12 from "./form-popup-view-more.component";
var styles_FormPopupViewMoreComponent = [];
var RenderType_FormPopupViewMoreComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormPopupViewMoreComponent, data: {} });
export { RenderType_FormPopupViewMoreComponent as RenderType_FormPopupViewMoreComponent };
export function View_FormPopupViewMoreComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-background-black", [], null, null, null, i1.View_BackgroundBlackComponent_0, i1.RenderType_BackgroundBlackComponent)), i0.ɵdid(1, 49152, null, 0, i2.BackgroundBlackComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 20, "form", [["class", "form-popup"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(6, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "w-100 h-50-px d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "w-90 d-flex align-items-center justify-content-end"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-button-close", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonCloseComponent_0, i4.RenderType_ButtonCloseComponent)), i0.ɵdid(10, 49152, null, 0, i5.ButtonCloseComponent, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "w-100 h-90-px d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "w-85 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-text-title", [], null, null, null, i6.View_TextTitleComponent_0, i6.RenderType_TextTitleComponent)), i0.ɵdid(14, 49152, null, 0, i7.TextTitleComponent, [], { text: [0, "text"], level: [1, "level"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 3, "div", [["class", "w-100 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 2, "div", [["class", "w-85"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "app-form-text-overflow-y", [], null, null, null, i8.View_FormTextOverflowYComponent_0, i8.RenderType_FormTextOverflowYComponent)), i0.ɵdid(18, 114688, null, 0, i9.FormTextOverflowYComponent, [], { cssClass: [0, "cssClass"], cssClassDiv: [1, "cssClassDiv"], text: [2, "text"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 3, "div", [["class", "w-100 h-110-px d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "w-85"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "app-button", [["id", "app-button_button-close"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.closeSavePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i0.ɵdid(22, 49152, null, 0, i11.ButtonComponent, [], { cssClass: [0, "cssClass"], mandatory: [1, "mandatory"], text: [2, "text"], parentForm: [3, "parentForm"] }, { clickEvent: "clickEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.title; var currVal_9 = 3; _ck(_v, 14, 0, currVal_8, currVal_9); var currVal_10 = "h-260-px"; var currVal_11 = "h-260-px"; var currVal_12 = _co.contentText; _ck(_v, 18, 0, currVal_10, currVal_11, currVal_12); var currVal_13 = "w-100 buttonTextMedium"; var currVal_14 = true; var currVal_15 = "Continuar"; var currVal_16 = _co.formGroup; _ck(_v, 22, 0, currVal_13, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormPopupViewMoreComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-popup-view-more", [], null, null, null, View_FormPopupViewMoreComponent_0, RenderType_FormPopupViewMoreComponent)), i0.ɵdid(1, 114688, null, 0, i12.FormPopupViewMoreComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormPopupViewMoreComponentNgFactory = i0.ɵccf("app-form-popup-view-more", i12.FormPopupViewMoreComponent, View_FormPopupViewMoreComponent_Host_0, { title: "title", contentText: "contentText" }, { closePopupEvent: "closePopupEvent", closeSavePopupEvent: "closeSavePopupEvent" }, []);
export { FormPopupViewMoreComponentNgFactory as FormPopupViewMoreComponentNgFactory };
