import * as tslib_1 from "tslib";
import { ToggleService } from "./services/local/toggle.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(toggleService) {
        this.toggleService = toggleService;
        this.title = "bpop-reliefs-frontend";
        this.getToggle();
    }
    AppComponent.prototype.getToggle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toggleService.asyncToggle()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
