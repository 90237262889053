<div id="main" class="d-flex flex-column justify-with-scroll-md">
  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <app-content-message [icon]="" [title]="title"> </app-content-message>

        <div class="h-14-px"></div>

        <div *ngFor="let account of listAccount; let i = index">
          <div
            *ngIf="i > 0"
            class="h-50-px w-100 d-flex align-items-center justify-content-center"
          >
            <div class="w-85">
              <hr />
            </div>
          </div>

          <div class="w-100 d-flex justify-content-center">
            <div class="w-85 content-account" (click)="selectAccount(account.id)">
              <div
                class="h-100 w-10 option float-left d-flex align-items-center"
              >
                <app-radio-button
                  [fieldControl]="formGroup.controls['radioAccount']"
                  [parentForm]="formGroup"
                  [value]="account.id"
                ></app-radio-button>
              </div>
              <div class="account float-left d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="title-account">
                    {{ account.type }}
                  </div>
                  <div class="description-account">
                    Nro
                    {{ account.number }}
                  </div>
                </div>
              </div>
              <div
                class="h-100 w-20 float-left d-flex align-items-center justify-content-end"
              >
                <div [ngClass]="getClassButtonState(account.status)">
                  {{ account.statusName }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h-14-px"></div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data"
                [cssClass]="'w-100 buttonTextMedium'"
                [loading]="loadingButtonAuthorization"
                [mandatory]="true"
                [text]="'Continuar'"
                [parentForm]="formGroup"
                (clickEvent)="clickContinue()"
              >
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
