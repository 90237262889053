import { Injectable } from "@angular/core";

import { MessageEnum } from "../../core/enums/message.enum";
import { MessageRemoteService } from "../remote/message-remote.service";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(private messageRemoteService: MessageRemoteService) {}

  getMessageById(messageEnum: MessageEnum) {
    return this.messageRemoteService.getMessageList(messageEnum);
  }
}
