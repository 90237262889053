import { Injectable } from "@angular/core";

import { ICommonText } from "../../core/interfaces/common-text.interface";
import { CommonTextsDataService } from "../data/common-texts-data.service";

@Injectable({
  providedIn: "root",
})
export class CommonTextsRemoteService {
  constructor(private commonTextsDataService: CommonTextsDataService) {}

  public searchCommonTextData(textId: number): ICommonText {
    return this.commonTextsDataService
      .getCommonTextsData()
      .find((commonText: ICommonText) => commonText.textId === textId);
  }
}
