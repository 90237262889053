import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-otp-authentication",
  templateUrl: "./otp-authentication.component.html",
})
export class OtpAuthenticationComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "onecolumn",
        {
          outlets: { "part-center": ["formotpauthentication"] },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
