import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { ErrorHandlerService } from "./error-handler.service";

import { OtpAuthenticationRemoteService } from "../remote/otp-authentication-remote.service";

@Injectable({
  providedIn: "root",
})
export class OtpAuthenticationService {
  constructor(
    private otpAuthenticationRemoteService: OtpAuthenticationRemoteService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  authenticationOtp(
    customer: ICustomer,
    otpValue: string,
  ): Observable<boolean> {
    return this.otpAuthenticationRemoteService
      .authenticationOtp({
        documentType: customer.documentType,
        documentNumber: customer.documentNumber,
        otpValue,
      })
      .pipe(
        map((dataAuthenticationOtp: any) => {
          if (
            this.errorHandlerService.validateIfError(
              dataAuthenticationOtp,
              TypeMessageErrorEnum.ERROR_206,
            )
          ) {
            throw new Error(TypeMessageErrorEnum.ERROR_206);
          } else {
            customer.token = dataAuthenticationOtp.token;
            return dataAuthenticationOtp.successOtp;
          }
        }),
        catchError((errorAuthenticationOtp: any) => {
          return errorAuthenticationOtp.message ===
            TypeMessageErrorEnum.ERROR_206
            ? throwError([])
            : this.errorHandlerService.errorUnknow(errorAuthenticationOtp);
        }),
      );
  }

  generateOtpByCall(customer: ICustomer): Observable<any> {
    return this.otpAuthenticationRemoteService.generateOtpByCall(customer).pipe(
      map(() => {
        return;
      }),
      catchError((errorGenerateOtpByCall) => {
        return this.errorHandlerService.errorUnknow(errorGenerateOtpByCall);
      }),
    );
  }
}
