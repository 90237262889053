import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { SimulationSalaryLoanRequestMapper } from "./mapper/simulation-salaryLoanRequest.mapper";

@Injectable({
  providedIn: "root",
})
export class SimulationRemoteService {
  constructor(private httpClient: HttpClient) {}

  simulation(simulationRequest: any): Observable<object> {
    const simulationSalaryLoanRequestMapper = new SimulationSalaryLoanRequestMapper();

    return this.httpClient.post(
      environment.serverUrlReliefs + "reliefs-core/simulation",
      simulationSalaryLoanRequestMapper.setSimulationSalaryLoanRequestMapper(
        simulationRequest,
      ),
    );
  }
}
