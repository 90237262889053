import { EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent() {
        this.focusTextEvent = new EventEmitter();
    }
    Object.defineProperty(FormFieldComponent.prototype, "setLoading", {
        set: function (loading) {
            this.loading = loading;
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.focusText = function () {
        this.focusTextEvent.emit();
    };
    FormFieldComponent.prototype.showMessageError = function () {
        return (this.fieldControl.touched &&
            !this.fieldControl.valid &&
            (this.textError !== undefined || this.textError !== ""));
    };
    return FormFieldComponent;
}());
export { FormFieldComponent };
