import { InitialNavPillsDataService } from "../data/initial-nav-pills-data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/initial-nav-pills-data.service";
var InitialNavPillsRemoteService = /** @class */ (function () {
    function InitialNavPillsRemoteService(initialNavPillsDataService) {
        this.initialNavPillsDataService = initialNavPillsDataService;
    }
    InitialNavPillsRemoteService.prototype.getInitialNavPillsByEnum = function (initialNavPillsEnum) {
        return this.initialNavPillsDataService
            .getInitialNavPills()
            .filter(function (initialNavPill) { return initialNavPill.id === initialNavPillsEnum; });
    };
    InitialNavPillsRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InitialNavPillsRemoteService_Factory() { return new InitialNavPillsRemoteService(i0.ɵɵinject(i1.InitialNavPillsDataService)); }, token: InitialNavPillsRemoteService, providedIn: "root" });
    return InitialNavPillsRemoteService;
}());
export { InitialNavPillsRemoteService };
