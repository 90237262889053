import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { BenefitTypeEnum } from "../../../core/enums/benefit-type.enum";

import { IBenefitTypeMessage } from "../../../core/interfaces/benefit-type-message.interface";
import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

import { FormatsService } from "../../../services/local/formats.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";
import { RedirectService } from "../../../services/local/redirect.service";
import { SimulationService } from "../../../services/local/simulation.service";

@Component({
  selector: "app-relief-type-view",
  templateUrl: "./relief-type-view.component.html",
})
export class ReliefTypeViewComponent implements OnInit, OnDestroy {
  hiddenLoading: boolean;
  payerName: string;
  payerNameText: string;
  quotaAndRate: string;
  quotaAndRateText: string;
  reliefTypes: IBenefitTypeMessage[] = [];
  steps: any;
  title: string;

  private payrollLoanSelected: IPayrollLoan;
  private reliefSimulation: Subscription;

  constructor(
    private formatsService: FormatsService,
    private payrollLoanService: PayrollLoanService,
    private redirectService: RedirectService,
    private router: Router,
    private simulationService: SimulationService,
  ) {}

  ngOnInit() {
    this.initText();
    this.setBenefitTypes();
  }

  ngOnDestroy() {
    this.reliefSimulation.unsubscribe();
  }

  accountSelection(payrollLoan: IPayrollLoan) {
    this.payrollLoanService.setPayrollLoanSend(payrollLoan);
    payrollLoan.benefitType === BenefitTypeEnum.BENEFIT_TYPE_ONE
      ? this.redirectService.redirectToBankRelief()
      : this.router.navigate(["accountselection"]);
  }

  private initText() {
    this.payrollLoanSelected = this.payrollLoanService.getPayrollLoanSelected();
    this.payerName = this.payrollLoanSelected.payerName;
    this.payerNameText = "Tu pagaduría seleccionada es: ";
    this.quotaAndRate = `${this.formatsService.formatCurrency(
      this.payrollLoanSelected.feeAmount,
    )} - ${this.formatsService.formatRate(
      this.payrollLoanSelected.nominalRate * 100,
    )} M.V`;

    this.quotaAndRateText =
      "La cuota y tasa de interés seguirán siendo las mismas pactadas inicialmente";

    this.steps = {
      numberSteps: 7,
      stepActive: 4,
    };

    this.title = `Tenemos 2 tipos de beneficios para ti`;
  }

  private setBenefitTypes() {
    this.reliefSimulation = this.simulationService
      .reliefSimulation(
        this.payrollLoanSelected.obligationId,
        this.payrollLoanSelected.benefitType,
      )
      .subscribe(
        (dataReliefTypes) => {
          this.reliefTypes = dataReliefTypes;
          this.hiddenLoading = true;
        },
        () => this.router.navigate(["overflowerror"]),
      );
  }
}
