<div *ngIf="popupError">
  <app-background-black></app-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img [src]="icon" alt="Icon Error" />
      </div>
    </div>
    <div
      class="w-100 h-40-px m-b-10-px d-flex align-items-start justify-content-center"
    >
      <div class="w-85 text-center">
        <app-text-title [level]="3" [text]="title"></app-text-title>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-justify">
        <app-text-description [text]="description"></app-text-description>
      </div>
    </div>
    <div class="w-100 h-110-px d-flex justify-content-center">
      <div class="w-85 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <app-button
            id="app-button_button-click"
            [cssClass]="'w-100 buttonTextMedium'"
            [mandatory]="true"
            [text]="captionButtom"
            [parentForm]="formGroup"
            (clickEvent)="closePopupError()"
          ></app-button>
        </div>
      </div>
    </div>
  </form>
</div>
