/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-close.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-close.component";
var styles_ButtonCloseComponent = [i0.styles];
var RenderType_ButtonCloseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonCloseComponent, data: {} });
export { RenderType_ButtonCloseComponent as RenderType_ButtonCloseComponent };
export function View_ButtonCloseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["type", "button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "button-close"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ButtonCloseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-close", [], null, null, null, View_ButtonCloseComponent_0, RenderType_ButtonCloseComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonCloseComponent, [], null, null)], null, null); }
var ButtonCloseComponentNgFactory = i1.ɵccf("app-button-close", i3.ButtonCloseComponent, View_ButtonCloseComponent_Host_0, {}, {}, []);
export { ButtonCloseComponentNgFactory as ButtonCloseComponentNgFactory };
