<div id="main" class="d-flex flex-column justify-with-scroll-md">

  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <app-content-message
          [icon]=""
          [title]="title"
          [description]="description"
        >
        </app-content-message>

        <div class="h-14-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <app-nav-pills
              id="app-nav-pills_select-nationality"
              [checkMark]="true"
              [pills]="pills"
              [valueControl]="formGroup.controls['valuePill']"
              [parentForm]="formGroup"
            ></app-nav-pills>
          </div>
        </div>

        <div class="h-25-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <app-form-field
              id="app-form-field_customer-login-document"
              (keyup.enter)="clickContinue()"
              [lengthInput]="'15'"
              [fieldControl]="formGroup.controls['textDocumentCustomer']"
              [parentForm]="formGroup"
              [text]="'Número de documento'"
              appOnlyNumber
            >
            </app-form-field>
          </div>
        </div>

        <div class="h-14-px"></div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data"
                [cssClass]="'w-100 buttonTextMedium'"
                [loading]="loadingButtonAuthorization"
                [mandatory]="true"
                [text]="'Continuar'"
                [parentForm]="formGroup"
                (clickEvent)="clickContinue()"
              >
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
