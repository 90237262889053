import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { CustomerService } from "./customer.service";
import { ErrorHandlerService } from "./error-handler.service";
import { PayrollLoanRemoteService } from "../remote/payroll-loan-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "./customer.service";
import * as i2 from "./error-handler.service";
import * as i3 from "../remote/payroll-loan-remote.service";
var PayrollLoanService = /** @class */ (function () {
    function PayrollLoanService(customerService, errorHandlerService, payrollLoanRemoteService) {
        this.customerService = customerService;
        this.errorHandlerService = errorHandlerService;
        this.payrollLoanRemoteService = payrollLoanRemoteService;
    }
    PayrollLoanService.prototype.getCustomerActivePayrollLoans = function () {
        var _this = this;
        return this.payrollLoanRemoteService
            .getCustomerActivePayrollLoans(this.customerService.getCustomer())
            .pipe(map(function (dataPayrollLoan) {
            _this.payrollLoans = dataPayrollLoan;
            return dataPayrollLoan;
        }), catchError(function (errorActivePayroll) {
            return _this.errorHandlerService.errorUnknow(errorActivePayroll);
        }));
    };
    PayrollLoanService.prototype.getPayrollLoanSelected = function () {
        return this.payrollLoanSelected;
    };
    PayrollLoanService.prototype.setPayrollLoanSelected = function (payrollLoan) {
        this.payrollLoanSelected = payrollLoan;
    };
    PayrollLoanService.prototype.getPayrollLoanSend = function () {
        return this.payrollLoanSend;
    };
    PayrollLoanService.prototype.setPayrollLoanSend = function (payrollLoan) {
        this.payrollLoanSend = payrollLoan;
    };
    PayrollLoanService.prototype.setAccountId = function (accountId) {
        this.payrollLoanSend.accountId = accountId;
    };
    PayrollLoanService.prototype.confirmRelief = function (payrollLoan) {
        var _this = this;
        return this.payrollLoanRemoteService
            .confirmRelief(this.customerService.getCustomer(), payrollLoan)
            .pipe(map(function (dataRelief) {
            if (_this.errorHandlerService.validateIfError(dataRelief, TypeMessageErrorEnum.ERROR_206)) {
                throw new Error(TypeMessageErrorEnum.ERROR_206);
            }
            else {
                return dataRelief;
            }
        }), catchError(function (errorRelief) {
            return errorRelief.message === TypeMessageErrorEnum.ERROR_206
                ? throwError([])
                : _this.errorHandlerService.errorUnknow(errorRelief);
        }));
    };
    PayrollLoanService.prototype.hasActiveRelief = function () {
        return (this.payrollLoans.filter(function (payrollLoan) { return !payrollLoan.processedRelief; })
            .length > 0);
    };
    PayrollLoanService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PayrollLoanService_Factory() { return new PayrollLoanService(i0.ɵɵinject(i1.CustomerService), i0.ɵɵinject(i2.ErrorHandlerService), i0.ɵɵinject(i3.PayrollLoanRemoteService)); }, token: PayrollLoanService, providedIn: "root" });
    return PayrollLoanService;
}());
export { PayrollLoanService };
