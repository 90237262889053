import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppDesignModule } from "./design/app-design.module";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [AppDesignModule, AppRoutingModule, BrowserModule, HttpClientModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
