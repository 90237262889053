import { CirclePointTypeEnum } from "../../../core/enums/circle-point-type.enum";
var ProgressStepBarWithNumberComponent = /** @class */ (function () {
    function ProgressStepBarWithNumberComponent() {
        this.listSteps = [];
    }
    Object.defineProperty(ProgressStepBarWithNumberComponent.prototype, "setStpes", {
        set: function (value) {
            this.widthSteps = 100 / value.numberSteps;
            for (var i = 0; i < value.numberSteps; i++) {
                this.listSteps.push({
                    number: i + 1,
                    circlePointType: i < value.stepActive
                        ? CirclePointTypeEnum.OK
                        : i === value.stepActive
                            ? CirclePointTypeEnum.ACTIVE
                            : CirclePointTypeEnum.OFF,
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ProgressStepBarWithNumberComponent.prototype.getClassLine = function (step) {
        return step.circlePointType === CirclePointTypeEnum.OFF
            ? "line-step-off"
            : "line-step";
    };
    return ProgressStepBarWithNumberComponent;
}());
export { ProgressStepBarWithNumberComponent };
