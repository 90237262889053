import { CurrencyPipe } from "@angular/common";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var FormatsService = /** @class */ (function () {
    function FormatsService(currencyPipe) {
        this.currencyPipe = currencyPipe;
    }
    FormatsService.prototype.formatCurrency = function (value) {
        return this.currencyPipe.transform(value, "COP", "$", ".0-0", "es-CO");
    };
    FormatsService.prototype.formatRate = function (value) {
        return value.toFixed(2) + " %";
    };
    FormatsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormatsService_Factory() { return new FormatsService(i0.ɵɵinject(i1.CurrencyPipe)); }, token: FormatsService, providedIn: "root" });
    return FormatsService;
}());
export { FormatsService };
