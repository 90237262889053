<div id="main" class="d-flex flex-column justify-with-scroll-lg">
  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex flex-column">
              <div>
                <app-text-title [text]="title" [level]="3"> </app-text-title>
              </div>
              <div class="mt-3">
                <app-text-description
                  [cssClass]="'font-weight-bold'"
                  [text]="description"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="'Desembolso: '"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2'"
                  [text]="disbursementAmount"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="'Pagaduría: '"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2 ml-2 text-right'"
                  [text]="payrollLoan.payerName"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-1'"
                  [text]="'Número de obligación: '"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-1'"
                  [text]="payrollLoan.obligationId"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <hr />
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="'Total de tu deuda por Libranza'"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2'"
                  [text]="totalAmount"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="'Cuota fija Mensual'"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2'"
                  [text]="feeAmount"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="term"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2'"
                  [text]="feeNumber"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-2'"
                  [text]="'Tasa Nominal M.V'"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-2'"
                  [text]="nominalRate"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="d-flex justify-content-between">
              <div>
                <app-text-description
                  [cssClass]="'mb-1'"
                  [text]="'Equivalente tasa efectiva anual'"
                ></app-text-description>
              </div>
              <div>
                <app-text-description
                  [cssClass]="'font-weight-bold mb-1'"
                  [text]="effectiveRate"
                ></app-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <hr />
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <app-text-description
              [cssClass]="'font-weight-bold'"
              [text]="'Autorizaciones'"
            ></app-text-description>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="animation-text-offices">
              <app-form-checkbox
                id="app-form-checkbox_data-treatment"
                [parentForm]="formGroup"
                [fieldControl]="formGroup.controls['checkDataTreatment']"
                [typeMessageBox]="messageEnum.PERSONAL_DATA_INFO"
                [text]="
                  'Autorizo a mi empleador o entidad pagadora el descuento del número de cuotas adicionales que se requieran para la cancelación total de la obligación.'
                "
              >
              </app-form-checkbox>
            </div>
          </div>
        </div>

        <div class="h-25-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="animation-text-offices">
              <app-form-checkbox
                id="app-form-checkbox_terms-conditions"
                [parentForm]="formGroup"
                [fieldControl]="formGroup.controls['checkTermsConditions']"
                [typeMessageBox]="messageEnum.TERMS_CONDITIONS_INFO"
                [text]="'El cliente declara que conoce y acepta los '"
                [textEnd]="' del producto de Libranza del Banco Popular.'"
                [textSeeMore]="'términos y condiciones'"
                (seeMoreEvent)="seeMoreTermsConditions($event)"
              >
              </app-form-checkbox>
            </div>
          </div>
        </div>

        <div class="h-14-px"></div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data"
                [cssClass]="'w-100 buttonTextMedium'"
                [loading]="loadingButtonAuthorization"
                [mandatory]="true"
                [text]="'Acepto el beneficio'"
                [parentForm]="formGroup"
                (clickEvent)="goToApproved()"
              >
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<app-form-popup-view-more
  #formPopupViewMore
  [hidden]="hiddenViewMore"
  (closePopupEvent)="closeSeeMore()"
  (closeSavePopupEvent)="closeSeeMore()"
></app-form-popup-view-more>
