import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ReliefConfirmationRequestMapper } from "./mapper/relief-confirmationRequest.mapper";
import { SearchCustomerActivePayrollLoanRequestMapper } from "./mapper/search-customerActivePayrollLoanRequest.mapper";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PayrollLoanRemoteService = /** @class */ (function () {
    function PayrollLoanRemoteService(httpClient) {
        this.httpClient = httpClient;
    }
    PayrollLoanRemoteService.prototype.getCustomerActivePayrollLoans = function (customer) {
        var searchCustomerActivePayrollLoanRequestMapper = new SearchCustomerActivePayrollLoanRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/payroll-loans/active", searchCustomerActivePayrollLoanRequestMapper.setSearchCustomerActivePayrollLoanRequestMapper(customer));
    };
    PayrollLoanRemoteService.prototype.confirmRelief = function (customer, payrollLoan) {
        var reliefConfirmationRequestMapper = new ReliefConfirmationRequestMapper();
        return this.httpClient.post(environment.serverUrlReliefs + "reliefs-core/confirmation", reliefConfirmationRequestMapper.setReliefConfirmationRequestMapper(customer, payrollLoan));
    };
    PayrollLoanRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PayrollLoanRemoteService_Factory() { return new PayrollLoanRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: PayrollLoanRemoteService, providedIn: "root" });
    return PayrollLoanRemoteService;
}());
export { PayrollLoanRemoteService };
