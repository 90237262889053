import { Injectable } from "@angular/core";

import { INavPill } from "../../core/interfaces/nav-pill.interface";

import { DocumentTypeEnum } from "../../core/enums/document-type.enum";
import { InitialNavPillsEnum } from "../../core/enums/initial-nav-pills.enum";

@Injectable({
  providedIn: "root",
})
export class InitialNavPillsDataService {
  getInitialNavPills(): INavPill[] {
    return [
      {
        id: InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
        active: true,
        text: "Cédula de ciudadanía",
        value: DocumentTypeEnum.CC,
      },
      {
        id: InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
        active: false,
        text: "Cédula de extranjería",
        value: DocumentTypeEnum.CE,
      },
    ];
  }
}
