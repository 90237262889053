/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-three-point.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-three-point.component";
var styles_LoadingThreePointComponent = [i0.styles];
var RenderType_LoadingThreePointComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingThreePointComponent, data: {} });
export { RenderType_LoadingThreePointComponent as RenderType_LoadingThreePointComponent };
export function View_LoadingThreePointComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "h-100 w-100 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "h-100 w-100 d-flex align-items-center justify-content-center"; var currVal_1 = (_co.pointWhite ? "div-point-white" : "div-point-green"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.pointWhite ? "point-white" : "point-green"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.pointWhite ? "point-white" : "point-green"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.pointWhite ? "point-white" : "point-green"); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_LoadingThreePointComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-three-point", [], null, null, null, View_LoadingThreePointComponent_0, RenderType_LoadingThreePointComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingThreePointComponent, [], null, null)], null, null); }
var LoadingThreePointComponentNgFactory = i1.ɵccf("app-loading-three-point", i3.LoadingThreePointComponent, View_LoadingThreePointComponent_Host_0, { pointWhite: "pointWhite" }, {}, []);
export { LoadingThreePointComponentNgFactory as LoadingThreePointComponentNgFactory };
