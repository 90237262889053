import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { IAccount } from "../../../core/interfaces/account.interface";

import { AccountStatusEnum } from "../../../core/enums/account-status.enum";

import { CustomerService } from "../../../services/local/customer.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";

@Component({
  selector: "app-form-account-selection",
  templateUrl: "./form-account-selection.component.html",
  styleUrls: ["./form-account-selection.component.scss"],
})
export class FormAccountSelectionComponent implements OnInit {
  description: string;
  loadingButtonAuthorization: boolean;
  steps: any;
  title: string;

  formGroup: FormGroup;
  listAccount: IAccount[];

  constructor(
    private customerService: CustomerService,
    private payrollLoanService: PayrollLoanService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.steps = {
      numberSteps: 7,
      stepActive: 5,
    };
    this.title = "Cuenta para desembolso";

    this.setAccount();
    this.initForm();
  }

  clickContinue() {
    this.payrollLoanService.setAccountId(
      this.formGroup.controls.radioAccount.value,
    );
    this.router.navigate(["confirmrelief"]);
  }

  getClassButtonState(status: string) {
    return status === AccountStatusEnum.ACTIVE
      ? "button-state-active"
      : status === AccountStatusEnum.INACTIVE
      ? "button-state-inactive"
      : "button-state-new";
  }

  selectAccount(accountId: any) {
    this.formGroup.controls.radioAccount.setValue(accountId);
  }

  private initForm() {
    this.formGroup = new FormGroup({});
    this.formGroup.addControl(
      "radioAccount",
      new FormControl("", Validators.required),
    );
  }

  private setAccount() {
    this.customerService.getCustomerAccounts().subscribe(
      (dataAccount) => (this.listAccount = dataAccount),
      () => this.router.navigate(["overflowerror"]),
    );
  }
}
