import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { IOverflowError } from "../../../core/interfaces/overflow-error.interface";

import { ErrorHandlerService } from "../../../services/local/error-handler.service";

@Component({
  selector: "app-overflow-error-view",
  templateUrl: "./overflow-error-view.component.html",
  styleUrls: ["./overflow-error-view.component.scss"],
})
export class OverflowErrorViewComponent implements OnInit, OnDestroy {
  overflowError: IOverflowError;
  formGroup: FormGroup;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.overflowError = this.errorHandlerService.getError();
    this.initForm();
  }

  ngOnDestroy() {
    this.errorHandlerService.clearError();
  }

  initForm() {
    this.formGroup = new FormGroup({});
  }

  goTo() {
    this.router.navigate([this.overflowError.url]);
  }
}
