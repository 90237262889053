<div id="main" class="d-flex flex-column justify-with-scroll-md">

  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <app-content-message
          [icon]=""
          [title]="title"
          [description]="description">
        </app-content-message>

        <div class="h-14-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="animation-text-offices">
                <app-form-checkbox
                  id="app-form-checkbox_data-treatment"
                  [parentForm]="formGroup"
                  [fieldControl]="formGroup.controls['checkDataTreatment']"
                  [typeMessageBox]="messageEnum.PERSONAL_DATA_INFO"
                  [text]="
                    'Autorizo a el BANCO POPULAR a que realice el tratamiento sobre mi información personal'
                  "
                  [textSeeMore]="'Ver mas...'"
                  (seeMoreEvent)="seeMoreDataTreatment($event)">
                </app-form-checkbox>
              </div>
          </div>          
        </div>

        <div class="h-25-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <div class="animation-text-offices">
                <app-form-checkbox
                  id="app-form-checkbox_credit-reporting"
                  [parentForm]="formGroup"
                  [fieldControl]="formGroup.controls['checkCreditReporting']"
                  [typeMessageBox]="messageEnum.RISK_CENTERS_INFO"
                  [text]="
                    'Autorizo a el BANCO POPULAR a que realicen la consulta de centrales de riesgos de mi información personal'
                  "
                  [textSeeMore]="'Ver mas...'"
                  (seeMoreEvent)="seeMoreCreditReporting($event)">
                </app-form-checkbox>
              </div>
          </div>
        </div>

        <div class="h-14-px"></div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_accept-data"
                [cssClass]="'w-100 buttonTextMedium'"
                [loading]="loadingButtonAuthorization"
                [mandatory]="true"
                [text]="'Continuar'"
                [parentForm]="formGroup"
                (clickEvent)="goToAuthentication()">
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<app-form-popup-view-more
  #formPopupViewMore
  [hidden]="hiddenViewMore"
  (closePopupEvent)="closeSeeMore()"
  (closeSavePopupEvent)="closeSeeMore()"
></app-form-popup-view-more>
