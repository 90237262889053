import { EventEmitter, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ErrorTypeEnum } from "../../../core/enums/error-type.enum";
import { ErrorHandlerService } from "../../../services/local/error-handler.service";
var PopupErrorViewComponent = /** @class */ (function () {
    function PopupErrorViewComponent(errorHandlerService, router) {
        this.errorHandlerService = errorHandlerService;
        this.router = router;
        this.closePopupEvent = new EventEmitter();
    }
    Object.defineProperty(PopupErrorViewComponent.prototype, "setPopupError", {
        set: function (popupError) {
            this.popupError = popupError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupErrorViewComponent.prototype, "setErrorTypeEnum", {
        set: function (errorTypeEnum) {
            var errorData = this.errorHandlerService.getErrorById(errorTypeEnum);
            if (errorData) {
                this.icon = errorData.icon;
                this.title = errorData.title;
                this.description = errorData.description;
                this.captionButtom = errorData.captionButtom;
                this.urlNavigate = errorData.url;
            }
        },
        enumerable: true,
        configurable: true
    });
    PopupErrorViewComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    PopupErrorViewComponent.prototype.initForm = function () {
        this.formGroup = new FormGroup({});
    };
    PopupErrorViewComponent.prototype.closePopupError = function () {
        this.closePopupEvent.emit();
        if (this.urlNavigate) {
            this.router.navigate([this.urlNavigate]);
        }
    };
    return PopupErrorViewComponent;
}());
export { PopupErrorViewComponent };
