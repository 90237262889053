/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./text-link.component";
var styles_TextLinkComponent = [i0.styles];
var RenderType_TextLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextLinkComponent, data: {} });
export { RenderType_TextLinkComponent as RenderType_TextLinkComponent };
export function View_TextLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵted(2, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.text; _ck(_v, 2, 0, currVal_2); }); }
export function View_TextLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-link", [], null, null, null, View_TextLinkComponent_0, RenderType_TextLinkComponent)), i1.ɵdid(1, 49152, null, 0, i4.TextLinkComponent, [], null, null)], null, null); }
var TextLinkComponentNgFactory = i1.ɵccf("app-text-link", i4.TextLinkComponent, View_TextLinkComponent_Host_0, { text: "text" }, {}, []);
export { TextLinkComponentNgFactory as TextLinkComponentNgFactory };
