<div class="d-flex align-items-start" [formGroup]="parentForm">
  <div class="checkbox">
    <input type="checkbox" [id]="typeMessageBox" [formControl]="fieldControl" required>
    <div class="checkbox-icon"></div>
  </div>
  <div class="label">
    <label [for]="typeMessageBox">{{ text }}</label>
    <a *ngIf="textSeeMore" (click)="seeMore(typeMessageBox)"> {{ textSeeMore }}</a>
    <span *ngIf="textEnd" class="textEnd">{{ textEnd }}</span>      
  </div>
</div>