import { Component, Input } from "@angular/core";

@Component({
  selector: "app-content-message",
  templateUrl: "./content-message.component.html",
})
export class ContentMessageComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() cssClassTitle: string;
  @Input() description: string;
  @Input() htmlDescription: boolean;
}
