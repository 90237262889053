import { BenefitTypeDataService } from "../data/benefit-type-data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/benefit-type-data.service";
var BenefitTypeRemoteService = /** @class */ (function () {
    function BenefitTypeRemoteService(benefitTypeDataService) {
        this.benefitTypeDataService = benefitTypeDataService;
    }
    BenefitTypeRemoteService.prototype.getBenefitTypeMessage = function (benefitTypeEnum, dataPayrollLoanSimulation) {
        return this.benefitTypeDataService
            .getBenefitTypeMessage(dataPayrollLoanSimulation)
            .filter(function (benefitType) { return benefitType.benefitType === benefitTypeEnum; })[0];
    };
    BenefitTypeRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BenefitTypeRemoteService_Factory() { return new BenefitTypeRemoteService(i0.ɵɵinject(i1.BenefitTypeDataService)); }, token: BenefitTypeRemoteService, providedIn: "root" });
    return BenefitTypeRemoteService;
}());
export { BenefitTypeRemoteService };
