import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RecaptchaRemoteService {
  constructor(private httpClient: HttpClient) {}

  validations(token: string): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlRecaptcha + "recaptcha/validations",
      {
        response: token,
      },
    );
  }
}
