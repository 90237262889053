<div class="h-100 w-100 d-flex align-items-center justify-content-center">
  <div
    class="d-flex align-items-center justify-content-center"
    [ngClass]="getClassCircle()"
  >
    <div>
      {{ number }}
    </div>
  </div>
</div>
