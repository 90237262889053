/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../templates/one-column/one-column.component.ngfactory";
import * as i2 from "../../templates/one-column/one-column.component";
import * as i3 from "./salary-loan.component";
import * as i4 from "@angular/router";
var styles_SalaryLoanComponent = [];
var RenderType_SalaryLoanComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalaryLoanComponent, data: {} });
export { RenderType_SalaryLoanComponent as RenderType_SalaryLoanComponent };
export function View_SalaryLoanComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-one-column", [], null, null, null, i1.View_OneColumnComponent_0, i1.RenderType_OneColumnComponent)), i0.ɵdid(1, 49152, null, 0, i2.OneColumnComponent, [], null, null)], null, null); }
export function View_SalaryLoanComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-salary-loan", [], null, null, null, View_SalaryLoanComponent_0, RenderType_SalaryLoanComponent)), i0.ɵdid(1, 114688, null, 0, i3.SalaryLoanComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalaryLoanComponentNgFactory = i0.ɵccf("app-salary-loan", i3.SalaryLoanComponent, View_SalaryLoanComponent_Host_0, {}, {}, []);
export { SalaryLoanComponentNgFactory as SalaryLoanComponentNgFactory };
