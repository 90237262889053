/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lottie-animation.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lottie-animation.component";
var styles_LottieAnimationComponent = [i0.styles];
var RenderType_LottieAnimationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LottieAnimationComponent, data: {} });
export { RenderType_LottieAnimationComponent as RenderType_LottieAnimationComponent };
export function View_LottieAnimationComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { animation: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["animation", 1]], null, 0, "div", [["class", "animation-lottie"]], [[4, "width", null], [4, "height", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_LottieAnimationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lottie-animation", [], null, null, null, View_LottieAnimationComponent_0, RenderType_LottieAnimationComponent)), i1.ɵdid(1, 114688, null, 0, i2.LottieAnimationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LottieAnimationComponentNgFactory = i1.ɵccf("app-lottie-animation", i2.LottieAnimationComponent, View_LottieAnimationComponent_Host_0, { path: "path", renderer: "renderer", loop: "loop", autoplay: "autoplay", width: "width", height: "height" }, {}, []);
export { LottieAnimationComponentNgFactory as LottieAnimationComponentNgFactory };
