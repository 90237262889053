import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { INavPill } from "../../../core/interfaces/nav-pill.interface";

@Component({
  selector: "app-nav-pills",
  templateUrl: "./nav-pills.component.html",
  styleUrls: ["./nav-pills.component.scss"],
})
export class NavPillsComponent implements OnInit {
  @Input() pills: INavPill[];
  @Input() checkMark: boolean;
  @Input() parentForm: FormGroup;
  @Input() valueControl: FormControl;

  constructor(private formBuilder: FormBuilder, private router: Router) {}

  ngOnInit() {
    let index = 0;
    this.pills.forEach((pill) => {
      if (pill.active) {
        this.activateLink(index);
      }

      index++;
    });
  }

  activateLink(index: number, without?: boolean) {
    if (without === undefined || !without) {
      this.pills.forEach((pill) => {
        pill.active = false;
      });

      this.pills[index].active = true;
      this.valueControl.setValue(this.pills[index].value);

      if (this.pills[index].url !== undefined && this.pills[index].url !== "") {
        this.router.navigate([this.pills[index].url]);
      }
    }
  }
}
