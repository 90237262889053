<div class="w-100 h-135-px d-flex justify-content-center" *ngIf="icon">
  <div>
    <img [src]="icon" alt="Icon Error" />
  </div>
</div>
<div
  class="w-100 d-flex align-items-start justify-content-center"
  *ngIf="title !== ''"
>
  <div class="w-85" [ngClass]="cssClassTitle !== '' ? cssClassTitle : ''">
    <app-text-title [level]="3" [text]="title"></app-text-title>
  </div>
</div>
<div class="w-100 d-flex justify-content-center" *ngIf="description !== ''">
  <div class="w-85 text-justify">
    <app-text-description
      [text]="description"
      *ngIf="!htmlDescription"
    ></app-text-description>

    <app-text-html [text]="description" *ngIf="htmlDescription"></app-text-html>
  </div>
</div>
