import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { ErrorTypeEnum } from "../../../core/enums/error-type.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { CapitalCasePipe } from "../../../core/pipes/capital-case.pipe";

import { ICustomer } from "../../../core/interfaces/customer.interface";

import { ProgressBarComponent } from "../../elements/progress-bar/progress-bar.component";

import { OtpFieldComponent } from "../../groups/otp-field/otp-field.component";

import { CustomerService } from "../../../services/local/customer.service";
import { ErrorHandlerService } from "../../../services/local/error-handler.service";
import { OtpAuthenticationService } from "../../../services/local/otp-authentication.service";
import { ToggleService } from "../../../services/local/toggle.service";

@Component({
  selector: "app-form-otp-authentication",
  templateUrl: "./form-otp-authentication.component.html",
  styleUrls: ["./form-otp-authentication.component.scss"],
})
export class FormOtpAuthenticationComponent implements OnInit {
  @ViewChild("progressBar", { static: false })
  progressBar: ProgressBarComponent;

  @ViewChild("otpField", { static: false })
  otpField: OtpFieldComponent;

  authenticationOtpSuccess: boolean;
  countRetrySendOtp: number;
  description: string;
  loadingButtonAuthenticateOtp: boolean;
  numberInputOtp: number;
  popupError: boolean;
  steps: any;
  timeRemaining: string;
  title: string;

  customer: ICustomer;
  errorTypeEnum: ErrorTypeEnum;
  formGroup: FormGroup;

  constructor(
    private capitalCasePipe: CapitalCasePipe,
    private customerService: CustomerService,
    private otpAuthenticationService: OtpAuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  ngOnInit() {
    this.customer = this.customerService.getCustomer();

    this.countRetrySendOtp = 0;
    this.numberInputOtp = this.toggleService.getToggleEnabledById(
      ToggleEnum.ADL_TEAM_CLIENT_VALIDATION,
    )
      ? ApplicationConstantsEnum.OTP_LONG
      : ApplicationConstantsEnum.OTP_SHORT;

    // tslint:disable-next-line:max-line-length
    this.description = `Ingresa el código de seguridad que enviamos a tu celular: ${this.customer.maskedCellphoneNumber}.`;
    this.steps = {
      numberSteps: 7,
      stepActive: 2,
    };

    this.title = `${this.capitalCasePipe.transform(
      this.customer.firstName,
    )}, mira tu celular`;

    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({});
  }

  authenticateCodeOtp() {
    if (this.formGroup.valid) {
      this.loadingButtonAuthenticateOtp = true;

      this.otpAuthenticationService
        .authenticationOtp(this.customer, this.otpField.getValueOtp())
        .subscribe(
          () => {
            this.authenticationOtpSuccess = true;
            this.router.navigate(["salaryloan"]);
          },
          () => this.authenticateCodeOtpError(),
        );
    }
  }

  authenticateCodeOtpError() {
    this.countRetrySendOtp++;

    if (this.countRetrySendOtp === ApplicationConstantsEnum.NUMBER_ATTEMPTS) {
      if (localStorage.getItem("attempts")) {
        this.errorHandlerService.setError(
          ErrorTypeEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_AGAIN,
        );
      } else {
        this.errorHandlerService.setError(
          ErrorTypeEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS,
        );
        localStorage.setItem("attempts", "first");
      }
      this.router.navigate(["overflowerror"]);
    } else {
      this.popupError = true;
      this.errorTypeEnum = ErrorTypeEnum.ERROR_INCORRECT_OTP;
    }
  }

  generateOtpByCall() {
    this.loadingButtonAuthenticateOtp = true;

    this.otpAuthenticationService.generateOtpByCall(this.customer).subscribe(
      () => this.clearOtpText(true),
      () => this.router.navigate(["overflowerror"]),
    );
  }

  infoBarComplete() {
    if (!this.authenticationOtpSuccess) {
      this.popupError = true;
      this.errorTypeEnum = ErrorTypeEnum.ERROR_EXPIRE_OTP;
    }
  }

  closePopupError() {
    this.popupError = false;

    if (this.errorTypeEnum === ErrorTypeEnum.ERROR_EXPIRE_OTP) {
      this.loadingButtonAuthenticateOtp = true;
    } else {
      this.clearOtpText(false);
    }
  }

  private clearOtpText(restart: boolean) {
    this.otpField.clearOtpText();
    this.loadingButtonAuthenticateOtp = false;

    if (restart) {
      this.progressBar.restartWithSeconds();
    }
  }
}
