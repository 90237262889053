import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";
import { ErrorHandlerService } from "./error-handler.service";
import { OtpAuthenticationRemoteService } from "../remote/otp-authentication-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/otp-authentication-remote.service";
import * as i2 from "./error-handler.service";
var OtpAuthenticationService = /** @class */ (function () {
    function OtpAuthenticationService(otpAuthenticationRemoteService, errorHandlerService) {
        this.otpAuthenticationRemoteService = otpAuthenticationRemoteService;
        this.errorHandlerService = errorHandlerService;
    }
    OtpAuthenticationService.prototype.authenticationOtp = function (customer, otpValue) {
        var _this = this;
        return this.otpAuthenticationRemoteService
            .authenticationOtp({
            documentType: customer.documentType,
            documentNumber: customer.documentNumber,
            otpValue: otpValue,
        })
            .pipe(map(function (dataAuthenticationOtp) {
            if (_this.errorHandlerService.validateIfError(dataAuthenticationOtp, TypeMessageErrorEnum.ERROR_206)) {
                throw new Error(TypeMessageErrorEnum.ERROR_206);
            }
            else {
                customer.token = dataAuthenticationOtp.token;
                return dataAuthenticationOtp.successOtp;
            }
        }), catchError(function (errorAuthenticationOtp) {
            return errorAuthenticationOtp.message ===
                TypeMessageErrorEnum.ERROR_206
                ? throwError([])
                : _this.errorHandlerService.errorUnknow(errorAuthenticationOtp);
        }));
    };
    OtpAuthenticationService.prototype.generateOtpByCall = function (customer) {
        var _this = this;
        return this.otpAuthenticationRemoteService.generateOtpByCall(customer).pipe(map(function () {
            return;
        }), catchError(function (errorGenerateOtpByCall) {
            return _this.errorHandlerService.errorUnknow(errorGenerateOtpByCall);
        }));
    };
    OtpAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OtpAuthenticationService_Factory() { return new OtpAuthenticationService(i0.ɵɵinject(i1.OtpAuthenticationRemoteService), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: OtpAuthenticationService, providedIn: "root" });
    return OtpAuthenticationService;
}());
export { OtpAuthenticationService };
