import { Component, Input } from "@angular/core";

import { CirclePointTypeEnum } from "../../../core/enums/circle-point-type.enum";

@Component({
  selector: "app-circle-point-with-number",
  templateUrl: "./circle-point-with-number.component.html",
  styleUrls: ["./circle-point-with-number.component.scss"],
})
export class CirclePointWithNumberComponent {
  @Input("circlePointType")
  set setCirclePointType(value: CirclePointTypeEnum) {
    this.circlePointType = value;
  }

  @Input("number")
  set setNumber(value: number) {
    this.number = value;
  }

  circlePointType: CirclePointTypeEnum;
  number: number;

  getClassCircle(): string {
    return this.circlePointType === CirclePointTypeEnum.OK
      ? "circle-ok"
      : this.circlePointType === CirclePointTypeEnum.ACTIVE
      ? "circle-active"
      : "circle-off";
  }
}
