import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { BenefitTypeEnum } from "../../../core/enums/benefit-type.enum";

import { IBenefitTypeMessage } from "../../../core/interfaces/benefit-type-message.interface";
import { IPayrollLoan } from "../../../core/interfaces/payroll-loan.interface";

import { FormatsService } from "../../../services/local/formats.service";
import { SimulationService } from "../../../services/local/simulation.service";

@Component({
  selector: "app-form-relief-type",
  templateUrl: "./form-relief-type.component.html",
  styleUrls: ["./form-relief-type.component.scss"],
})
export class FormReliefTypeComponent implements OnInit {
  @Input() reliefType: IBenefitTypeMessage;
  @Input() number: string;

  @Output() accountSelectionEvent = new EventEmitter<IPayrollLoan>();

  listTerm: any;
  loadingButtonRelief: boolean;
  payrollLoanCard: IPayrollLoan;
  disbursementAmount: string;

  formGroup: FormGroup;

  constructor(
    private formatsService: FormatsService,
    private router: Router,
    private simulationService: SimulationService,
  ) {}

  ngOnInit() {
    this.payrollLoanCard = this.reliefType.payrollLoan;
    this.disbursementAmount =
      this.payrollLoanCard.disbursementAmount < 1
        ? undefined
        : this.formatsService.formatCurrency(
            this.payrollLoanCard.disbursementAmount,
          );
    this.listTerm = this.reliefType.listTerm;
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({});

    if (this.reliefType.benefitType !== BenefitTypeEnum.BENEFIT_TYPE_ONE) {
      this.formGroup.addControl(
        "textTerm",
        new FormControl(this.listTerm[0].text),
      );
      this.formGroup.addControl(
        "valueTerm",
        new FormControl(this.listTerm[0].value),
      );
    }
  }

  goToAccountSelection() {
    this.accountSelectionEvent.emit(this.payrollLoanCard);
  }

  setSelectTerm() {
    this.disbursementAmount = ApplicationConstantsEnum.CHARACTER_EMPTY;
    this.loadingButtonRelief = true;
    this.simulationService
      .simulation(
        this.payrollLoanCard.obligationId,
        this.formGroup.controls.valueTerm.value.value,
        this.payrollLoanCard.benefitType,
      )
      .subscribe(
        (dataSimulation) => {
          this.loadingButtonRelief = false;
          this.disbursementAmount = this.formatsService.formatCurrency(
            dataSimulation.disbursementAmount,
          );

          this.payrollLoanCard.disbursementAmount =
            dataSimulation.disbursementAmount;
          this.payrollLoanCard.nominalRate = dataSimulation.approvalRate;
          this.payrollLoanCard.totalAmount = dataSimulation.totalAmount;
          this.payrollLoanCard.feeNumber = this.formGroup.controls.valueTerm.value.value;
        },
        () => {
          this.router.navigate(["overflowerror"]);
        },
      );
  }
}
