import { CommonTextsDataService } from "../data/common-texts-data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/common-texts-data.service";
var CommonTextsRemoteService = /** @class */ (function () {
    function CommonTextsRemoteService(commonTextsDataService) {
        this.commonTextsDataService = commonTextsDataService;
    }
    CommonTextsRemoteService.prototype.searchCommonTextData = function (textId) {
        return this.commonTextsDataService
            .getCommonTextsData()
            .find(function (commonText) { return commonText.textId === textId; });
    };
    CommonTextsRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonTextsRemoteService_Factory() { return new CommonTextsRemoteService(i0.ɵɵinject(i1.CommonTextsDataService)); }, token: CommonTextsRemoteService, providedIn: "root" });
    return CommonTextsRemoteService;
}());
export { CommonTextsRemoteService };
