import { Component, Input } from "@angular/core";

@Component({
  selector: "app-text-title",
  templateUrl: "./text-title.component.html",
  styleUrls: ["./text-title.component.scss"],
})
export class TextTitleComponent {
  @Input() text: string;
  @Input() level: number;
}
