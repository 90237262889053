import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { IBenefitTypeMessage } from "../../core/interfaces/benefit-type-message.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IPayrollLoan } from "../../core/interfaces/payroll-loan.interface";

import { BenefitTypeEnum } from "../../core/enums/benefit-type.enum";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { SimulationRemoteService } from "../remote/simulation-remote.service";

import { BenefitTypeService } from "./benefit-type.service";
import { CustomerService } from "./customer.service";
import { ErrorHandlerService } from "./error-handler.service";
import { PayrollLoanService } from "./payroll-loan.service";

@Injectable({
  providedIn: "root",
})
export class SimulationService {
  customer: ICustomer;
  payrollLoanSelected: IPayrollLoan;
  reliefTypes: IBenefitTypeMessage[] = [];

  constructor(
    private benefitTypeService: BenefitTypeService,
    private customerService: CustomerService,
    private errorHandlerService: ErrorHandlerService,
    private payrollLoanService: PayrollLoanService,
    private simulationRemoteService: SimulationRemoteService,
  ) {
    this.customer = this.customerService.getCustomer();
  }

  simulation(
    obligationId: string,
    term: number,
    benefitType: string,
  ): Observable<any> {
    const simulationRequest = {
      documentType: this.customer.documentType,
      documentNumber: this.customer.documentNumber,
      obligationId,
      term,
      benefitType,
    };

    return this.simulationRemoteService.simulation(simulationRequest).pipe(
      map((dataSimulation: any) => {
        if (
          this.errorHandlerService.validateIfError(
            dataSimulation,
            TypeMessageErrorEnum.ERROR_206,
          )
        ) {
          throw new Error(TypeMessageErrorEnum.ERROR_206);
        } else {
          return dataSimulation;
        }
      }),
      catchError((errorSimulation: any) => {
        return errorSimulation.message === TypeMessageErrorEnum.ERROR_206
          ? throwError([])
          : this.errorHandlerService.errorUnknow(errorSimulation);
      }),
    );
  }

  reliefSimulation(
    obligationId: string,
    benefitType: string,
  ): Observable<IBenefitTypeMessage[]> {
    this.payrollLoanSelected = this.payrollLoanService.getPayrollLoanSelected();
    this.reliefTypes = [];

    let setReliefTypes: Observable<any>;

    if (benefitType === BenefitTypeEnum.BENEFIT_TYPE_THREE) {
      setReliefTypes = this.setReliefType(
        obligationId,
        BenefitTypeEnum.BENEFIT_TYPE_THREE,
      ).pipe(
        mergeMap(() =>
          this.setReliefType(obligationId, BenefitTypeEnum.BENEFIT_TYPE_TWO),
        ),
      );
    } else {
      setReliefTypes = this.setReliefType(
        obligationId,
        BenefitTypeEnum.BENEFIT_TYPE_TWO,
      ).pipe(
        tap(() => {
          this.addReliefType(BenefitTypeEnum.BENEFIT_TYPE_ONE, {
            benefitType: BenefitTypeEnum.BENEFIT_TYPE_ONE,
            disbursementAmount: 0,
            minTerm: 0,
            maxTerm: 0,
          });
        }),
      );
    }

    return setReliefTypes.pipe(map(() => this.reliefTypes));
  }

  private setReliefType(
    obligationId: string,
    benefitType: BenefitTypeEnum,
  ): Observable<any> {
    return this.simulation(obligationId, 0, benefitType).pipe(
      tap((dataPayrollLoanSimulation) => {
        this.addReliefType(
          benefitType,
          this.updatePayrollLoanSimulation(
            benefitType,
            dataPayrollLoanSimulation,
          ),
        );
      }),
    );
  }

  private addReliefType(
    benefitType: BenefitTypeEnum,
    dataPayrollLoanSimulation: any,
  ) {
    this.reliefTypes.push(
      this.benefitTypeService.getBenefitTypeMessage(
        benefitType,
        dataPayrollLoanSimulation,
      ),
    );
  }

  private updatePayrollLoanSimulation(
    benefitType: BenefitTypeEnum,
    dataPayrollLoanSimulation: any,
  ) {
    dataPayrollLoanSimulation.benefitType = benefitType;
    dataPayrollLoanSimulation.payerName = this.payrollLoanSelected.payerName;
    dataPayrollLoanSimulation.feePayment = this.payrollLoanSelected.feePayment;
    dataPayrollLoanSimulation.nominalRate = this.payrollLoanSelected.nominalRate;
    dataPayrollLoanSimulation.feeNumber = dataPayrollLoanSimulation.maxTerm;

    return dataPayrollLoanSimulation;
  }
}
