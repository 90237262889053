/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./otp-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/otp-input/otp-input.component.ngfactory";
import * as i3 from "../../../core/directives/only-number.directive";
import * as i4 from "../../elements/otp-input/otp-input.component";
import * as i5 from "@angular/common";
import * as i6 from "./otp-field.component";
import * as i7 from "@angular/platform-browser";
var styles_OtpFieldComponent = [i0.styles];
var RenderType_OtpFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OtpFieldComponent, data: {} });
export { RenderType_OtpFieldComponent as RenderType_OtpFieldComponent };
function View_OtpFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "p-l-15-px p-l-r-sm-10-px"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-otp-input", [["appOnlyNumber", ""]], [[8, "id", 0]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OtpInputComponent_0, i2.RenderType_OtpInputComponent)), i1.ɵdid(2, 16384, null, 0, i3.OnlyNumberDirective, [], null, null), i1.ɵdid(3, 4243456, [[1, 4], ["textOtp", 4]], 0, i4.OtpInputComponent, [], { fieldControl: [0, "fieldControl"], focusInit: [1, "focusInit"], parentForm: [2, "parentForm"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.formGroup.controls[_v.context.$implicit]; var currVal_3 = true; var currVal_4 = _co.formGroup; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widthPercentajeText; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
export function View_OtpFieldComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { textOtp: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "animation-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row d-flex flex-nowrap"], ["id", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OtpFieldComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.arrayText; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OtpFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-otp-field", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).calculateWidthText($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OtpFieldComponent_0, RenderType_OtpFieldComponent)), i1.ɵdid(1, 4308992, null, 0, i6.OtpFieldComponent, [i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OtpFieldComponentNgFactory = i1.ɵccf("app-otp-field", i6.OtpFieldComponent, View_OtpFieldComponent_Host_0, { numberInputText: "numberInputText", formGroup: "formGroup" }, {}, []);
export { OtpFieldComponentNgFactory as OtpFieldComponentNgFactory };
