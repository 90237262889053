import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

import { ErrorCodeEnum } from "../../core/enums/error-code.enum";
import { ErrorTypeEnum } from "../../core/enums/error-type.enum";
import { TypeMessageErrorEnum } from "../../core/enums/type-message-error.enum";

import { IErrorData } from "../../core/interfaces/error-data.interface";
import { ErrorHandlerRemoteService } from "../remote/error-handler-remote.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  error: IErrorData;

  constructor(private errorHandlerRemoteService: ErrorHandlerRemoteService) {}

  clearError() {
    this.error = undefined;
  }

  getError(): IErrorData {
    return this.error;
  }

  setError(errorTypeEnum: ErrorTypeEnum) {
    this.error = this.errorHandlerRemoteService.searchErrorsData(errorTypeEnum);
  }

  getErrorById(errorTypeEnum: ErrorTypeEnum) {
    return this.errorHandlerRemoteService.searchErrorsData(errorTypeEnum);
  }

  errorUnknow(error: any) {
    if (error.error) {
      this.validateIfError(error, TypeMessageErrorEnum.ERROR_500);
    }

    return throwError([]);
  }

  validateIfError(
    object: any,
    typeMessageError: TypeMessageErrorEnum,
  ): boolean {
    let isError = false;

    if (!object.code) {
      if (TypeMessageErrorEnum.ERROR_500 === typeMessageError) {
        isError = true;
        this.errorOverflow(ErrorCodeEnum.FinancialReliefs999);
      }
    } else {
      isError = true;
      this.errorOverflow(object.code);
    }

    return isError;
  }

  private errorOverflow(code: string) {
    let errorTypeEnum: ErrorTypeEnum;

    switch (code) {
      case ErrorCodeEnum.FinancialReliefs01:
      case ErrorCodeEnum.FinancialReliefs02:
      case ErrorCodeEnum.FinancialReliefs03:
      case ErrorCodeEnum.FinancialReliefs07:
        errorTypeEnum = ErrorTypeEnum.ERROR_LOGIN;
        break;
      case ErrorCodeEnum.FinancialReliefs04:
        errorTypeEnum = ErrorTypeEnum.ERROR_GENERATE_OTP;
        break;
      case ErrorCodeEnum.FinancialReliefs05:
        errorTypeEnum = ErrorTypeEnum.ERROR_SIM;
        break;
      case ErrorCodeEnum.FinancialReliefs08:
        errorTypeEnum = ErrorTypeEnum.ERROR_COLLECTION_CUSTOMER;
        break;
      case ErrorCodeEnum.FinancialReliefs10:
        errorTypeEnum = ErrorTypeEnum.ERROR_INCOMPLETE_DATA_RELIEF;
        break;
      case ErrorCodeEnum.FinancialReliefs11:
        errorTypeEnum = ErrorTypeEnum.SIMULATION_ERROR;
        break;
      case ErrorCodeEnum.FinancialReliefs12:
      case ErrorCodeEnum.FinancialReliefs14:
        errorTypeEnum = ErrorTypeEnum.ERROR_AGE_OUT_OF_RANGE;
        break;
      default:
        errorTypeEnum = ErrorTypeEnum.TECHNICAL_ERROR;
        break;
    }

    this.setError(errorTypeEnum);
  }
}
