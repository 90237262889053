import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
})
export class FormFieldComponent {
  @Input("loading")
  set setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Input() lengthInput: string;
  @Input() fieldControl: FormControl;
  @Input() parentForm: FormGroup;
  @Input() text: string;
  @Input() textError: string;
  @Output() focusTextEvent = new EventEmitter<void>();

  loading: boolean;

  focusText() {
    this.focusTextEvent.emit();
  }

  showMessageError(): boolean {
    return (
      this.fieldControl.touched &&
      !this.fieldControl.valid &&
      (this.textError !== undefined || this.textError !== "")
    );
  }
}
