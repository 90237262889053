<div id="main" class="d-flex flex-column justify-with-scroll-md">
  <div class="content-step-bar">
    <app-progress-step-bar-with-number
      [steps]="steps"
    ></app-progress-step-bar-with-number>
  </div>

  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <app-content-message
          [icon]=""
          [title]="title"
          [description]="description"
        >
        </app-content-message>

        <div class="h-14-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <p>
              El código tiene
              <strong>
                04:00
              </strong>
              minutos de validez
            </p>

            <app-progress-bar
              #progressBar
              (infoBarCompleteEvent)="infoBarComplete()"
              [seconds]="240"
              [time]="true"
            ></app-progress-bar>
          </div>
        </div>

        <div class="h-25-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <app-otp-field
              #otpField
              [numberInputText]="numberInputOtp"
              [formGroup]="formGroup"
            ></app-otp-field>
          </div>
        </div>

        <div class="h-25-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <app-text-link
              (click)="generateOtpByCall()"
              [text]="'Solicita una nueva clave por llamada teléfonica'"
            ></app-text-link>
          </div>
        </div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <app-button
                id="app-button_authenticate-otp"
                [cssClass]="'w-100 buttonTextMedium'"
                [loading]="loadingButtonAuthenticateOtp"
                [mandatory]="true"
                [parentForm]="formGroup"
                [text]="'Continuar'"
                (clickEvent)="authenticateCodeOtp()"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<app-popup-error-view
  #popupErrorView
  [popupError]="popupError"
  [errorTypeEnum]="errorTypeEnum"
  (closePopupEvent)="closePopupError()"
></app-popup-error-view>
