import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { InitialNavPillsEnum } from "../../../core/enums/initial-nav-pills.enum";
import { CustomerService } from "../../../services/local/customer.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";
var FormLoginComponent = /** @class */ (function () {
    function FormLoginComponent(customerService, initialNavPillsService, payrollLoanService, router) {
        this.customerService = customerService;
        this.initialNavPillsService = initialNavPillsService;
        this.payrollLoanService = payrollLoanService;
        this.router = router;
        this.cleanPayrollLoan();
    }
    FormLoginComponent.prototype.ngOnInit = function () {
        this.description = "Para continuar necesitamos que ingreses tu documento";
        this.steps = {
            numberSteps: 7,
            stepActive: 0,
        };
        this.title = "Inicia la solicitud de tu beneficio";
        this.addPills();
        this.initForm();
    };
    FormLoginComponent.prototype.clickContinue = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingButtonAuthorization = true;
                        this.singleExecutionUnsubscribe();
                        return [4 /*yield*/, this.customerService.setCustomer({
                                documentType: this.formGroup.controls.valuePill.value,
                                documentNumber: this.formGroup.controls.textDocumentCustomer.value,
                            })];
                    case 1:
                        _a.sent();
                        this.singleExecution = this.customerService.loginCustomer().subscribe(function () {
                            _this.router.navigate(["authorizationdata"]);
                        }, function () {
                            _this.router.navigate(["overflowerror"]);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FormLoginComponent.prototype.addPills = function () {
        this.pills = this.initialNavPillsService.getInitialNavPills(InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN);
    };
    FormLoginComponent.prototype.cleanPayrollLoan = function () {
        this.payrollLoanService.setPayrollLoanSelected(undefined);
    };
    FormLoginComponent.prototype.initForm = function () {
        this.formGroup = new FormGroup({});
        this.formGroup.addControl("textDocumentCustomer", new FormControl("", Validators.required));
        this.formGroup.addControl("valuePill", new FormControl());
    };
    FormLoginComponent.prototype.singleExecutionUnsubscribe = function () {
        if (this.singleExecution) {
            this.singleExecution.unsubscribe();
        }
    };
    return FormLoginComponent;
}());
export { FormLoginComponent };
