import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { INavPill } from "../../../core/interfaces/nav-pill.interface";

import { InitialNavPillsEnum } from "../../../core/enums/initial-nav-pills.enum";

import { CustomerService } from "../../../services/local/customer.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";

@Component({
  selector: "app-form-login",
  templateUrl: "./form-login.component.html",
})
export class FormLoginComponent implements OnInit {
  description: string;
  loadingButtonAuthorization: boolean;
  steps: any;
  title: string;

  formGroup: FormGroup;
  pills: INavPill[];

  private singleExecution: Subscription;

  constructor(
    private customerService: CustomerService,
    private initialNavPillsService: InitialNavPillsService,
    private payrollLoanService: PayrollLoanService,
    private router: Router,
  ) {
    this.cleanPayrollLoan();
  }

  ngOnInit() {
    this.description = "Para continuar necesitamos que ingreses tu documento";
    this.steps = {
      numberSteps: 7,
      stepActive: 0,
    };
    this.title = "Inicia la solicitud de tu beneficio";

    this.addPills();
    this.initForm();
  }

  async clickContinue() {
    this.loadingButtonAuthorization = true;
    this.singleExecutionUnsubscribe();

    await this.customerService.setCustomer({
      documentType: this.formGroup.controls.valuePill.value,
      documentNumber: this.formGroup.controls.textDocumentCustomer.value,
    });

    this.singleExecution = this.customerService.loginCustomer().subscribe(
      () => {
        this.router.navigate(["authorizationdata"]);
      },
      () => {
        this.router.navigate(["overflowerror"]);
      },
    );
  }

  private addPills() {
    this.pills = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
    );
  }

  private cleanPayrollLoan() {
    this.payrollLoanService.setPayrollLoanSelected(undefined);
  }

  private initForm() {
    this.formGroup = new FormGroup({});
    this.formGroup.addControl(
      "textDocumentCustomer",
      new FormControl("", Validators.required),
    );
    this.formGroup.addControl("valuePill", new FormControl());
  }

  private singleExecutionUnsubscribe() {
    if (this.singleExecution) {
      this.singleExecution.unsubscribe();
    }
  }
}
