import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ToggleRemoteService = /** @class */ (function () {
    function ToggleRemoteService(http) {
        this.http = http;
    }
    ToggleRemoteService.prototype.asyncToggleList = function () {
        return this.http
            .get(environment.serverUrlSalaryLoans + "features/all")
            .toPromise();
    };
    ToggleRemoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToggleRemoteService_Factory() { return new ToggleRemoteService(i0.ɵɵinject(i1.HttpClient)); }, token: ToggleRemoteService, providedIn: "root" });
    return ToggleRemoteService;
}());
export { ToggleRemoteService };
