import { BenefitTypeRemoteService } from "../remote/benefit-type-remote.service";
import * as i0 from "@angular/core";
import * as i1 from "../remote/benefit-type-remote.service";
var BenefitTypeService = /** @class */ (function () {
    function BenefitTypeService(benefitTypeRemoteService) {
        this.benefitTypeRemoteService = benefitTypeRemoteService;
    }
    BenefitTypeService.prototype.getBenefitTypeMessage = function (benefitTypeEnum, dataPayrollLoanSimulation) {
        return this.benefitTypeRemoteService.getBenefitTypeMessage(benefitTypeEnum, dataPayrollLoanSimulation);
    };
    BenefitTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BenefitTypeService_Factory() { return new BenefitTypeService(i0.ɵɵinject(i1.BenefitTypeRemoteService)); }, token: BenefitTypeService, providedIn: "root" });
    return BenefitTypeService;
}());
export { BenefitTypeService };
