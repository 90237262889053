import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Observable, timer } from "rxjs";
import { map, take } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent {
  @ViewChild("timeRemainnigProgress", { static: false })
  timeRemainnigProgress: ElementRef;

  @Input("progress")
  set setProgress(progress: number) {
    if (progress) {
      this.barProgress(progress.toString());

      if (progress === ApplicationConstantsEnum.NUMBER_MAX_BAR_PROGRESS) {
        this.infoBarComplete();
      }
    }
  }

  @Input("seconds")
  set setSeconds(seconds: number) {
    if (seconds) {
      this.seconds = seconds;
      this.restartWithSeconds();
    }
  }

  @Input() time: boolean;
  @Output() infoBarCompleteEvent = new EventEmitter();

  count: number;
  countTimer: Observable<number>;
  intervalBar: any;
  seconds: number;
  secondsCount: number;

  constructor(private renderer: Renderer2) {}

  infoBarComplete() {
    this.infoBarCompleteEvent.emit();
  }

  restartWithSeconds() {
    clearInterval(this.intervalBar);
    this.secondsCount = this.seconds;

    this.progreessBarCompleteWithSeconds();
    this.timerComplete();
  }

  private progreessBarCompleteWithSeconds() {
    this.count = 0;

    this.intervalBar = setInterval(() => {
      this.count++;
      if (this.count <= 100) {
        this.barProgress(this.count.toString());
      } else {
        clearInterval(this.intervalBar);

        this.infoBarComplete();
      }
    }, (this.seconds * 1000) / 100);
  }

  private timerComplete() {
    this.countTimer = timer(0, 1000).pipe(
      take(this.seconds),
      map(() => --this.secondsCount),
    );
  }

  private barProgress(percentajeProgress: string) {
    this.renderer.setStyle(
      this.timeRemainnigProgress.nativeElement,
      "width",
      percentajeProgress + "%",
    );
  }
}
