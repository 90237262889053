import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BenefitTypeEnum } from "../../../core/enums/benefit-type.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { MessageService } from "../../../services/local/message.service";
import { PayrollLoanService } from "../../../services/local/payroll-loan.service";
import { RedirectService } from "../../../services/local/redirect.service";
var ApprovedReliefViewComponent = /** @class */ (function () {
    function ApprovedReliefViewComponent(messageService, payrollLoanService, redirectService, router) {
        this.messageService = messageService;
        this.payrollLoanService = payrollLoanService;
        this.redirectService = redirectService;
        this.router = router;
    }
    ApprovedReliefViewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.getMessageForm();
                        this.initForm();
                        return [4 /*yield*/, this.getCustomerActivePayrollLoans()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedReliefViewComponent.prototype.getCustomerActivePayrollLoans = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.payrollLoanService
                            .getCustomerActivePayrollLoans()
                            .toPromise()
                            .catch(function () {
                            _this.router.navigate(["overflowerror"]);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedReliefViewComponent.prototype.applyOtherLoan = function () {
        return this.payrollLoanService.hasActiveRelief();
    };
    ApprovedReliefViewComponent.prototype.getMessageForm = function () {
        var payrollLoanSelected = this.payrollLoanService.getPayrollLoanSelected();
        var message = this.messageService.getMessageById(payrollLoanSelected.benefitType === BenefitTypeEnum.BENEFIT_TYPE_ONE
            ? MessageEnum.APPROVED_RELIEF_WITH_QUOTA
            : MessageEnum.APPROVED_RELIEF_5_DAYS);
        if (message.length > 0) {
            this.icon = message[0].icon;
            this.title = message[0].title;
            this.description = message[0].description;
            this.cssClassTitle = message[0].cssClassTitle;
            this.htmlDescription = message[0].htmlDescription;
        }
    };
    ApprovedReliefViewComponent.prototype.clickApplyOtherLoan = function () {
        this.router.navigate(["salaryloan"]);
    };
    ApprovedReliefViewComponent.prototype.clickExit = function () {
        this.redirectService.redirectToBank();
    };
    ApprovedReliefViewComponent.prototype.initForm = function () {
        this.formGroup = new FormGroup({});
    };
    return ApprovedReliefViewComponent;
}());
export { ApprovedReliefViewComponent };
