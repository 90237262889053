import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "CapitalCasePipe" })
export class CapitalCasePipe implements PipeTransform {
  transform(stringTransform: string) {
    let stringReturn: string = "";
    const stringArray = stringTransform.split(" ");

    stringArray.forEach((str: string) => {
      stringReturn +=
        str.indexOf("¡") === -1
          ? stringReturn === ""
            ? ""
            : " "
          : str.substr(0, 1);

      stringReturn +=
        str
          .substr(str.indexOf("¡") === -1 ? 0 : str.indexOf("¡") + 1, 1)
          .toUpperCase() +
        str
          .substr(
            str.indexOf("¡") === -1 ? 1 : str.indexOf("¡") + 2,
            str.length - 1,
          )
          .toLowerCase();
    });

    return stringReturn;
  }
}
