<p [ngClass]="cssClass">
  <img
    class="mr-3"
    *ngIf="icon"
    src="./assets/images/icons/{{ icon }}.png"
    alt="Icono en el texto"
    [hidden]="icon === undefined || icon === ''"
  />
  {{ text }}
  <span class="font-weight-bold" *ngIf="textBold">
    {{ textBold }}
  </span>
</p>
